import {Component, OnInit} from '@angular/core';
import {InventoryService} from '../../inventory.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../../system/client-create/user.service';
import {ToastrService} from 'ngx-toastr';

export interface Unit {
  id: number;
  name: string;
}

@Component({
  templateUrl: './type-add.component.html'
})
export class TypeAddComponent implements OnInit {
  typeForm: FormGroup;
  unit: Unit[] = [];
  branch = [];
  warehouse: any;
  id =  0;
  checked = 1;

  constructor(
    private inventoryService: InventoryService,
    private userService: UserService,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {
    this.typeForm = new FormGroup({
      branch: new FormControl(null, Validators.required),
      warehouse: new FormControl(null, Validators.required),
      name: new FormControl(null, [Validators.required, Validators.maxLength(40)]),
      unit: new FormControl(null, Validators.required),
      isOpen: new FormControl(null, Validators.required)
    });
    this.warehouse = this.inventoryService.getWarehouse();
    if (window.history.state !== undefined) {
      const data = window.history.state;

      this.typeForm.controls.branch.patchValue(Number(data.branch));
      this.typeForm.controls.warehouse.patchValue(Number(data.w_id));
      this.typeForm.controls.name.setValue(data.name);
      this.typeForm.controls.unit.patchValue(data.unit_id);

      // isActive
      if (data.is_seller === 1) {
        this.typeForm.controls.isOpen.setValue(true);
        this.checked = 1;
      } else {
        this.typeForm.controls.isOpen.setValue(false);
        this.checked = 0;
      }

      this.id = data.id;
    } else {
      this.id = 0;
    }
    if (this.id === undefined) {
      this.id = 0;
    }

    this.inventoryService.getUnit()
      .subscribe((result: Unit[]) => {
        this.unit = result;
      });
    this.userService.getBranchesByOwner()
      .subscribe(x => {
        this.branch = x;
      });
  }

  onSubmit() {
    if (this.typeForm.valid) {
      this.inventoryService.postType(
        this.typeForm.value.branch,
        this.typeForm.value.warehouse,
        this.typeForm.value.name,
        this.typeForm.value.unit,
        this.id,
        this.typeForm.value.isOpen
      ).subscribe(result => {
        if (result.message === '1') {
          this.typeForm.reset();
        }
      });
    } else {
      this.toast.info('Hamma ma\'lumotlarni kiriting');
    }
  }

}
