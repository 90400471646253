import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';


const BACKEND_URL = environment.api_URL;

@Injectable({providedIn: 'root'})
export class MobileService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  getMobileOrder(): any {
    return this.httpClient.get<any>(BACKEND_URL + 'mobile/order/card/get');
  }
  getMobileOrderAll(d1: Date, d2: Date): any {
    const data = {
      d1,
      d2
    };
    return this.httpClient.post<any>(BACKEND_URL + 'mobile/order/card/all', data);
  }

  onCancel(id: number): any {
    const app = {
      id
    };
    return this.httpClient.post<any>(BACKEND_URL + 'mobile/order/card/cancel', app);
  }
  subtractCashier(data: any): any {
    return this.httpClient.post<any>(BACKEND_URL + 'crm/pos/day/operation/post', data);
  }

  getOrderReport(d1: Date, d2: Date): any {
    const data = {
      d1,
      d2
    };
    return this.httpClient.post<any>(BACKEND_URL + 'mobile/order/report', data);
  }

  getDeliveryList(d1: Date, d2: Date): any {
    const data = {
      d1,
      d2
    };
    return this.httpClient.post<any>(BACKEND_URL + 'crm/delivery/list/all', data);
  }

  getDeliveryPaidCost(d1: Date, d2: Date): any {
    const data = {
      d1,
      d2
    };
    return this.httpClient.post<any>(BACKEND_URL + 'crm/delivery/paid/cost', data);
  }
}
