import {Component, Inject, OnInit, Optional} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Customer} from '../../../installment/installment-apps/installment-sale/ins-model';
import {CashierService} from '../../../crm/cashier/cashier.service';
import {take} from 'rxjs/operators';
import {AccountingService} from '../../../crm/Accounting/accounting.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-currency-exchange',
  templateUrl: './currency-exchange.component.html',
  styleUrls: ['./currency-exchange.component.css']
})
export class CurrencyExchangeComponent implements OnInit {

  balanceForm: FormGroup;
  exchange: Customer[];
  summExchange = 0;

  constructor(
    private fb: FormBuilder,
    private cashierService: CashierService,
    private accountingService: AccountingService,
    private dialogRef: MatDialogRef<CurrencyExchangeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    this.getExchangeTypes();
    this.balanceForm = this.fb.group({
      nameExchange: [this.data[0].name, Validators.required],
      idExchange: [this.data[0].id, Validators.required],
      typeExchange: ['', Validators.required],
      costExchange: [0, [Validators.required, Validators.maxLength(15)]],
      rateExchange: [0, [Validators.required, Validators.maxLength(8)]],
    });
  }

  balanceFormSubmit() {
    if (confirm('Rostdan ham operatsiyani amalga oshirasizmi?')) {
      if (this.balanceForm.valid) {
        this.cashierService.postExchangeCompanyBalance(
          this.balanceForm.value.idExchange,
          this.balanceForm.value.typeExchange,
          this.balanceForm.value.costExchange,
          this.balanceForm.value.rateExchange,
        ).subscribe(result => {
          if (result.message === '1') {
            this.ngOnInit();
            this.balanceForm.reset();
          }
        });
        this.balanceForm.reset();
      } else {
        alert('Xamma malumotlarni kiriting!');
      }
    }
  }

  getExchangeTypes(): void {
    this.accountingService.getExchangeTypes().pipe(take(1))
      .subscribe(result => {
        this.exchange = result;
      });
  }

  calculateSum() {
    let summ = 0;
    let cost = 0;
    let type = 0;
    summ = this.balanceForm.value.costExchange;
    cost = this.balanceForm.value.rateExchange;
    type = this.balanceForm.value.typeExchange;
    if (type === 1) {
      this.summExchange = Number(summ) / Number(cost);
    } else if (type === 2) {
      this.summExchange = Number(summ) * Number(cost);
    } else if (type === 3) {
      this.summExchange = Number(summ);
    } else if (type === 4) {
      this.summExchange = Number(summ);
    } else if (type === 5) {
      this.summExchange = Number(summ) * Number(cost);
    } else if (type === 6) {
      this.summExchange = Number(summ) / Number(cost);
    } else if (type === 7) {
      this.summExchange = Number(summ) / Number(cost);
    } else if (type === 8) {
      this.summExchange = Number(summ) * Number(cost);
    } else if (type === 9) {
      this.summExchange = Number(summ);
    } else if (type === 10) {
      this.summExchange = Number(summ);
    }
  }
}
