import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {InstallmentService} from '../../installment.service';
import {Toast, ToastrService} from 'ngx-toastr';

interface Customer {
  id: number;
  full_name: string;
  photo: string;
}

@Component({
  templateUrl: './ins-oper-add.component.html',
  styleUrls: ['./ins-oper-add.component.css']
})
export class InsOperAddComponent implements OnInit {

  insOperForm: FormGroup;
  operControl = new FormControl();
  filterProducts: Observable<Customer[]>;
  customer: Customer[] = [];
  sales = [];
  realDebt = 0;
  restDebt = 0;
  isLoading = false;
  products;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private insService: InstallmentService,
    private toast: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.insOperForm = this.fb.group({
      customer_id: ['', Validators.required],
      ins_id: ['', Validators.required],
      currency_type: ['', Validators.required],
      amount: ['', [Validators.required, Validators.maxLength(13)]],
      comment: ['', [Validators.required, Validators.maxLength(256)]]
    });

    this.filterProducts = this.operControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    );
    this.insService.getInsCustomer().subscribe((result: Customer[]) => {
      this.customer = result;
    });
  } // End of ngOnInit

  filter(val: any): Customer[] {
    return this.customer.filter((item: any) => {
      // If the user selects an option, the value becomes a Human object,
      // therefore we need to reset the val for the filter because an
      // object cannot be used in this toLowerCase filter
      if (typeof val === 'object') {
        val = '';
      } else {
        const TempString = item.full_name;
        return TempString.toLowerCase().includes(val.toLowerCase());
      }
    });
  }

  displayFn(item: any): any {
    // tslint:disable-next-line:triple-equals
    if (item == undefined) {
      return;
    }
    return item.full_name;
  }

  setProduct(value: any) {
    this.insOperForm.controls.customer_id.setValue(value.id);
    this.insService.getUserSaleList(value.id)
      .subscribe(x => {
        this.sales = x;
      });
  }
  onChange(obj: any) {
    this.realDebt = 0;
    this.realDebt = obj.value.debt_amount;
    this.restDebt = 0;
    this.insOperForm.controls.ins_id.setValue(obj.value.id);
    this.products = obj.value.products;
  }

  onKey() {
     this.restDebt = Number(this.realDebt) - Number(this.insOperForm.value.amount);
  }

  onSubmit() {
    if (this.insOperForm.valid) {
      this.isLoading = true;
      this.insService.postOperAdd(this.insOperForm.value)
        .subscribe(result => {
          if (result.message === 1) {
            this.isLoading = false;
            const app = {
              id: result.id
            };
            this.router.navigate(['/installment/oper-list'], {state: app});
          } else {
            this.isLoading = false;
          }
        });
    } else {
      this.toast.info('Hamma malumotlarni kiriting!');
    }
  }
}

