import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {AuthData} from './auth-data.model';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';

const BACKEND_URL = environment.api_URL;

@Injectable({providedIn: 'root'})
export class AuthService {
  private isAuthenticated = false;
  private isValid = false;
  private token: string;
  private tokenTimer: any;
  private userId: number;
  private owner: number;
  private branch: number;
  private fullName: string;
  private authStatusListener = new Subject<boolean>();
  private currencyValueListener = new Subject<any>();
  private currencyValueListenerSelf = new Subject<string>();

  constructor(private http: HttpClient, private router: Router) {
  }

  getToken() {
    return this.token;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getUserId() {
    return this.userId;
  }

  getOwner() {
    return this.owner;
  }

  getPermission() {
    return this.getBranch() !== undefined;
  }

  getBranch() {
    return this.branch;
  }

  getFullName() {
    return this.fullName;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  getDollar(): any {
    this.http.get<any>(BACKEND_URL + 'crm/cashier/dollar/get')
      .subscribe(result => {
        this.currencyValueListener.next(result);
      });
  }

  getDollarSubs() {
    return this.currencyValueListener.asObservable();
  }

  getDollarSelf(): any {
    this.http.get<any>(BACKEND_URL + 'crm/cashier/dollar/self/get')
      .subscribe(result => {
        this.currencyValueListenerSelf.next(result[0].currency_value);
      });
  }

  getDollarSubsSelf() {
    return this.currencyValueListenerSelf.asObservable();
  }

  createUser(email: string, password: string) {
    const authData: AuthData = {email, password};
    this.http.post(BACKEND_URL + 'user/signup', authData)
      .subscribe(() => {
        this.router.navigate(['/']);
      }, error => {
        this.authStatusListener.next(false);
      });
  }

  login(email: string, password: string) {
    // if (this.getPermissionData() === '123456') {
      const authData: AuthData = {email, password};
      this.http.post<{ token: string, expiresIn: number, userId: number, owner: number, branch: number, fullName: string }>
      (BACKEND_URL + 'user/login', authData)
        .subscribe(response => {
          const token = response.token;
          this.token = token;
          if (token) {
            const expireInDuration = response.expiresIn;
            this.setAuthTimer(expireInDuration);
            this.isAuthenticated = true;
            this.userId = response.userId;
            this.owner = response.owner;
            this.branch = response.branch;
            this.fullName = response.fullName;
            this.authStatusListener.next(true);
            const now = new Date();
            const expirationDate = new Date(now.getTime() + expireInDuration * 1000);
            this.saveAuthData(token, expirationDate, this.userId);
            this.router.navigate(['/applist']);
          }
        }, error => {
          this.authStatusListener.next(false);
        });
    // }
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expireIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expireIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.userId = Number(authInformation.userId);
      this.setAuthTimer(expireIn / 1000);
      this.authStatusListener.next(true);
    }
  }

  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    clearTimeout(this.tokenTimer);
    this.userId = null;
    this.clearAuthData();
    this.router.navigate(['auth/login']);
  }

  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  private saveAuthData(token: string, expirationDate: Date, userId: number) {
    localStorage.setItem('token', token);
    localStorage.setItem('expiration', expirationDate.toISOString());
    localStorage.setItem('userId', String(userId));
  }

  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('expiration');
    localStorage.removeItem('userId');
  }

  private getAuthData() {
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expiration');
    const userId = localStorage.getItem('userId');
    if (!token || !expirationDate) {
      return;
    }
    return {
      token,
      expirationDate: new Date(expirationDate),
      userId
    };
  }

  getPermissionData() {
    const permissionKey = localStorage.getItem('permission_key');
    if (permissionKey) {
      return permissionKey;
    }
    return null;
  }

  checkWarning(): any {
    return this.http.get<any>(BACKEND_URL + 'user/check/warning');
  }

}
