import * as ShooppingListActions from './shooping-list.actions';

export interface Ingredient {
  name: string;
  quantity: number;
}

const initialState = {
  ingredients: [
    {name: 'Olma', quantity: 2 },
    {name: 'Olcha', quantity:  3},
  ]
};
export function shoopingListReducer(
  state = initialState,
  action: ShooppingListActions.ShoopingListActions) {
  switch (action.type) {
    case ShooppingListActions.ADD_INGREDIENT:
      return {
        ...state,
        ingredients: [...state.ingredients, action.payload]
      };
    case 'ADD_INGREDIENTS': {
      return {
        ...state,
        ingredients: []
      };
    }
    default:
      return state;
  }
}
