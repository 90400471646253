import {Component, OnInit} from '@angular/core';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {AccountingService} from '../accounting.service';
import {MatDialog} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {AccountingAddoperationComponent} from './accounting-addoperation/accounting-addoperation.component';
import {CurrencyCellRendererQuantity, CurrencyCellRendererSum} from '../../../test/table-currency';

@Component({
  templateUrl: './admin-oper.component.html',
  styleUrls: ['admin-oper.component.css']
})
export class AdminOperComponent implements OnInit {

  constructor(
    private accountingService: AccountingService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router
  ) {
  }
  isLoading = false;
  rowData: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  isVisible: any;
  public modules: Module[] = AllModules;

  columnDefs = [
    {headerName: 'Sana', field: 'cr_on', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Turi', field: 'type_id', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Kompaniya', field: 'company', sortable: true, width: 200, filter: 'agTextColumnFilter'},
    {headerName: 'Valyuta', field: 'currency', sortable: true, width: 100, filter: 'agTextColumnFilter'},
    {headerName: 'Summa', field: 'cost', sortable: true, width: 150, cellRenderer: CurrencyCellRendererQuantity},
    {headerName: 'Foydalanuvchi', field: 'cr_by', sortable: true, width: 100},
    {headerName: 'Izoh', field: 'comment', sortable: true, width: 300},
  ];


  ngOnInit(): void {
    this.isLoading = true;
    this.onRenderComponent();
    if (this.rowData !== undefined) {
      this.isLoading = false;
    }
  }
  onRenderComponent() {
    this.rowData = this.accountingService.getAccountingOperation();
  }

  onBtnExport() {
    this.gridApi.exportDataAsExcel();
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    param.api.sizeColumnsToFit();
  }

  onDoubleClicked($event: any) {
    this.isVisible = this.gridApi.getSelectedRows()[0];
  }

  onAddOperation() {
    const dialogRef = this.dialog.open(AccountingAddoperationComponent, {
      panelClass: ['animate__animated', 'animate__slideInRight'],
      width: '30%',
      height: '100%',
      position: {right: '0'},
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  onAddOperationBack() {
    this.router.navigate(['accounting/dashboard']);
  }

  onReturnOperation() {
    if (confirm('Rostdan qaytarmoqchimisiz?')) {
      this.accountingService.postReturnOperation(
        this.isVisible.id
      ).subscribe(result => {
        if (result.message === '1') {
          this.isVisible = false;
          this.ngOnInit();
        }
      });
    }
  }

  onAddCompany() {
    this.router.navigate(['../../inventory/company/add']);
  }
}
