import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AccountingService} from '../accounting.service';
import {take} from 'rxjs/operators';

interface Type {
  id: number;
  name: string;
}

@Component({
  templateUrl: './exchange-currency.component.html',
  styleUrls: ['./exchange-currency.component.css']
})
export class ExchangeCurrencyComponent implements OnInit {

  adminExchange: FormGroup;
  result = 0;
  isDisable = true;

  type: Type[];

  constructor(
    private fb: FormBuilder,
    private accountingService: AccountingService
  ) {
  }

  ngOnInit(): void {
    this.adminExchange = this.fb.group({
      type: ['', Validators.required],
      cost: ['', [Validators.required, Validators.maxLength(15)]],
      rate: ['', [Validators.required, Validators.maxLength(8)]],
    });
    this.getExchangeTypes();
  }

  getExchangeTypes(): void {
    this.accountingService.getExchangeTypes().pipe(take(1))
      .subscribe(result => {
        this.type = result;
      });
  }

  calculateSum() {
    let summ = 0;
    let cost = 0;
    let type = 0;
    summ = this.adminExchange.value.cost;
    cost = this.adminExchange.value.rate;
    type = this.adminExchange.value.type;
    if (type === 1) {
      this.result = Number(summ) / Number(cost);
    } else if (type === 2) {
      this.result = Number(summ) * Number(cost);
    } else if (type === 3) {
      this.result = Number(summ) * Number(cost);
    } else if (type === 4) {
      this.result = Number(summ) * Number(cost);
    } else if (type === 5) {
      this.result = Number(summ) * Number(cost);
    } else if (type === 6) {
      this.result = Number(summ) / Number(cost);
    } else if (type === 7) {
      this.result = Number(summ) / Number(cost);
    } else if (type === 8) {
      this.result = Number(summ) * Number(cost);
    } else if (type === 9) {
      this.result = Number(summ) * Number(cost);
    } else if (type === 10) {
      this.result = Number(summ) * Number(cost);
    }
  }

  onSubmit() {
    if (this.adminExchange.valid) {
      this.isDisable = false;
      this.accountingService.postExchangeCurrency(
        this.adminExchange.value.type,
        this.adminExchange.value.cost,
        this.adminExchange.value.rate,
        Number(this.result.toPrecision(15)),
      ).subscribe(result => {
        if (result.message === '1') {
          this.adminExchange.reset();
          this.isDisable = true;
        }
      });
    }
  }
}
