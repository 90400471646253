import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {InventoryService} from '../../../inventory/inventory.service';
import {AccountingService} from '../../accounting.service';
import {map, startWith, take} from 'rxjs/operators';

interface Type {
  id: number;
  name: string;
}

interface Company {
  id: number;
  name: string;
  type: number;
  cost: number;
}

@Component({
  templateUrl: './accounting-addoperation.component.html',
  styleUrls: ['./accounting-addoperation.component.css']
})
export class AccountingAddoperationComponent implements OnInit {
  operationForm: FormGroup;
  customer$: Observable<Type[]>;
  currency$: Observable<Type[]>;
  myControl = new FormControl();
  filteredOptions: Observable<Company[]>;
  options: Company[] = [];

  isDisable = true;
  type: Type[] = [
    {id: 1, name: 'Kirim'},
    {id: 2, name: 'Chiqim'}
  ];
  constructor(
    private fb: FormBuilder,
    private inventoryService: InventoryService,
    private accountingService: AccountingService
  ) {
  }
  ngOnInit(): void {
    this.operationForm = this.fb.group({
      type: ['', Validators.required],
      customer: ['', Validators.required],
      currency: ['', Validators.required],
      cost: ['', [Validators.required, Validators.maxLength(15)]],
      comment: ['', [Validators.required, Validators.maxLength(200)]]
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    );
    this.inventoryService.getCompany()
      .subscribe((result: Company[]) => {
        this.options = result;
      });
    this.currency$ = this.inventoryService.getCurrency();
  }

  onSubmit() {
    if (this.operationForm.valid) {
      this.isDisable = false;
      this.accountingService.postAccountingOperation(
        this.operationForm.value.type,
        this.operationForm.value.customer,
        this.operationForm.value.currency,
        this.operationForm.value.cost,
        this.operationForm.value.comment,
      ).subscribe(result => {
        if (result.message === '1') {
          this.operationForm.reset('');
          this.isDisable = true;
        }
      });
    }
  }

  filter(val: any): Company[] {
    return this.options.filter((item: any) => {
      // If the user selects an option, the value becomes a Human object,
      // therefore we need to reset the val for the filter because an
      // object cannot be used in this toLowerCase filter
      if (typeof val === 'object') {
        val = '';
      } else {
        const TempString = item.name;
        return TempString.toLowerCase().includes(val.toLowerCase());
      }
    });
  }

  displayFnCompany(item: any): any {
    // tslint:disable-next-line:triple-equals
    if (item == undefined) {
      return;
    }
    return item.name;
  }

  setCompany(event: any) {
    this.operationForm.controls.customer.setValue(event.id);
      }
}
