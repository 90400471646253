import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PostCreateComponent } from './posts/post-create/post-create.component';
import {AuthGuard} from './auth/auth.guard';
import {ApplistComponent} from './app-list/applist.component';
import {HrListComponent} from './hr/hr-list/hr-list.component';
import {TestComponent} from './test/test.component';
import {HrCreateComponent} from './hr/hr-create/hr-create.component';
import {LoginComponent} from './auth/login/login.component';
import {HrOperationsComponent} from './hr/operations/hr-operations.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'create', component: PostCreateComponent, canActivate: [AuthGuard] },
  { path: 'edit/:postId', component: PostCreateComponent, canActivate: [AuthGuard] },
  { path: 'applist', component: ApplistComponent, canActivate: [AuthGuard] },
  { path: 'hr', component: TestComponent, canActivate: [AuthGuard] },
  { path: 'hr/list', component: HrListComponent, canActivate: [AuthGuard] },
  { path: 'test', component: TestComponent, canActivate: [AuthGuard] },
  { path: 'hr/add', component: HrCreateComponent, canActivate: [AuthGuard] },
  { path: 'hr/oper/list', component: HrOperationsComponent, canActivate: [AuthGuard] },
  { path: 'auth', loadChildren: './auth/auth.module#AuthModule'},
  { path: 'hospital', loadChildren: () => import('./hospital/hospital.module').then(m => m.HospitalModule), canActivate: [AuthGuard]}
  // { path: '**', component: LoginComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {}
