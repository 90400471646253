import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {InventoryService} from '../../inventory.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {Type} from '../../inventory-add/inventory-add.component';

@Component({
  templateUrl: './currency-add.component.html'
})
export class CurrencyAddComponent implements OnInit {
  currencyForm: FormGroup;
  stateCtrl: FormControl;
  filteredStates: Observable<any[]>;

  question = 'Would you like to add "';

  states: any[] = [
    'Arkansas',
    'California',
    'Florida',
    'Texas'
  ];

  statesArray: any = [];

  constructor(private inventoryService: InventoryService) {
    this.stateCtrl = new FormControl();
    this.filteredStates = this.stateCtrl.valueChanges
      .pipe(
        startWith(''),
        map(state => state ? this.filterStates(state) : this.states.slice())
      );
  }

  ngOnInit(): void {
    this.currencyForm = new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.maxLength(40)])
    });
    this.inventoryService.getType()
      .subscribe((result: any) => {
       // this.states = result;
      });
  }

  filterStates(name: any) {
    let results = this.states.filter(state =>
      state.toLowerCase().indexOf(name.toLowerCase()) === 0);

    if (results.length < 1) {
      results = [this.question + name + '"?'];
    }

    return results;
  }

  // optionSelected(option) {
  //   console.log('optionSelected:', option.value);
  //   if (option.value.indexOf(this.question) === 0) {
  //     const newState = option.value.substring(this.question.length).split('"?')[0];
  //     this.states.push(newState);
  //     this.stateCtrl.setValue(newState);
  //   }
  // }
  //
  // enter() {
  //   const value = this.stateCtrl.value;
  //   if (!this.states.some(entry => entry === value)) {
  //     this.states.push(value);
  //   }
  //   setTimeout(() => this.stateCtrl.setValue(value));
  // }

  onSubmit() {
    this.inventoryService.postCurrency(this.currencyForm.value.name);
  }

}
