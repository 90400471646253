import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

const BACKEND_URL = environment.api_URL;

@Injectable({providedIn: 'root'})
export class NotificationService {
  private notification = new BehaviorSubject<boolean>(true);
  cast = this.notification.asObservable();

  constructor(
    private http: HttpClient
  ) {
  }

  updateNotification(status: boolean) {
    this.notification.next(status);
  }

  getNotification() {
    return this.http.get<any>(BACKEND_URL + 'permission/notification/list');
  }
  onView(id: number) {
    const app = {
      id
    };
    return this.http.post<any>(BACKEND_URL + 'permission/notification/view', app);
  }
}
