import {Component, OnInit} from '@angular/core';

@Component({
  templateUrl: './sertificate-add.component.html',
  styleUrls: ['./sertificate-add.component.css']
})
export class SertificateAddComponent implements OnInit {


  ngOnInit(): void {
  }

}
