import {NgModule} from '@angular/core';
import {LoginComponent} from './login/login.component';
import {SignupComponent} from './signup/signup.component';
import {CommonModule} from '@angular/common';
import {AngularMaterialModule} from '../angular-material.module';
import {FormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AuthRoutingModule} from './auth-routing.module';
import {MatGridListModule} from '@angular/material/grid-list';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
  ],
    imports: [
        MatProgressSpinnerModule,
        CommonModule,
        AngularMaterialModule,
        FormsModule,
        AuthRoutingModule,
        MatGridListModule,
        TranslateModule
    ]
})
export class AuthModule {

}
