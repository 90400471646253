import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CashierService} from '../cashier.service';
import {AuthService} from '../../../auth/auth.service';

@Component({
  templateUrl: './cashier-settings.component.html',
  styleUrls: ['./cashier-settings.component.css']
})
export class CashierSettingsComponent implements OnInit {
  operationTypes: any = [
    {id: 1, name: 'Ombor maxsulotlarni yangi kurs bo\'yicha qayta narxlash', checked: false},
    {id: 2, name: 'Faqat kursni o\'zini o\'zgartirish', checked: true}
  ];
  currencyFrom: FormGroup;
  constructor(private fb: FormBuilder, private cashierService: CashierService, private authService: AuthService) {
  }

  ngOnInit(): void {
    this.currencyFrom = this.fb.group({
      cost: ['', [Validators.required, Validators.maxLength(15)]],
      type: [Validators.required]
    });
  }

  onSubmitCurrency() {
    if (this.currencyFrom.valid) {
      this.cashierService.postCurrencySelf(this.currencyFrom.value.cost, this.currencyFrom.value.type)
        .subscribe(result => {
          if (result.message === '1') {
            this.currencyFrom.controls.cost.setValue('');
            this.authService.getDollarSelf();
          }
        });
    }
  }
}
