import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../auth/auth.guard';
import {ProductOrderComponent} from './product-order/product-order.component';

const routes: Routes = [
  { path: 'mobile', component: ProductOrderComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class MobileRoutingModule {}
