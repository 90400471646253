import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../user.service';
import {Router} from '@angular/router';
import {ToastrService} from "ngx-toastr";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {InventoryAddComponent} from "../../../crm/inventory/inventory-add/inventory-add.component";
import {InventoryPermissionComponent} from "../../inventory-permission/inventory-permission.component";

interface Branch {
  id: number;
  name: string;
}

@Component({
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {

  userForm: FormGroup;
  owner: Branch[] = [];
  branch: Branch[] = [];
  gender: any[] = [
    {id: 1, name: 'Erkak'},
    {id: 2, name: 'Ayol'}
  ];
  user: any = [];

  constructor(
    private userService: UserService,
    private router: Router,
    private toast: ToastrService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.userForm = new FormGroup({
      owner: new FormControl(null, Validators.required),
      branch: new FormControl(null, Validators.required),
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      fatherName: new FormControl(null, Validators.required),
      userName: new FormControl(null, [Validators.required, Validators.maxLength(15)]),
      password: new FormControl(null, [Validators.required, Validators.maxLength(15)]),
      gender: new FormControl(null, Validators.required),
      phone: new FormControl(null, Validators.required),
      location: new FormControl(null, Validators.required)
    });

    this.userService.getOwners()
      .subscribe((x: Branch[]) => {
        this.owner = x;
      });
    this.userService.getBranchs()
      .subscribe((x: Branch[]) => {
        this.branch = x;
      });
  }

  onSubmit() {
    if (this.userForm.invalid) {
      this.toast.error('Hamma joylarni to\'ldiring!');
    } else {
      this.userService.postUser(
        this.userForm.value.owner,
        this.userForm.value.branch,
        this.userForm.value.firstName,
        this.userForm.value.lastName,
        this.userForm.value.fatherName,
        this.userForm.value.userName,
        this.userForm.value.password,
        this.userForm.value.gender,
        this.userForm.value.phone,
        this.userForm.value.location
      );
      this.goPermissions();
    }
  }

  goPermissions() {
    this.router.navigate(['./permissions']);
  }

  goPermission1(): void {
    const dialogRef = this.dialog.open(InventoryPermissionComponent, {
      panelClass: ['animate__animated', 'animate__slideInRight'],
      width: '80%',
      height: '100%'
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
