import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InstallmentService} from '../../installment.service';
import {take} from 'rxjs/operators';

@Component({
  templateUrl: './ins-customer-add.component.html',
  styleUrls: ['./ins-customer-add.component.css']
})
export class InsCustomerAddComponent implements OnInit {

  insCustomerAddForm: FormGroup;
  imagePreview: string | ArrayBuffer;
  formId = 0;
  isLoading = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private insService: InstallmentService
  ) {

  }

  ngOnInit(): void {
    this.insCustomerAddForm = this.fb.group({
      full_name: ['', [Validators.required, Validators.maxLength(100)]],
      address: ['', [Validators.required, Validators.maxLength(150)]],
      email: ['test@gmail.com', [Validators.required, Validators.maxLength(100), Validators.email]],
      birthdate: ['', Validators.required],
      working_place: ['', [Validators.required, Validators.maxLength(150)]],
      mansab: ['', [Validators.required, Validators.maxLength(150)]],
      salary: ['', [Validators.required, Validators.maxLength(12)]],
      passport_seria: ['', [Validators.required, Validators.maxLength(9), Validators.minLength(9)]],
      whoGiven: ['', [Validators.required, Validators.maxLength(100)]],
      pass_date: ['', Validators.required],
      passport: [null],
      comment: ['Izoh yozing', [Validators.required, Validators.maxLength(256)]],
      phones: this.fb.array([this.createAddress(1)]),
      pnfl: ['', [Validators.required, Validators.maxLength(14), Validators.minLength(14)]]
    });

    if (window.history.state.id > 0) {
      this.formId = window.history.state.id;
      this.insCustomerAddForm.controls.full_name.setValue(window.history.state.full_name);
      this.insCustomerAddForm.controls.address.setValue(window.history.state.address);
      this.insCustomerAddForm.controls.email.setValue(window.history.state.email);
      const date1 = new Date(window.history.state.birth_date);
      const date2 = new Date(window.history.state.passdate);
      this.insCustomerAddForm.controls.birthdate.setValue(date1);
      this.insCustomerAddForm.controls.pass_date.setValue(date2);
      this.insCustomerAddForm.controls.working_place.setValue(window.history.state.working_place);
      this.insCustomerAddForm.controls.mansab.setValue(window.history.state.mansab);
      this.insCustomerAddForm.controls.salary.setValue(window.history.state.salary);
      this.insCustomerAddForm.controls.passport_seria.setValue(window.history.state.passport_seria);
      this.insCustomerAddForm.controls.whoGiven.setValue(window.history.state.who_given);
      this.insCustomerAddForm.controls.comment.setValue(window.history.state.s_comment);
      this.insCustomerAddForm.controls.pnfl.setValue(window.history.state.pnfl);
      this.imagePreview  = window.history.state.photo;
      (this.insCustomerAddForm.get('phones') as FormArray).removeAt(0);
      window.history.state.phone_numbers.forEach((phone) => {
        (this.insCustomerAddForm.get('phones') as FormArray).push(this.createAddressDetails(phone));
      });
    }
  }

  createAddressDetails(phone): FormGroup {
    return this.fb.group({
      order: [phone.order],
      phone: [phone.phone, Validators.required]
    });
  }

  createAddress(id): FormGroup {
    return this.fb.group({
      order: [id],
      phone: ['', Validators.required]
    });
  }

  onImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.insCustomerAddForm.patchValue({passport: file});
    this.insCustomerAddForm.get('passport').updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result;
    };
    reader.readAsDataURL(file);
  }

  onSubmit() {
    if (this.insCustomerAddForm.valid) {
      this.isLoading = true;
      this.insService.addInsCus(this.insCustomerAddForm.value, this.insCustomerAddForm.value.birthdate,
        this.insCustomerAddForm.value.pass_date, this.formId, this.insCustomerAddForm.value.passport)
        .subscribe(result => {
          if (result.message === 1 && this.formId <= 0) {
            this.isLoading = false;
            this.router.navigate(['/installment/customers/list']);
            this.imagePreview = '';
          } else {
            this.isLoading = false;
          }
        });
    }
  }

  addNewPhone() {
    const lenghtOfArray = (this.insCustomerAddForm.get('phones') as FormArray).length;
    (this.insCustomerAddForm.get('phones') as FormArray).push(this.createAddress(lenghtOfArray + 1));
  }

  get getFormControls(): any {
    return this.insCustomerAddForm.get('phones')['controls'];
  }

  removePhone(id: number) {
    if ((this.insCustomerAddForm.get('phones') as FormArray).length > 1) {
      (this.insCustomerAddForm.get('phones') as FormArray).removeAt(id);
    }
  }
}
