import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { AuthService } from "../auth.service";
import { Subscription } from "rxjs";

@Component({
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit{
  hide = true;
  isLoading = false;
  isLogin = false;
  date: string;
  hour: number;
  minute: number;
  second: number;
  year: number;
  constructor(public authService: AuthService) {}

  ngOnInit(): void {
    const timeStamp = new Date();
    this.year = timeStamp.getFullYear();
    // this.authStatusSub = this.authService
    //   .getAuthStatusListener()
    //   .subscribe((authStatus) => {
    //     if (authStatus === true) {
    //       this.isLogin = true;
    //     } else {
    //       this.isLogin = false;
    //     }
    //   });
    setInterval(() => {
      const currentDate = new Date();
      this.hour = currentDate.getHours();
      this.minute = currentDate.getMinutes();
      this.second = currentDate.getSeconds();
    }, 1000);
  }

  // ngOnDestroy(): void {
  //   this.authStatusSub.unsubscribe();
  // }

  onLogin(form: NgForm) {
    if (form.invalid) {
      return;
    } else {
      this.isLogin = true;
      this.authService.login(form.value.email, form.value.password);
    }
  }
}
