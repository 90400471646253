import { Component, OnInit } from '@angular/core';
import {
  CurrencyCellRendererDollar,
  CurrencyCellRendererQuantity,
  CurrencyCellRendererSum
} from '../../../../test/table-currency';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {InventoryService} from '../../inventory.service';
import {MatDialog} from '@angular/material/dialog';
import {take} from 'rxjs/operators';

interface Warehouse {
  id: number;
  name: string;
  level_nice: number;
  level_warning: number;
  level_low: number;
}

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.css']
})
export class ArchiveComponent implements OnInit {
  rowData: any = [];
  warehouse: Warehouse[] = [];
  gridApi;
  gridColumnApi;
  defaultColDef;

  public modules: Module[] = AllModules;

  constructor(
    public dialog: MatDialog,
    private inventoryService: InventoryService
  ) {
    this.defaultColDef = {resizable: true};
  }

  columnDefs = [
    {headerName: 'Filial', field: 'branch_name', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Ombor', field: 'warehouse', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {
      headerName: 'Barcode', field: 'barcode', sortable: true, filter: 'agTextColumnFilter',
      checkboxSelection: true, width: 150
    },
    {headerName: 'Turi', field: 'type', sortable: true, filter: 'agTextColumnFilter', width: 200},
    {headerName: 'Nomi', field: 'product_name', sortable: true, filter: 'agTextColumnFilter', width: 250}
  ];

  ngOnInit(): void {
    this.rowData = this.inventoryService.getArchiveProducts();
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    param.api.sizeColumnsToFit();
  }

  addProductModal(): void {
    const selectedRow = this.gridApi.getSelectedRows()[0].id;
    if (selectedRow > 0) {
      this.inventoryService.removeArchiveProduct(selectedRow).pipe(take(1))
        .subscribe(r => {
          this.dialog.closeAll();
        });
    } else {
      alert('Jadvaldan tanlang!');
    }
  }
}
