import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';

const BACKEND_URL = environment.api_URL;

@Injectable({providedIn: 'root'})
export class HrService {

  constructor(private http: HttpClient, private router: Router) {}

  addPerson(obj: any, date: Date, id: number) {
    const post: any = {
      full_name: obj.full_name,
      email: obj.email,
      phone_number: obj.phone_number,
      birth_date: date,
      salary: obj.salary,
      id
    };
    return this.http.post<any>(BACKEND_URL + 'person/hr/add', post);

  }
  // get erp_hr_users
  getHrUsers(): any {
    return this.http.get<any>(BACKEND_URL + 'person/hr/get');
  }

  getHrOperations(): any {
    return this.http.get<any>(BACKEND_URL + 'person/hr/oper/get');
  }
  addHrOperation(obj: any): any {
    const  app = {
      user: obj.user,
      amount: obj.amount,
      comment: obj.comment,
      operType: 1
    };
    return this.http.post<any>(BACKEND_URL + 'person/hr/oper/add', app);
  }

  deleteUser(id): any {
    const app = {
      id
    };
    return this.http.post<any>(BACKEND_URL + 'person/hr/delete', app);
  }
  deleteOper(id): any {
    const app = {
      id
    };
    return this.http.post<any>(BACKEND_URL + 'person/hr/oper/delete', app);
  }

  salaryCalculate(id: number): any {
    const data = {
      id
    };
    return this.http.post<any>(BACKEND_URL + 'person/hr/salary/calculate', data);
  }
}
