import {Component, OnInit} from '@angular/core';
import * as XLSX from 'xlsx';
import {InventoryService} from '../../inventory.service';

@Component({
  templateUrl: './excell-import.component.html',
  styleUrls: ['./excell-import.component.css']
})
export class ExcellImportComponent implements OnInit {

  rowData: any;
  constructor(
      private inventoryService: InventoryService
  ) {
  }

  columnDefs = [
    {headerName: 'w_id', field: 'w_id'},
    {headerName: 'type_id', field: 'type_id'},
    {headerName: 'product_name', field: 'product_name'},
    {headerName: 'cost_uz', field: 'cost_uz'},
    {headerName: 'sell_cost_uz', field: 'sell_cost_uz'},
    {headerName: 'cost_us', field: 'cost_us'},
    {headerName: 'sell_cost_us', field: 'sell_cost_us'},
    {headerName: 'quantity', field: 'quantity'},
    {headerName: 'unit', field: 'unit'},
    {headerName: 'barcode', field: 'barcode'},
    {headerName: 'is_open', field: 'is_open'},
    {headerName: 'status', field: 'status'},

  ];
  ngOnInit(): void {
  }

  onFileChange(evt: any) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = evt.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      console.log(jsonData.Sheet1);
      this.inventoryService.postExcelImport(jsonData.Sheet1)
        .subscribe(x => {
          if (x.message === 1) {
            console.log(x);
          } else {
            console.log(x);
          }
        });
    };
    reader.readAsBinaryString(file);
  }
}
