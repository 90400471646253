import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {InventoryService} from '../../../inventory.service';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {InvoicePListComponent} from '../invoice-product-list/invoice-p-list.component';
import {MatDialog} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {dateParser} from '../../../../dateParser';
@Component({
  templateUrl: './in-list.component.html',
  styleUrls: ['./in-list.component.css']
})
export class InListComponent implements OnInit {

  rowData: any;
  rowDataIP: any;
  rowDataFilter: any;
  filterForm: FormGroup;
  gridApi;
  gridColumnApi;
  defaultColDef;
  public modules: Module[] = AllModules;
  constructor(
    private router: Router,
    private inventoryService: InventoryService,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {
    this.defaultColDef = { resizable: true };
  }

  columnDefs = [
    {headerName: 'Sana', field: 'date', sortable: true, filter: 'agTextColumnFilter', width: 100 },
    {headerName: 'Filial', field: 'branch_name', sortable: true, filter: 'agTextColumnFilter', width: 150 },
    {headerName: 'Kompaniya', field: 'company', sortable: true, filter: 'agTextColumnFilter', width: 300 },
    {headerName: 'Invoice nomi', field: 'invoice_name', sortable: true, filter: 'agTextColumnFilter', width: 150 },
    {headerName: 'Operatsiya turi', field: 'oper_type', sortable: true, filter: 'agTextColumnFilter', width: 100 },
    {headerName: 'Valyuta', field: 'currency', sortable: true, filter: 'agTextColumnFilter', width: 100 },
    {headerName: 'Sum', field: 'cost', sortable: true, filter: 'agNumberColumnFilter',
      cellRenderer: this.CurrencyCellRendererDollar, width: 150 },
    {headerName: 'Ishchi', field: 'cr_by', sortable: true, filter: true, width: 150 },
    {headerName: 'Izox', field: 'comment', sortable: true }
  ];

  columnDefsIP = [
    {headerName: 'Barcode', field: 'barcode', sortable: true, checkboxSelection: true, width: 100},
    {headerName: 'Turi', field: 'type', sortable: true, width: 100},
    {headerName: 'Miqdori', field: 'quantity', sortable: true, width: 100, cellRenderer: this.CurrencyCellRendererDollar},
    {headerName: 'Narxi', field: 'cost', sortable: true, width: 100, cellRenderer: this.CurrencyCellRendererDollar},
    {headerName: 'Summa', field: 'sum', sortable: true, width: 100, cellRenderer: this.CurrencyCellRendererDollar},
    {headerName: '%', field: 'bt', sortable: true, width: 100, cellRenderer: this.CurrencyCellRendererDollar},
    {headerName: '% summa', field: 'sum_bt', sortable: true, width: 100, cellRenderer: this.CurrencyCellRendererDollar},
  ];

  columnDefsFilter = [
    {headerName: 'Nomi', field: 'name', sortable: true, checkboxSelection: true, width: 100, filter: 'agTextColumnFilter'},
    {headerName: 'Barcode', field: 'barcode', sortable: true, width: 100, filter: 'agTextColumnFilter'},
    {headerName: 'Miqdori', field: 'quantity', sortable: true, width: 100,
      cellRenderer: this.CurrencyCellRendererDollar, filter: 'agTextColumnFilter'},
    {headerName: 'Dona', field: 'dona', sortable: true, width: 100,
      cellRenderer: this.CurrencyCellRendererDollar, filter: 'agTextColumnFilter'}
  ];

  private CurrencyCellRendererDollar(params: any) {
    const usdFormate = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0
    });
    return usdFormate.format(params.value);
  }

  openDialog() {
    this.router.navigate(['../../inventory/invoice/add']);
  }

  onRowDoubleClick(rowData: any) {
    const dialogRef = this.dialog.open(InvoicePListComponent, {
      width: '70%',
      height: '100%',
      position: {right: '0'},
      data: rowData
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.ngOnInit();
    });
  }
  ngOnInit(): void {
    const date1 = new Date();
    const to = dateParser(date1);
    const mm = String(date1.getMonth() + 1).padStart(2, '0');
    const yyyy = date1.getFullYear();
    const from =  yyyy + '-' + mm + '-' + '01';
    this.filterForm = this.fb.group({
      from: [from, Validators.required],
      to: [to, Validators.required]
    });
    this.rowData = this.inventoryService.getInvoiceFilter(this.filterForm.value);
    this.rowDataFilter =  this.inventoryService.getInvoiceReport(this.filterForm.value);
  }


  goBack() {
    this.router.navigate(['inventory']);
  }

  onSubmitFilter(): void {
    if (this.filterForm.valid) {
      this.rowDataFilter =  this.inventoryService.getInvoiceReport(this.filterForm.value);
      this.rowData = this.inventoryService.getInvoiceFilter(this.filterForm.value);
    } else {
      alert('Muddatlarni kiriting!');
    }
  }
}
