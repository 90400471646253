import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

const BACKEND_URL = environment.api_URL;

@Injectable({
  providedIn: 'root'
})
export class InstallmentService {

  constructor(
    private http: HttpClient
  ) {
  }

  // Adding new installment customer
  addInsCus(obj: any, birthDate: Date, passDate: Date, id: number, image: File): any {
    const customer = new FormData();
    customer.append('id', id.toString());
    customer.append('full_name', obj.full_name);
    customer.append('email', obj.email);
    customer.append('address', obj.address);
    customer.append('phones', JSON.stringify(obj.phones));
    customer.append('birthdate', birthDate.toISOString());
    customer.append('working_place', obj.working_place);
    customer.append('mansab', obj.mansab);
    customer.append('salary', obj.salary.toString());
    customer.append('passport_seria', obj.passport_seria.toString());
    customer.append('pnfl', obj.pnfl.toString());
    customer.append('whoGiven', obj.whoGiven.toString());
    customer.append('pass_date', passDate.toISOString());
    customer.append('comment', obj.comment);
    if (image !== null) {
      customer.append('image', image, obj.email.toString());
      return this.http.post<any>(BACKEND_URL + 'ins/customer/add', customer);
    } else {
      const app = {
        id,
        full_name: obj.full_name,
        email: obj.email,
        address: obj.address,
        phones: obj.phones,
        birthdate: obj.birthdate,
        working_place: obj.working_place,
        mansab: obj.mansab,
        salary: obj.salary,
        passport_seria: obj.passport_seria,
        pnfl: obj.pnfl,
        whoGiven: obj.whoGiven,
        pass_date: obj.pass_date,
        comment: obj.comment
      };
      return this.http.post<any>(BACKEND_URL + 'ins/customer/add1', app);
    }
  }

  addWithPhoneNumber(obj: any, id: number): any {
    const app = {
      id,
      full_name: obj.full_name,
      email: obj.email,
      address: obj.address,
      phones: obj.phones,
      birthdate: obj.birthdate,
      working_place: obj.working_place,
      mansab: obj.mansab,
      salary: obj.salary,
      passport_seria: obj.passport_seria,
      whoGiven: obj.whoGiven,
      pass_date: obj.pass_date,
      comment: obj.comment
    };
    return this.http.post<any>(BACKEND_URL + 'ins/customer/add1', app);
  }

  // getting installment customer
  getInsCustomer(): any {
    return this.http.get<any>(BACKEND_URL + 'ins/customer/get');
  }

  // Post installment sale
  postInstallmentSale(obj: any): any {
    return this.http.post<any>(BACKEND_URL + 'ins/installment/sale', obj);
  }

  // get installment sale list
  getInsSaleList(): any {
    return this.http.get<any>(BACKEND_URL + 'ins/installment/sale/list');
  }

  // get installment operation list
  getInsOperationList(id: number): any {
    const app = {
      id
    };
    return this.http.post<any>(BACKEND_URL + 'ins/installment/oper/list', app);
  }

  getUserSaleList(id: number): any {
    const app = {
      id
    };
    return this.http.post<any>(BACKEND_URL + 'ins/installment/sale-id/list', app);
  }

  postOperAdd(obj: any): any {
    const app = {
      customer_id: obj.customer_id,
      comment: obj.comment,
      currency_type: obj.currency_type,
      ins_id: obj.ins_id,
      amount: obj.amount
    };
    return this.http.post<any>(BACKEND_URL + 'ins/installment/oper/add', app);
  }

  getPaymentGraph(id): any {
    const app = {
      id
    };
    return this.http.post<any>(BACKEND_URL + 'ins/installment/graph/get', app);
  }

  getDayPayments(): any {
    return this.http.get<any>(BACKEND_URL + 'ins/installment/day/payment');
  }

  getOwnerInfo(): any {
    return this.http.get<any>(BACKEND_URL + 'ins/installment/owner/info');
  }

  getLastInsSaleByUser(): any {
    return this.http.get<any>(BACKEND_URL + 'ins/installment/last/sale/user');
  }

  deleteSale(id: number): any {
    const app = {
      saleId: id
    };
    return this.http.post<any>(BACKEND_URL + 'ins/installment/sale/delete', app);
  }

  checkBlackList(customerId: number): any {
    const payload = {
      customer_id: customerId
    };
    return this.http.post<any>(BACKEND_URL + 'ins/installment/check/blacklist', payload);
  }
}
