import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ApplistModel} from './applist.model';
import {ApplistService} from './applist.service';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';

@Component({
  templateUrl: './applist.component.html',
  styleUrls: ['./applist.component.css']
})
export class ApplistComponent implements OnInit, OnDestroy {
  appList: ApplistModel[] = [];
  private appsSub: Subscription;
  isLoading = false;

  constructor(public applistService: ApplistService, private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.applistService.getApps();
    this.appsSub = this.applistService.getAppListUpdateListener()
      .subscribe((appList: ApplistModel[]) => {
        this.appList = appList;
        this.isLoading = false;
      });
    // this.authService.getDollar();
    this.authService.getDollarSelf();
  }

  getURL(url: string) {
    this.router.navigate(['../' +  url +  '']);
  }

  ngOnDestroy(): void {
    this.appsSub.unsubscribe();
  }

}
