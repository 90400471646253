import {Component, Inject, OnInit, Optional} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InventoryService} from '../../inventory.service';
import {Observable} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  templateUrl: './product-update.component.html',
  styleUrls: ['./product-update.component.css']
})
export class ProductUpdateComponent implements OnInit {
  updateForm: FormGroup;
  type$: Observable<any>;
  product: any;
  operType$: any = [
    {id: 1, name: 'Percent', checked: false},
    {id: 2, name: 'Summa', checked: true},
    {id: 3, name: 'Narxni ma\'lum summaga oshirish', checked: false}
  ];

  constructor(
    private fb: FormBuilder,
    private inventoryService: InventoryService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProductUpdateComponent>
  ) {
    this.product = data;
  }

  ngOnInit(): void {
    this.updateForm = this.fb.group({
      type: [''],
      productId: [this.product.id],
      productName: [this.product.product_name],
      operType: [2, Validators.required],
      base: [Number(this.product.cost_uz).toFixed(), [Validators.required, Validators.maxLength(10)]],
      cost: [Number(this.product.sell_cost_uz).toFixed(), [Validators.required, Validators.maxLength(10)]],
      warehouse: [this.product.warehouse]
    });
    this.type$ = this.inventoryService.getType();
  }

  updateCost() {
    if (this.updateForm.valid) {
      if (this.updateForm.value.type > 0) {
        this.inventoryService.updateProductCost(
          this.updateForm.value.type,
          this.updateForm.value.productId,
          this.updateForm.value.base,
          this.updateForm.value.cost,
          this.updateForm.value.operType,
          this.updateForm.value.warehouse
        ).subscribe(result => {
          if (result.message === '1') {

          }
        });
      } else {
        this.inventoryService.updateProductCost(
          0,
          this.updateForm.value.productId,
          this.updateForm.value.base,
          this.updateForm.value.cost,
          this.updateForm.value.operType,
          this.updateForm.value.warehouse
        ).subscribe(result => {
          if (result.message === '1') {

          }
        });
      }
    } else {
      alert('Barcha malumotlarni kiriting');
    }
  }
}
