import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Optional,
  Output,
} from "@angular/core";
import { InstallmentService } from "../../installment.service";
import {
  FormBuilder,
  FormControl,
  FormControlName,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith, take } from "rxjs/operators";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PosService } from "../../../../crm/pos/pos.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from "@techiediaries/ngx-qrcode";
import { Branch, Customer, InsOwner } from "../ins-model";

interface Food {
  value: string;
  viewValue: string;
}

@Component({
  templateUrl: "./ins-window.component.html",
  styleUrls: ["./ins-window.component.css"],
})
export class InsWindowComponent implements OnInit {
  customerControl = new FormControl();
  options: string[] = ["One", "Two", "Three"];
  filterProducts: Observable<Customer[]>;
  customer: Customer[] = [];
  insFormGroup: FormGroup;
  graph: any = [];
  ustama = 0;
  branchInfo: Branch;
  isSms = true;

  foods: Food[] = [
    { value: "0", viewValue: "0" },
    { value: "1", viewValue: "1" },
    { value: "2", viewValue: "2" },
    { value: "3", viewValue: "3" },
    { value: "4", viewValue: "4" },
    { value: "5", viewValue: "5" },
    { value: "6", viewValue: "6" },
    { value: "7", viewValue: "7" },
    { value: "8", viewValue: "8" },
    { value: "9", viewValue: "9" },
    { value: "10", viewValue: "10" },
    { value: "11", viewValue: "11" },
    { value: "12", viewValue: "12" },
    { value: "13", viewValue: "13" },
    { value: "14", viewValue: "14" },
    { value: "15", viewValue: "15" },
    { value: "16", viewValue: "16" },
    { value: "17", viewValue: "17" },
    { value: "18", viewValue: "18" },
    { value: "19", viewValue: "19" },
    { value: "20", viewValue: "20" },
    { value: "21", viewValue: "21" },
    { value: "22", viewValue: "22" },
    { value: "23", viewValue: "23" },
    { value: "24", viewValue: "24" },
    { value: "25", viewValue: "25" },
    { value: "26", viewValue: "26" },
    { value: "27", viewValue: "27" },
    { value: "28", viewValue: "28" },
    { value: "29", viewValue: "29" },
    { value: "30", viewValue: "30" },
  ];

  // valid data
  total = 0;
  withAdditionalTotal = 0;
  withoutInitial = 0;
  allTotal = 0;
  image = "assets/img/svg-icons/profile.png";
  id: number;
  customerName = "";
  today = "";
  isPrintable = false;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = "https://www.instagram.com/meinhaus.uz/";
  insCustomer: Customer;
  insGuarantor: Customer;
  OwnerInfo: InsOwner[] = [];
  ownerYATTName = "";
  ownerYATTAddress = "";
  isReady = false;
  timer = 3;
  isSend = false;
  productCount = 0;
  division = 0;
  saleId = 0;
  isInBlackList = false;
  saleCurrency = "UZS";

  constructor(
    private toast: ToastrService,
    private fb: FormBuilder,
    private insService: InstallmentService,
    private posService: PosService,
    private dialogRef: MatDialogRef<InsWindowComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.insFormGroup = this.fb.group({
      customer: ["", Validators.required],
      initialPay: ["0", [Validators.required, Validators.maxLength(12)]],
      ins_percent: [
        "0",
        [Validators.required, Validators.maxLength(2), Validators.minLength(1)],
      ],
      duration: [
        "",
        [Validators.required, Validators.maxLength(2), Validators.minLength(1)],
      ],
      guarantor: ["", Validators.required],
      comment: ["Izoh", [Validators.required, Validators.maxLength(30)]],
      crOn: ["", Validators.required],
      crOnPay: ["", Validators.required],
      products: [this.data.products, Validators.required],
      initialPayType: [Validators.required],
      isForCashier: ["1", Validators.required],
    });
    this.productCount = this.data.products.length;

    const date = new Date();
    const dd1 = String(date.getDate()).padStart(2, "0");
    const mm1 = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy1 = date.getFullYear();
    this.today = dd1 + "." + mm1 + "." + yyyy1;

    this.total = this.data.totalSum;

    this.filterProducts = this.customerControl.valueChanges.pipe(
      startWith(""),
      map((value) => this.filter(value))
    );
    this.insService.getInsCustomer().subscribe((result: Customer[]) => {
      this.customer = result;
    });
    this.posService.getBranchInfo().subscribe((x: Branch) => {
      this.branchInfo = x[0];
    });
    this.insService.getOwnerInfo().subscribe((x: InsOwner[]) => {
      this.OwnerInfo = x;
    });
  }

  filter(val: any): Customer[] {
    return this.customer.filter((item: any) => {
      // If the user selects an option, the value becomes a Human object,
      // therefore we need to reset the val for the filter because an
      // object cannot be used in this toLowerCase filter
      if (typeof val === "object") {
        val = "";
      } else {
        const TempString = item.full_name + item.id;
        return TempString.toLowerCase().includes(val.toLowerCase());
      }
    });
  }

  displayFn(item: any): any {
    // tslint:disable-next-line:triple-equals
    if (item == undefined) {
      return;
    }
    return item.full_name;
  }

  displayFn1(item: any): any {
    // tslint:disable-next-line:triple-equals
    if (item == undefined) {
      return;
    }
    return item.full_name;
  }

  setProduct(value: any) {
    this.image = value.photo;
    this.insFormGroup.controls.customer.setValue(value.id);
    this.insCustomer = value;
    this.checkBlackList();
  }

  checkBlackList(): void {
    if (this.insFormGroup.value.customer !== "") {
      this.insService
        .checkBlackList(this.insFormGroup.value.customer)
        .subscribe((x: any) => {
          if (x.data.success === false) {
            this.isInBlackList = true;
            this.toast.error("Sizning mijozingiz qora ro'yxatda mavjud!");
            this.insFormGroup.controls.customer.setValue("");
          } else {
            this.isInBlackList = false;
          }
        });
    }
  }

  onSelected(): void {
    this.validateWhite("duration", 0, 99);
    this.insFormGroup.controls.initialPay.setValue(0);
    this.graph = [];
    this.id = this.insFormGroup.value.duration;

    // Calculating installment payment graph
    let date = new Date(this.insFormGroup.value.crOnPay);
    for (let i = 1; i <= this.insFormGroup.value.duration; i++) {
      const dd = String(date.getDate()).padStart(2, "0");
      const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = date.getFullYear();
      const perMonth =
        this.withAdditionalTotal / this.insFormGroup.value.duration;
      this.graph.push({
        order_id: i,
        t_date: yyyy + "-" + mm + "-" + dd,
        t_sum: perMonth,
        p_date: yyyy + "-" + mm + "-" + dd,
        plastic: 0,
        cash: 0,
        signature: "",
      });
      date = new Date(date.getTime() + 30 * 24 * 60 * 60 * 1000);
    }
  }

  onKey() {
    this.validateWhite("initialPay", 0, 1000000000);
    this.withAdditionalTotal =
      Number(this.total - this.insFormGroup.value.initialPay) +
      (Number(this.total - this.insFormGroup.value.initialPay) *
        this.insFormGroup.value.ins_percent) /
        100;
    this.withoutInitial =
      this.withAdditionalTotal - Number(this.insFormGroup.value.initialPay);
    this.allTotal =
      this.withAdditionalTotal + Number(this.insFormGroup.value.initialPay);

    // Izoh
    this.division =
      Number(this.insFormGroup.value.initialPay) / this.productCount;
    // Izoh
    this.graph = [];
    // Calculating installment payment graph
    const date = new Date(this.insFormGroup.value.crOnPay);

    const date1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    for (let i = 1; i <= this.id; i++) {
      const dd = String(date1.getDate()).padStart(2, "0");
      const mm = String(date1.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = date1.getFullYear();
      const perMonth = this.withAdditionalTotal / this.id;
      this.graph.push({
        order_id: i,
        t_date: yyyy + "-" + mm + "-" + dd,
        t_sum: perMonth,
        p_date: yyyy + "-" + mm + "-" + dd,
        plastic: 0,
        cash: 0,
        signature: "",
      });
      // date1 = new Date(date1.getTime() + (30 * 24 * 60 * 60 * 1000));
      date1.setMonth(date1.getMonth() + 1);
    }
  }

  onSubmit() {
    if (this.insFormGroup.valid) {
      if (confirm("Savdoni amalga oshirasizmi?")) {
        this.insFormGroup.controls.initialPayType.enable();
        this.isSend = true;
        const obj = {
          orgSum: this.total,
          customer: this.insFormGroup.value.customer,
          month: this.id,
          withAdd: this.allTotal,
          initialPay: this.insFormGroup.value.initialPay,
          withoutInitial: this.withAdditionalTotal,
          products: this.insFormGroup.value.products,
          graph: this.graph,
          comment: this.insFormGroup.value.comment,
          cr_on: this.insFormGroup.value.crOn,
          guarantor: this.insFormGroup.value.guarantor,
          sms: this.isSms ? "ok" : "no",
          initialPayType: this.insFormGroup.value.initialPayType,
          isForCashier: this.insFormGroup.value.isForCashier,
          saleCurrency: this.saleCurrency,
        };
        this.insService.postInstallmentSale(obj).subscribe((x) => {
          if (x.message === 1) {
            this.isPrintable = true;
            this.insService
              .getLastInsSaleByUser()
              .pipe(take(1))
              .subscribe((r) => {
                this.saleId = r[0].id;
              });
          } else {
            this.toast.error(x.error);
          }
        });
      } else {
        this.toast.info("Hamma malumotlarini kiriting!");
      }
    }
  }

  onPrint() {
    this.isPrintable = true;
    window.print();
  }

  onClose() {
    this.dialogRef.close(1);
  }

  setGuarantor(value: any): void {
    this.insFormGroup.controls.guarantor.setValue(value.id);
    this.insGuarantor = value;
  }

  validateWhite(name: string, min: number, max: number) {
    const value: number = this.insFormGroup.get(name).value;
    if (value > max) {
      this.insFormGroup.get(name).setValue(max);
    } else if (value < min) {
      this.insFormGroup.get(name).setValue(min);
    } else if (value === null) {
      this.insFormGroup.get(name).setValue(min);
    } else {
      this.insFormGroup.get(name).setValue(value);
    }
  }

  onDataPick(): void {
    const date = new Date(this.insFormGroup.value.crOn);
    const dd1 = String(date.getDate()).padStart(2, "0");
    const mm1 = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy1 = date.getFullYear();
    this.today = dd1 + "." + mm1 + "." + yyyy1;

    // Calling functions
    // this.onSelected();
    this.onKey();
  }

  onOwnerNameChange(value: InsOwner): void {
    this.ownerYATTName = value.name;
    this.ownerYATTAddress = value.address;
  }

  onPrepareForm(): void {
    this.isReady = true;
  }

  isSmsChange() {
    this.isSms = !this.isSms;
  }
  onValChange(value: string): void {
    this.saleCurrency = value;

    if (value === "UZS") {
      this.insFormGroup.controls.initialPayType.enable();
      this.insFormGroup.controls.initialPayType.patchValue("1");
    } else {
      this.insFormGroup.controls.initialPayType.patchValue("2");
      // disable initial pay
      this.insFormGroup.controls.initialPayType.disable();
    }
  }
}
