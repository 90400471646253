import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';

const BACKEND_URL = environment.api_URL;

@Injectable({
  providedIn: 'root'
})
export class UserService {

 constructor(
   private http: HttpClient,
   private router: Router
 ) {}

 postUser(owner: number, branch: number, firstName: string, lastName: string, fatherName: string, userName: string,
          password: string, gender: number, phone: string, location: string) {
   const user: any = {
     owner,
     branch,
     firstName,
     lastName,
     fatherName,
     userName,
     password,
     gender,
     phone,
     location
   };
   this.http.post<{message: string}>(BACKEND_URL + 'user/signup', user)
     .subscribe(result => {
     });
 }

 getUserInfo(id: number) {
   const user: any = {
     id
   };
   return  this.http.post(BACKEND_URL + 'user/info', user);
 }
 getUserList() {
   return this.http.get<any>(BACKEND_URL + 'user/list');
 }

 getPermissionList() {
   return this.http.get<any>(BACKEND_URL + 'permission/per');
 }
 addPermission(id: number, status: boolean, userId: number) {
   const app = {
     id,
     status,
     userId
   };
   return this.http.post<any>(BACKEND_URL  + 'permission/add/per', app);
 }

 getOwners() {
   return this.http.get<any>(BACKEND_URL + 'permission/owner/list');
 }

 getBranchs() {
   return this.http.get<any>(BACKEND_URL + 'permission/branch/list');
 }

 getBranchesByOwner() {
   return this.http.get<any>(BACKEND_URL + 'permission/branch/list/byowner');
 }

 editProfile(id: number, firstName: string, lastName: string, fatherName: string, userName: string, password: string,
             location: string, gender: number, phone: string) {
   const app = {
     id,
     firstName,
     lastName,
     fatherName,
     userName,
     password,
     location,
     gender,
     phone
   };
   return this.http.post<any>(BACKEND_URL + 'user/edit', app);
 }

 getUserByOwner(): any {
   return this.http.get<any>(BACKEND_URL + 'user/owner/list');
 }

 getUserTypePermissions(id: number): any {
   const data = {
      id
   };
   return this.http.post<any>(BACKEND_URL + 'user/type/permissions', data);
 }

 addTypePermission(typeId: number, userId: number, checked: boolean): any {
   const data = {
     type_id: typeId,
     user_id: userId,
     checked
   };
   return this.http.post<any>(BACKEND_URL + 'user/type/add/permission', data);
 }

}
