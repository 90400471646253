import {Component, OnInit} from '@angular/core';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {UserService} from '../client-create/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {InventoryService} from '../../crm/inventory/inventory.service';
import {take} from 'rxjs/operators';
import {MatCheckboxChange} from '@angular/material/checkbox';

interface Type {
  id: number;
  user_id: number;
  type_id: number;
  checked: boolean;
}

@Component({
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.css']
})
export class PermissionComponent implements OnInit {

  rowData: any;
  gridApi;
  gridColumnApi;
  per: any;
  defaultColDef;
  public modules: Module[] = AllModules;
  perForm: FormGroup;
  fullName: string;
  types: Type[] = [];
  users;
  isLoading = false;
  isLoading1 = false;
  checkedTypes: Type[] = [];
  userId: number;

  constructor(
    private userService: UserService,
    private inventoryService: InventoryService,
    private fb: FormBuilder,
    private toast: ToastrService
  ) {
  }

  columnDefs = [
    {headerName: 'Username', field: 'user_name', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Ism', field: 'first_name', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Familiya', field: 'last_name', sortable: true, width: 150, filter: 'agTextColumnFilter'},
    {headerName: 'Korxona', field: 'owner_name', sortable: true, width: 100, filter: 'agTextColumnFilter'},
    {headerName: 'Filial', field: 'branch_name', sortable: true, width: 150, filter: 'agTextColumnFilter'},
    {headerName: 'Xolati', field: 'status', sortable: true, width: 100, filter: 'agTextColumnFilter'},
  ];

  ngOnInit(): void {
    this.perForm = this.fb.group({
      per: ['', Validators.required],
      userId: ['', Validators.required]
    });

    this.rowData = this.userService.getUserList();
    this.userService.getPermissionList()
      .subscribe((x: any) => {
        this.per = x;
      });

    // this.getTypes();
    // this.getUserByOwner();
  }

  getTypes(): void {
    this.inventoryService.getType().pipe(take(1))
      .subscribe(r => {
        this.types = r;
      });
  }

  getUserByOwner(): void {
    this.users = this.userService.getUserByOwner();
  }

  onDrag(id: number) {
    if (this.perForm.valid) {
      this.userService.addPermission(id, this.perForm.value.per, this.perForm.value.userId)
        .subscribe(x => {
          if (x.message === 1) {

          }
        });
    } else {
      this.toast.info('Foydalanuvchi tanlang', 'Ma\'lumot', {
        timeOut: 1000
      });
    }
  }

  onDoubleClick(data: any) {
    this.perForm.controls.userId.setValue(data.id);
    this.fullName = data.first_name + ' ' + data.last_name;
  }

  onDoubleClick1(data: any) {
    this.isLoading = true;
    this.userId = data.id;

    this.userService.getUserTypePermissions(data.id).pipe(take(1))
      .subscribe(result => {
        this.checkedTypes = result;
        this.types.forEach( r => {
          r.checked = false;
          this.checkedTypes.forEach(r1 => {
            if (r.id === r1.type_id) {
              r.checked = true;
            }
          });
        });
        this.isLoading = false;
      });
  }


  onChangeCheckBox(event: MatCheckboxChange, id: number): void {
    this.isLoading1 = true;
    if (this.userId > 0) {
      this.userService.addTypePermission(id, this.userId, event.checked)
        .pipe(take(1))
        .subscribe(r => {
          this.isLoading1 = false;
        });
    } else {
      this.isLoading1 = false;
      this.toast.info('Jadvaldan foydalanuvchini tanlang!');
    }

  }
}
