import {Component, OnInit} from '@angular/core';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {PosService} from '../../pos.service';
import {Router} from '@angular/router';
import {ProdService} from '../../../../production/prod.service';
import {CurrencyCellRendererQuantity} from '../../../../test/table-currency';

@Component({
  templateUrl: './day-stat.component.html',
  selector: 'app-day-stat'
})
export class DayStatComponent implements OnInit {

  constructor(
    private posService: PosService,
    private router: Router,
    private prodService: ProdService
    ) {
  }
  isLoading = false;
  rowData: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  isVisible: any;
  public modules: Module[] = AllModules;

  columnDefs = [
    {headerName: 'Filial', field: 'branch_name', sortable: true, width: 200},
    {headerName: 'Nomi', field: 'name', sortable: true, width: 250, filter: 'agTextColumnFilter', checkboxSelection: true},
    {headerName: 'Barcode', field: 'barcode', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Miqdori', field: 'quantity', sortable: true, filter: 'agTextColumnFilter', width: 150,
      cellRenderer: CurrencyCellRendererQuantity},
    {headerName: 'Dona', field: 'dona', sortable: true, filter: 'agTextColumnFilter', width: 150,
      cellRenderer: CurrencyCellRendererQuantity},
    {headerName: 'Summa', field: 'total_sum', sortable: true, filter: 'agNumberColumnFilter', width: 150,
     cellRenderer: CurrencyCellRendererQuantity}
  ];

  ngOnInit(): void {
    if (this.router.url === '/production') {
      // this.rowData = this.prodService.getDayLayoutStat();
    } else if (this.router.url === '/sale_history') {
      this.rowData = this.posService.getPosSaleDayStat();
    }
    console.log(this.router.url);
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    param.api.sizeColumnsToFit();
  }

  onDoubleClicked($event: any) {
    this.gridApi.exportDataAsExcel();
  }
}
