import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HrService} from '../hr.service';
import {MatDialog} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {CurrencyCellRendererSum} from '../../test/table-currency';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';

interface User {
  id: number;
  full_name: string;
}

@Component({
  templateUrl: 'hr-operations.component.html',
  styleUrls: ['hr-operations.component.css']
})
export class HrOperationsComponent implements  OnInit {

  operationForm: FormGroup;
  constructor(
    private hrService: HrService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal
  ) {
  }
  isLoading = false;
  rowData: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  isVisible: any;
  public modules: Module[] = AllModules;
  users: User[] = [];
  type: User[] = [
    {id: 1, full_name: 'Kirim'},
    {id: 2, full_name: 'Chiqim'}
  ];

  columnDefs = [
    {headerName: 'Sana', field: 'date', sortable: true, width: 150},
    {headerName: 'Ism', field: 'name', sortable: true, filter: 'agTextColumnFilter', width: 150, checkboxSelection: true},
    {headerName: 'Summa', field: 'amount', sortable: true, width: 200, filter: 'agTextColumnFilter', cellRenderer: CurrencyCellRendererSum},
    {headerName: 'Oldindan olingan', field: 'current_month_sum',
      sortable: true, width: 200, filter: 'agTextColumnFilter', cellRenderer: CurrencyCellRendererSum},
    {headerName: 'Joriy oy uchun qoldiq', field: 'subtraction', sortable: true, cellStyle: this.cellStyling,
      width: 200, filter: 'agTextColumnFilter', cellRenderer: CurrencyCellRendererSum},
    {headerName: 'o\'tgan oy uchun qoldiq', field: 'saldo', sortable: true, cellStyle: this.saldoStyling,
      width: 200, filter: 'agTextColumnFilter', cellRenderer: CurrencyCellRendererSum},
    {headerName: 'Izox', field: 'comment_s', sortable: true, width: 300, filter: 'agTextColumnFilter'},
  ];

  ngOnInit(): void {
    this.hrService.getHrUsers()
      .subscribe((x: User[]) => {
        this.users = x;
        console.log(this.users);
      });
    this.operationForm = this.fb.group({
      user: ['', Validators.required],
      amount: ['', [Validators.required, Validators.maxLength(15)]],
      comment: ['', [Validators.required, Validators.maxLength(200)]]
    });
    this.rowData = this.hrService.getHrOperations();
  }

  openXl(content: any): void {
    this.modalService.open(content, { size: 'xl' });
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    param.api.sizeColumnsToFit();
  }

  onSubmit() {
    this.hrService.addHrOperation(this.operationForm.value)
      .subscribe(result => {
        if (result.message === 1) {
          this.rowData = this.hrService.getHrOperations();
          this.operationForm.reset('');
        }
      });
  }

  onDelete(): void {
    const selectedRow = this.gridApi.getSelectedRows()[0].id;
    if (selectedRow > 0) {
      if (confirm('Rostdan ochirishni xoxlaysizmi?')) {
        this.hrService.deleteOper(selectedRow)
          .subscribe(x => {
            if (x.message === 1) {
              this.rowData = this.hrService.getHrOperations();
            }
          });
      }
    }
  }

  cellStyling(params: any) {
    if (params.data.subtraction >= 0) {
      return {backgroundColor: '#17A589', color: 'white'};
    } else {
      return {backgroundColor: 'red', color: 'white'};
    }
  }

  saldoStyling(params: any) {
    if (params.data.saldo >= 0) {
      return {backgroundColor: '#17A589', color: 'white'};
    } else {
      return {backgroundColor: 'red', color: 'white'};
    }
  }
}
