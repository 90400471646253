import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {dateParser} from '../dateParser';
import {Observable, Subject} from 'rxjs';

const BACKEND_URL = environment.api_URL;

@Injectable({providedIn: 'root'})
export class PosService {
  private subject = new Subject<any>();

  constructor(private http: HttpClient) {
  }

  sentData(data: any) {
    this.subject.next(data);
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }

  postSale(customer: number, totalSum: number, totalHr: number, totalDollar: number,
           givenSum: number, givenHr: number, givenDollar: number, products: any,
           originSum: number, subOriginSum: number,
           delivery: number, comment: string, sms: number): any {

    const sale: any = {
      customer,
      totalSum,
      totalHr,
      totalDollar,
      givenSum,
      givenHr,
      givenDollar,
      products,
      originSum,
      subOriginSum,
      delivery,
      comment,
      sms
    };
    return this.http.post<{ message: string }>(BACKEND_URL + 'crm/pos/sale/post', sale);
  }

  getSaleHistory(d1: Date, d2: Date, obj: any, isFirst: boolean) {
    let app;
    if (isFirst) {
      app = obj;
    } else {
      const date1 = dateParser(d1);
      const date2 = dateParser(d2);
      const diff = Math.abs(d1.getTime() - d2.getTime());
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

      if (diffDays < 8) {
        localStorage.setItem('s_d1', date1);
        localStorage.setItem('s_d2', date2);

        app = {
          d1: localStorage.getItem('s_d1'),
          d2: localStorage.getItem('s_d2'),
        };
      } else {
        app = {
          d1: date1,
          d2: date2,
        };
      }

    }
    return this.http.post<any>(BACKEND_URL + 'crm/pos/sale_history/get', app);
  }

  getPosSaleProducts(d1: Date, d2: Date, obj: any, isFirst: boolean) {
    let app;
    if (isFirst) {
      app = obj;
    } else {
      const date1 = dateParser(d1);
      const date2 = dateParser(d2);
      const diff = Math.abs(d1.getTime() - d2.getTime());
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

      if (diffDays < 8) {
        localStorage.setItem('sp_d1', date1);
        localStorage.setItem('sp_d2', date2);

        app = {
          d1: localStorage.getItem('sp_d1'),
          d2: localStorage.getItem('sp_d2'),
        };
      } else {
        app = {
          d1: date1,
          d2: date2
        };
      }
    }
    return this.http.post<any>(BACKEND_URL +
      'crm/pos/sale/products/history/get', app);
  }

  addCustomer(name: string, phone: string) {
    const open = '1';
    const company: any = {
      name,
      email: 'test@mail.com',
      comment: 1,
      open,
      id: 0,
      type: 2,
      phone,
      location: '40.63513827765001, 71.08016689633445'
    };
    return this.http.post<{ message: string }>(BACKEND_URL + 'crm/inventory/company/add', company);
  }

  posReturnSale(id: number) {
    const app = {
      id
    };
    return this.http.post<{ message: string }>(BACKEND_URL + 'crm/pos/sale/return', app);
  }

  getBranchInfo(): any {
    return this.http.get<any>(BACKEND_URL + 'crm/pos/branch/info');
  }

  getSaleCheck(id: number): any {
    const app = {
      id
    };
    return this.http.post<any>(BACKEND_URL + 'crm/pos/check/info', app);
  }

  // get sale check products with layout quantity
  getCheckInfoWithLayoutQuantity(id: number): any {
    const app = {
      sale_id: id
    };
    return this.http.post<any>(BACKEND_URL + 'crm/pos/check/info/layout', app);
  }

  getCheckInfoProducts(id: number): any {
    const app = {
      sale_id: id
    };
    return this.http.post<any>(BACKEND_URL + 'crm/pos/check/info/products', app);
  }

  getPosProducts(): any {
    return this.http.get<any>(BACKEND_URL + 'crm/pos/products/get');
  }

  getPosSaleDayStat(): any {
    return this.http.get<any>(BACKEND_URL + 'crm/pos/day/stat');
  }

  getPosSale10DayStat(data: any): any {
    const from = dateParser(data.from);
    const to = dateParser(data.to);
    const app = {
      from,
      to
    };
    return this.http.post<any>(BACKEND_URL + 'crm/pos/10day/stat', app);
  }

  getPosProductsType(): any {
    return this.http.get(BACKEND_URL + 'pos/type/get');
  }

  getClientReport(date1: Date, date2: Date, url: string): any {
    const app = {
      from: dateParser(date1),
      to: dateParser(date2)
    };
    return this.http.post<any>(BACKEND_URL + url, app);
  }

  getAccountingPermissions() {
    return this.http.get<any>(BACKEND_URL + 'permission/get/permissions');
  }

  getDollarSelf(): any {
    return this.http.get<any>(BACKEND_URL + 'crm/cashier/dollar/self/get');
  }
}
