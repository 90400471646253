import {Component, ElementRef, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {InventoryService} from '../inventory.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../../system/client-create/user.service';
import {take} from "rxjs/operators";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export interface Type {
  id: number;
  name: string;
}

export interface Invoice {
  id: number;
  name: string;
}

export interface Product {
  id: number;
  w_id: number;
  type_id: number;
  barcode: string;
  name: number;
  branch_id: string;
  unit: number;
}

interface Checked {
  id: number;
  name: string;
}

@Component({
  templateUrl: "./inventory-add.component.html",
  styleUrls: ["./inventory-add.component.css"],
})
export class InventoryAddComponent implements OnInit {
  @ViewChild("barcode-print") htmlData!: ElementRef;
  productForm: FormGroup;
  type$: Observable<any>;
  isOpen: Checked[] = [
    { id: 1, name: "Yes" },
    { id: 0, name: "No" },
  ];

  units: Checked[] = [
    { id: 1, name: "Dona" },
    { id: 2, name: "KG" },
    { id: 3, name: "Metr" },
    { id: 4, name: "M^2" },
    { id: 5, name: "Litr" },
  ];
  checked = false;
  invoice: Invoice[] = [];
  product: Product[] = [];
  warehouse$: Observable<any>;
  data1: any;
  imagePreview: string | ArrayBuffer;
  imagePreviews: string[] = [];
  imagePreviews1: string[] = [];
  isLoading = false;
  branches = [];
  files: FileList;

  constructor(
    private inventoryService: InventoryService,
    private router: Router,
    public dialogRef: MatDialogRef<InventoryAddComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastrService,
    private userService: UserService
  ) {
    this.data1 = data;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.productForm = new FormGroup({
      branch: new FormControl(null, Validators.required),
      productId: new FormControl(0, Validators.required),
      warehouse: new FormControl(null, Validators.required),
      barcode: new FormControl(null, [
        Validators.required,
        Validators.maxLength(13),
      ]),
      type: new FormControl(null, Validators.required),
      name: new FormControl(null, [
        Validators.required,
        Validators.maxLength(45),
      ]),
      isOpen: new FormControl(true, Validators.required),
      image: new FormControl(null),
      isMobile: new FormControl(true, Validators.required),
      layout_quantity: new FormControl(1, [
        Validators.required,
        Validators.maxLength(1000000),
      ]),
      unit: new FormControl(null, Validators.required),
    });
    this.userService.getBranchesByOwner().subscribe((x) => {
      this.branches = x;
    });
    this.inventoryService.getInvoice().subscribe((result: Invoice[]) => {
      this.invoice = result;
    });
    this.inventoryService
      .getProduct(this.data1.id)
      .subscribe((result: Product[]) => {
        this.product = result;
        if (this.data1.id > 0) {
          this.productForm.controls.warehouse.patchValue(this.product[0].w_id);
          this.productForm.controls.type.patchValue(this.product[0].type_id);
          this.productForm.controls.branch.patchValue(
            this.product[0].branch_id
          );
          this.productForm.controls.unit.setValue(this.product[0].unit);
          this.isLoading = false;
        } else {
          this.productForm.controls.layout_quantity.setValue(1);
        }
        this.isLoading = false;
      });
    this.warehouse$ = this.inventoryService.getWarehouse();
    this.type$ = this.inventoryService.getType();
    this.productForm.controls.productId.setValue(this.data1.id);
    this.productForm.controls.name.setValue(this.data1.product_name);
    this.productForm.controls.barcode.setValue(this.data1.barcode);
    this.productForm.controls.layout_quantity.setValue(
      this.data1.layout_quantity
    );

    // isActive
    if (this.data1.id > 0) {
      if (this.data1.is_open === "open") {
        this.productForm.controls.isOpen.setValue(true);
        this.checked = true;
      } else {
        this.productForm.controls.isOpen.setValue(false);
        this.checked = false;
      }
      // isMobile
      if (this.data1.status === "A") {
        this.productForm.controls.isMobile.setValue(true);
      } else {
        this.productForm.controls.isMobile.setValue(false);
      }
    }
    this.imagePreview = this.data1.image_url;
    this.inventoryService
      .getProductImages(this.data1.id)
      .pipe(take(1))
      .subscribe((re) => {
        this.imagePreviews1 = re.data;
      });
  }

  onImagePicked(event: any) {
    this.files = (event.target as HTMLInputElement).files;
    const file = (event.target as HTMLInputElement).files;
    if (file && file[0]) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < file.length; i++) {
        const reader = new FileReader();
        reader.onload = (event1: any) => {
          this.imagePreview = event1.target.result;
          this.imagePreviews.push(event1.target.result);
        };
        reader.readAsDataURL(file[i]);
      }
    }
    this.productForm.patchValue({ image: file });
    this.productForm.get("image").updateValueAndValidity();
  }

  onSubmit() {
    if (this.productForm.valid) {
      this.inventoryService
        .postProduct(
          this.productForm.value.branch,
          this.productForm.value.productId,
          this.productForm.value.warehouse,
          this.productForm.value.barcode,
          this.productForm.value.type,
          this.productForm.value.name,
          this.productForm.value.isOpen,
          this.files,
          this.productForm.value.isMobile,
          this.productForm.value.layout_quantity,
          this.productForm.value.unit
        )
        .subscribe((result) => {
          if (result.message === "1") {
            this.productForm.controls.name.reset();
            this.productForm.controls.barcode.reset();
            this.productForm.controls.productId.setValue(0);
            this.productForm.controls.image.reset();
            this.imagePreview = "";
            this.productForm.controls.layout_quantity.setValue(1);
          }
        });
    } else {
      this.toast.info("Xamma ma'lumotlarni kiriting!");
    }
  }

  onDelete(id: any): void {
    if (confirm("Rasmni o'chirasizmi ?")) {
      this.inventoryService
        .deleteProductImages(id)
        .pipe(take(1))
        .subscribe((re) => {
          if (re.success) {
            this.inventoryService
              .getProductImages(this.data1.id)
              .pipe(take(1))
              .subscribe((re1) => {
                this.imagePreviews1 = re1.data;
              });
          }
        });
    }
  }
  generateBarcode() {
    this.productForm.controls.barcode.setValue(this.generateEAN13());
  }

  generateEAN13() {
    var ean13 = "";
    for (var i = 0; i < 12; i++) {
      ean13 += Math.floor(Math.random() * 10);
    }
    var checkDigit = this.getEAN13CheckDigit(ean13);
    return ean13 + checkDigit;
  }

  getEAN13CheckDigit(code) {
    var sum = 0;
    for (var i = code.length - 1; i >= 0; i--) {
      var weight = i % 2 === 0 ? 1 : 3;
      sum += weight * parseInt(code.charAt(i));
    }
    var checkDigit = (10 - (sum % 10)) % 10;
    return checkDigit.toString();
  }

  printBarcode(): void {
    const printContent = document.getElementById("barcode-print");
    let htmlPrint =
      "" +
      "<style>" +
      "#barcode-print {\n" +
      "width: 400px;\n" +
      "display: flex;\n" +
      "justify-content: center;\n" +
      "align-items: center;\n" +
      "}" +
      "</style>";
    htmlPrint += printContent.outerHTML;
    const WindowPrt = window.open();
    WindowPrt.document.write(printContent.outerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }

  public openPDF(): void {
    let DATA: any = document.getElementById("barcode-print");
    html2canvas(DATA).then((canvas) => {
      const FILEURI = canvas.toDataURL("image/png");
      let PDF = new jsPDF("l", "cm", [2, 4]);
      PDF.addImage(FILEURI, "PNG", 0, 0, 4, 2);
      PDF.save(this.data1.product_name + ".pdf");
    });
  }
}
