import {Component, OnInit} from '@angular/core';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {Router} from '@angular/router';
import {InventoryService} from '../../inventory.service';
import {UserService} from '../../../../system/client-create/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {take} from 'rxjs/operators';
import {MatCheckboxChange} from '@angular/material/checkbox';

interface Type {
  id: number;
  user_id: number;
  type_id: number;
  checked: boolean;
}
@Component({
  templateUrl: './in-property.component.html',
  styleUrls: ['./in-property.component.css']
})
export class InPropertyComponent  implements OnInit {

  rowDataType: any;
  rowDataWarehouse: any;
  gridApi;
  gridApi1;
  gridColumnApi;
  defaultColDefType;
  isLoading = false;

  rowData: any;
  per: any;
  defaultColDef;
  perForm: FormGroup;
  fullName: string;
  types: Type[] = [];
  users;
  isLoading1 = false;
  checkedTypes: Type[] = [];
  userId: number;
  public modules: Module[] = AllModules;
  constructor(
    private router: Router,
    private userService: UserService,
    private inventoryService: InventoryService,
    private fb: FormBuilder,
    private toast: ToastrService
  ) {
    this.defaultColDefType = { resizable: true };
  }


  columnDefsType = [
    {headerName: '#', field: 'id', sortable: true, filter: 'agTextColumnFilter', width: 70, checkboxSelection: true },
    {headerName: 'Filial', field: 'branch_name', sortable: true, filter: 'agTextColumnFilter', width: 300 },
    {headerName: 'Nomi', field: 'name', sortable: true, filter: 'agTextColumnFilter', width: 300 },
    {headerName: 'Birlik', field: 'unit', sortable: true, filter: 'agTextColumnFilter' },
    {headerName: 'Sotuvda', field: 'is_seller', sortable: true, filter: 'agTextColumnFilter', width: 100 },
  ];
  columnDefsWarehouse = [
    {headerName: '#', field: 'id', sortable: true, filter: 'agTextColumnFilter', width: 70, checkboxSelection: true},
    {headerName: 'Filial', field: 'branch_name', sortable: true, filter: 'agTextColumnFilter', width: 300 },
    {headerName: 'Nomi', field: 'name', sortable: true, filter: 'agTextColumnFilter', width: 300 },
    {headerName: 'Izox', field: 'comment', width: 300 },
    {headerName: 'Xolati', field: 'status', sortable: true},
  ];

  columnDefs = [
    {headerName: 'Username', field: 'user_name', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Ism', field: 'first_name', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Familiya', field: 'last_name', sortable: true, width: 150, filter: 'agTextColumnFilter'},
    {headerName: 'Korxona', field: 'owner_name', sortable: true, width: 100, filter: 'agTextColumnFilter'},
    {headerName: 'Filial', field: 'branch_name', sortable: true, width: 150, filter: 'agTextColumnFilter'},
    {headerName: 'Xolati', field: 'status', sortable: true, width: 100, filter: 'agTextColumnFilter'},
  ];
  ngOnInit(): void {
    this.rowDataType = this.inventoryService.getType();
    this.rowDataWarehouse = this.inventoryService.getWarehouse();

    this.perForm = this.fb.group({
      per: ['', Validators.required],
      userId: ['', Validators.required]
    });

    this.rowData = this.userService.getUserList();
    this.userService.getPermissionList()
      .subscribe((x: any) => {
        this.per = x;
      });

    this.getTypes();
    this.getUserByOwner();
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    param.api.sizeColumnsToFit();
  }
  onFirstDataRendered1(params): void {
    this.gridApi1 = params.api;
  }

  addType() {
    this.router.navigate(['../../inventory/type/add']);
  }

  addWarehouse() {
    this.router.navigate(['../../inventory/warehouse/add']);
  }

  goBack() {
    this.router.navigate(['inventory']);
  }

  editType(): void {
    const selectedRow = this.gridApi.getSelectedRows()[0];
    if (this.gridApi.getSelectedRows().length > 0) {
      this.router.navigate(['../../inventory/type/add'], {state: selectedRow});
    } else {
      alert('Jadvaldan qatorni tanlang !');
    }
  }

  editWarehouse(): void {
    const selectedRow1 = this.gridApi1.getSelectedRows()[0];
    if (this.gridApi1.getSelectedRows().length > 0) {
      this.router.navigate(['../../inventory/warehouse/add'], {state: selectedRow1});
    } else {
      alert('Jadvaldan qatorni tanlang !');
    }
  }

  getTypes(): void {
    this.inventoryService.getType().pipe(take(1))
      .subscribe(r => {
        this.types = r;
      });
  }

  getUserByOwner(): void {
    this.users = this.userService.getUserByOwner();
  }

  onDrag(id: number) {
    if (this.perForm.valid) {
      this.userService.addPermission(id, this.perForm.value.per, this.perForm.value.userId)
        .subscribe(x => {
          if (x.message === 1) {

          }
        });
    } else {
      this.toast.info('Foydalanuvchi tanlang', 'Ma\'lumot', {
        timeOut: 1000
      });
    }
  }

  onDoubleClick(data: any) {
    this.perForm.controls.userId.setValue(data.id);
    this.fullName = data.first_name + ' ' + data.last_name;
  }

  onDoubleClick1(data: any) {
    this.isLoading = true;
    this.userId = data.id;

    this.userService.getUserTypePermissions(data.id).pipe(take(1))
      .subscribe(result => {
        this.checkedTypes = result;
        this.types.forEach( r => {
          r.checked = false;
          this.checkedTypes.forEach(r1 => {
            if (r.id === r1.type_id) {
              r.checked = true;
            }
          });
        });
        this.isLoading = false;
      });
  }


  onChangeCheckBox(event: MatCheckboxChange, id: number): void {
    this.isLoading1 = true;
    if (this.userId > 0) {
      this.userService.addTypePermission(id, this.userId, event.checked)
        .pipe(take(1))
        .subscribe(r => {
          this.isLoading1 = false;
        });
    } else {
      this.isLoading1 = false;
      this.toast.info('Jadvaldan foydalanuvchini tanlang!');
    }

  }
}
