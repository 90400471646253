import {Component, OnDestroy, OnInit} from '@angular/core';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {CashierService} from '../../cashier.service';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CurrencyCellRendererDollar} from '../../../../test/table-currency';

@Component({
  selector: 'app-cashier-operations',
  templateUrl: './cashier-operations.component.html',
  styleUrls: ['./cashier-operations.component.css']
})
export class CashierOperationsComponent implements OnInit, OnDestroy {
  rowData: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  isReturn = false;
  public modules: Module[] = AllModules;
  private inOutSubs: Subscription;
  private operationSubs: Subscription;
  filterForm: FormGroup;
  date1 = '';
  date2 = '';
  maxDate: Date;
  isLoading  = false;

  constructor(
    private cashierService: CashierService,
    private fb: FormBuilder
    ) {
  }


  // Table header names
  columnDefs = [ // Sale operations table
    {headerName: 'Date', field: 'date', sortable: true, filter: 'agTextColumnFilter', width: 170},
    {headerName: 'Company', field: 'company', sortable: true, filter: 'agTextColumnFilter', resizable: true, checkboxSelection: true},
    {headerName: 'Operation', field: 'opertype', sortable: true, filter: 'agTextColumnFilter', width: 100},
    {headerName: 'Currency', field: 'currency', sortable: true, filter: 'agTextColumnFilter', width: 100},
    {
      headerName: 'Sum', field: 'sum', sortable: true, filter: 'agNumberColumnFilter', width: 150, resizable: true,
      valueFormatter: this.currencyFormatter
    },
    {
      headerName: 'Dollar', field: 'dollar', sortable: true, filter: 'agNumberColumnFilter', width: 150, resizable: true,
      cellRenderer: CurrencyCellRendererDollar
    },
    {
      headerName: 'Hr', field: 'hr', sortable: true, filter: 'agNumberColumnFilter', width: 150, resizable: true,
      valueFormatter: this.currencyFormatter
    },
    {headerName: 'Ishchi', field: 'cr_by', sortable: true, resizable: true, width: 100},
    {headerName: 'Comment', field: 'comment', sortable: true, resizable: true},
  ];

  currencyFormatter(params) {
    // return '' + this.formatNumber(params.value);
    return Math.floor(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  ngOnInit(): void {
    this.date1 = localStorage.getItem('c_1');
    this.date2 = localStorage.getItem('c_2');
    this.initFilterForm();
    const app = {
      d1: localStorage.getItem('c_1'),
      d2: localStorage.getItem('c_2'),
    };
    const  a = new Date();
    this.rowData = this.cashierService.getInOut(a, a, app, true);
    this.inOutSubs = this.cashierService.getInOutSubs()
      .subscribe(result => {
        if (result.message === '1') {
          this.ngOnInit();
        }
      });
    this.operationSubs = this.cashierService.cast$
      .subscribe(result => {
        if (result.message === '1') {
          this.ngOnInit();
        }
      });
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
  }

  onBtnExport1() {
    this.gridApi.exportDataAsExcel();
  }

  onBtnReturn() {
    const selectedRow = this.gridApi.getSelectedRows()[0];
    if (confirm(selectedRow.id + ' raqamli operatsiyani qaytarasizmi?')) {
      this.cashierService.postInOutReturn(selectedRow.id);
    }
  }

  ngOnDestroy(): void {
    this.inOutSubs.unsubscribe();
    this.operationSubs.unsubscribe();
  }

  initFilterForm(): void {
    this.filterForm = this.fb.group({
      s_date: [Validators.required],
      e_date: [Validators.required]
    });
  }

  onSubmit(): void {
    if (this.filterForm.valid) {
      const app = {
        d1: localStorage.getItem('c_1'),
        d2: localStorage.getItem('c_2'),
      };
      this.rowData = this.cashierService.getInOut(this.filterForm.value.s_date, this.filterForm.value.e_date, app, false);
      this.date1 = localStorage.getItem('c_1');
      this.date2 = localStorage.getItem('c_2');
    }
  }

  onDateSelected(date: Date) {
    this.filterForm.controls.e_date.reset();
    this.maxDate = new Date(date);
    this.maxDate.setDate(this.maxDate.getDate() + 10);
  }
}
