import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../notification.service';

interface Notification {
  id: number;
  title: string;
  content: string;
  user_id: number;
  cr_on: string;
}

@Component({
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit {
  isLoading = false;
  panelOpenState = false;
  list: Notification[] = [];
  constructor(
    private notification: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.notification.getNotification()
      .subscribe((x: Notification[]) => {
        this.list = x;
        this.isLoading = false;
      });
  }

  onView(id: number) {
    this.notification.onView(id)
      .subscribe(x => {
        this.notification.updateNotification(true);
      });
  }
}
