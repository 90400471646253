import {Component, OnInit} from '@angular/core';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {MobileService} from '../mobile.service';
import {CurrencyCellRendererQuantity} from '../../../test/table-currency';
import {dateParser} from "../../dateParser";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.css']
})
export class DeliveryComponent implements OnInit {

  rowData: any = [];
  gridApi;
  gridColumnApi;
  defaultColDef;
  public modules: Module[] = AllModules;
  exchangeFilterForm: FormGroup;

  constructor(
    private mobileService: MobileService,
    private fb: FormBuilder
  ) {
    this.defaultColDef = {resizable: true};
  }

  columnDefs = [
    {headerName: 'Sana', field: 'cr_on', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Xaridor', field: 'company_name', sortable: true, filter: 'agTextColumnFilter', width: 300},
    {headerName: 'Yetkazib beruvchi', field: 'deliverer_name', sortable: true, filter: 'agTextColumnFilter', width: 300},
    {headerName: 'Summa', field: 'total_sum', sortable: true, cellRenderer: CurrencyCellRendererQuantity,
      filter: 'agTextColumnFilter', width: 200},
    {headerName: 'Status', field: 'status', sortable: true, filter: 'agTextColumnFilter', width: 50},
    {headerName: 'Izox', field: 'comment_s', sortable: true, filter: 'agTextColumnFilter', width: 400}
  ];

  ngOnInit(): void {
    const date1 = new Date();
    const to = dateParser(date1);
    const mm = String(date1.getMonth() + 1).padStart(2, '0');
    const yyyy = date1.getFullYear();
    const from = yyyy + '-' + mm + '-' + '01';
    this.initExchangeForm(from, to);
    this.rowData = this.mobileService.getDeliveryList(this.exchangeFilterForm.value.d1, this.exchangeFilterForm.value.d2);
  }

  initExchangeForm(from: any, to: any): void {
    this.exchangeFilterForm = this.fb.group({
      d1: [from],
      d2: [to]
    });
  }

  onSubmitExchangeFilter() {
    if (this.exchangeFilterForm.valid) {
      this.rowData = this.mobileService.getDeliveryList(this.exchangeFilterForm.value.d1, this.exchangeFilterForm.value.d2);
    }
  }
}
