import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  templateUrl: './installment-list.component.html',
  styleUrls: ['./installment-list.component.css']
})
export class InstallmentListComponent implements OnInit {

  isVisible = true;

  constructor(
    public router: Router
  ) {
  }

  ngOnInit(): void {
    console.log(this.router.url);

  }

}
