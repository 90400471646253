import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../auth/auth.guard';
import {ProductionComponent} from './production-list/production.component';
import {ConstructorComponent} from './production-list/constructor/constructor.component';
import {ProductionStatComponent} from './production-main/production-stat.component';

const routes: Routes = [
  { path: 'production', component: ProductionStatComponent, canActivate: [AuthGuard] },
  { path: 'production/line', component: ProductionComponent, canActivate: [AuthGuard] },
  { path: 'production/constructor', component: ConstructorComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class ProdRoutingModule {
}
