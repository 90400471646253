import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith, take} from 'rxjs/operators';
import {InventoryService} from '../../inventory/inventory.service';
import {CustomerAddComponent} from '../customer-add/customer-add.component';
import {MatDialog} from '@angular/material/dialog';
import {PosService} from '../pos.service';
import {TabService} from '../../tab.service';
import {ToastrService} from 'ngx-toastr';
import {InsWindowComponent} from '../../../installment/installment-apps/installment-sale/installment-window/ins-window.component';
import {AccountingService} from '../../Accounting/accounting.service';
import {BasketArr1} from '../../dateParser';
import {CashierService} from '../../cashier/cashier.service';

interface Product {
  id: number;
  product_name: string;
  barcode: string;
  currency: string;
  quantity: number;
  cost: number;
  sum: number;
  image_url: string;
  sell_cost_uz: number;
}

interface Currency {
  id: number;
  name: string;
}

@Component({
  styleUrls: ['./sale.component.css'],
  templateUrl: './sale.component.html',
  providers: [TabService]
})
export class SaleComponent implements OnInit, AfterViewInit {
  saleForm: FormGroup;
  barcodeForm: FormGroup;
  saleControl = new FormControl();
  public products: FormArray;
  filterProducts: Observable<Product[]>;
  currency$: Observable<Currency[]>;
  type$: any = [];
  product: Product[] = [];
  productList: Product[] = [];
  totalQuantity = 0;
  totalSum: any[];
  totalDollar: any[];
  totalHr: any[];
  isLoading = false;
  originalSumId: any = [];
  originalCostSum = 0;
  mobileProducts = [];
  dona = 0;
  pack = 0;

  branchInfo;

  // Simple sell
  isSimpleSell = false;
  // Installment sell
  isInstallmentSell = false;
  // @ts-ignore
  @ViewChild('name') vc: ElementRef;
  @ViewChild('costRef') costRef: ElementRef;
  @ViewChild('barcodeRef') barcodeRef: ElementRef;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private inventoryService: InventoryService,
    public dialog: MatDialog,
    private posService: PosService,
    private toast: ToastrService,
    private accountingService: AccountingService,
    private cashierService: CashierService
  ) {
  }

  ngOnInit(): void {
    this.getBranchInfo();

    this.isLoading = true;
    this.mobileProducts = history.state[0];
    localStorage.setItem('temp_cl', history.state[1]);

    this.saleForm = this.formBuilder.group({
      id1: ['', Validators.required],
      name1: ['', Validators.required],
      barcode1: ['', Validators.required],
      currency1: [1, Validators.required],
      cost1: [0, Validators.required],
      quantity1: [0, Validators.required],
      dona1: [0, Validators.required],
      unit1: ['', Validators.required],
      image1: [''],
      totalSum: [0, Validators.required],
      totalDollar: [0, Validators.required],
      totalHr: [0, Validators.required],
      products: this.formBuilder.array([])
    });

    this.barcodeForm = this.formBuilder.group({
      barcode: ['', [Validators.required, Validators.maxLength(20)]]
    });

    this.filterProducts = this.saleControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    );
    // Getting currencies with async way
    this.currency$ = this.inventoryService.getCurrency();
    this.posService.getPosProductsType()
      .subscribe(x => {
        this.type$ = x;
        const app = {
          id: 0, name: 'main.all'
        };
        this.type$.push(app);
        this.type$.sort((a, b) => (a.id - b.id));
      });
    this.posService.getPosProducts()
      .subscribe((result: Product[]) => {
        this.product = result;
        this.productList = result;
        this.isLoading = false;
      });

    this.accountingService.getAccountingPermissions()
      .subscribe(result => {
        if (result.appList[0] !== undefined) {
          for (const [i, v] of result.appList.entries()) {
            if (v.name === 'ins_sell') {
              this.isInstallmentSell = true;
            } else if (v.name === 'simple_sell') {
              this.isSimpleSell = true;
            }
          }
        }
      });

    if (history.state[0] !== undefined) {
      for (let i = 0; i < this.mobileProducts.length; i++) {
        this.setProductPick12(this.mobileProducts[i]);
      }
    }
  }
  public ngAfterViewInit(): void {
    this.barcodeRef.nativeElement.focus();
  }
  filter(val: any): Product[] {
    return this.product.filter((item: any) => {
      // If the user selects an option, the value becomes a Human object,
      // therefore we need to reset the val for the filter because an
      // object cannot be used in this toLowerCase filter
      if (typeof val === 'object') {
        val = '';
      } else {
        const TempString = item.product_name + item.barcode;
        return TempString.toLowerCase().includes(val.toLowerCase());
      }
    });
  }
  displayFn(item: any): any {
    // tslint:disable-next-line:triple-equals
    if (item == undefined) {
      return;
    }
    return item.product_name;
  }
  // Set product details when choose from auto complete or grid
  setProduct(details: any) {
    this.dona = Number(details.layout_quantity);
    if (this.saleForm.value.id1 === details.id) {
      this.saleForm.controls.id1.setValue(details.id);
      this.saleForm.controls.name1.setValue(details.product_name);
      this.saleForm.controls.barcode1.setValue(details.barcode);
      this.saleForm.controls.image1.setValue(details.image_url);
      this.saleForm.controls.currency1.setValue(this.saleForm.value.currency1);
      this.saleForm.controls.unit1.setValue(details.product_unit);

      if (Number(this.saleForm.value.currency1) === 1) {
        this.saleForm.controls.cost1.setValue(Number(details.sell_cost_uz));
      } else if (Number(this.saleForm.value.currency1) === 2) {
        this.saleForm.controls.cost1.setValue(Number(details.sell_cost_us));
      } else {
        this.saleForm.controls.cost1.setValue(Number(details.sell_cost_uz));
      }
      this.saleForm.controls.quantity1.setValue(Number(this.saleForm.value.quantity1));
      this.saleForm.controls.dona1.setValue(Number(Number(this.saleForm.value.quantity1) / Number(this.dona)).toFixed(2));

    } else {
      this.saleForm.controls.id1.setValue(details.id);
      this.saleForm.controls.name1.setValue(details.product_name);
      this.saleForm.controls.barcode1.setValue(details.barcode);
      this.saleForm.controls.image1.setValue(details.image_url);
      this.saleForm.controls.currency1.setValue(this.saleForm.value.currency1);
      this.saleForm.controls.unit1.setValue(details.product_unit);

      if (Number(this.saleForm.value.currency1) === 1) {
        this.saleForm.controls.cost1.setValue(Number(details.sell_cost_uz));
      } else if (Number(this.saleForm.value.currency1) === 2) {
        this.saleForm.controls.cost1.setValue(Number(details.sell_cost_us));
      } else {
        this.saleForm.controls.cost1.setValue(Number(details.sell_cost_uz));
      }
      this.saleForm.controls.quantity1.setValue(1);
      this.saleForm.controls.dona1.setValue(Number(Number(this.saleForm.value.quantity1) / Number(details.layout_quantity)).toFixed(2));
    }
  }
  setProductPick12(details: any) {
    this.saleForm.controls.id1.setValue(details.product_id);
    this.saleForm.controls.name1.setValue(details.name);
    this.saleForm.controls.barcode1.setValue(details.barcode);
    this.saleForm.controls.image1.setValue(details.image);
    this.saleForm.controls.currency1.setValue(details.currency);
    this.saleForm.controls.unit1.setValue(details.product_unit);
    this.saleForm.controls.cost1.setValue(Number(details.cost));
    this.saleForm.controls.quantity1.setValue(Number(details.quantity));
    this.saleForm.controls.dona1.setValue(Number(Number(details.quantity) / Number(details.layout_quantity)).toFixed(2));
    this.addAddress12(1);
  }
  setProductPick(details: any) {
    if (details.quantity > 0) {
      this.onProductPick2(details);
    } else {
      if (confirm('Maxsulot omborda qolmagan!\nMiqdori:' + details.quantity +
        '\nSavdoni amalga oshirsangiz minusga ishlaydi\nSavdoni amalga oshirasizmi ?')) {
        this.onProductPick2(details);
      } else {

      }
    }
  }
  onProductPick2(details: any) {
    this.dona = Number(details.layout_quantity);
    if (this.saleForm.value.id1 === details.id) {
      this.saleForm.controls.id1.setValue(details.id);
      this.saleForm.controls.name1.setValue(details.product_name);
      this.saleForm.controls.barcode1.setValue(details.barcode);
      this.saleForm.controls.image1.setValue(details.image_url);
      // this.saleForm.controls.cost1.setValue(details.sell_cost_uz);
      this.saleForm.controls.currency1.setValue(this.saleForm.value.currency1);
      this.saleForm.controls.unit1.setValue(details.product_unit);
      if (Number(this.saleForm.value.currency1) === 1) {
        this.saleForm.controls.cost1.setValue(Number(details.sell_cost_uz));
      } else if (Number(this.saleForm.value.currency1) === 2) {
        this.saleForm.controls.cost1.setValue(Number(details.sell_cost_us));
      } else {
        this.saleForm.controls.cost1.setValue(Number(details.sell_cost_uz));
      }
      this.saleForm.controls.quantity1.setValue(Number(1) + Number(this.saleForm.value.quantity1));
      this.saleForm.controls.dona1.setValue(Number((Number(1) +
        Number(this.saleForm.value.quantity1)) / Number(details.layout_quantity)).toFixed(2));
      this.addAddress(1);
    } else {
      this.saleForm.controls.id1.setValue(details.id);
      this.saleForm.controls.name1.setValue(details.product_name);
      this.saleForm.controls.barcode1.setValue(details.barcode);
      this.saleForm.controls.image1.setValue(details.image_url);
      // this.saleForm.controls.cost1.setValue(details.sell_cost_uz);
      this.saleForm.controls.currency1.setValue(this.saleForm.value.currency1);
      this.saleForm.controls.unit1.setValue(details.product_unit);
      if (Number(this.saleForm.value.currency1) === 1) {
        this.saleForm.controls.cost1.setValue(Number(details.sell_cost_uz));
      } else if (Number(this.saleForm.value.currency1) === 2) {
        this.saleForm.controls.cost1.setValue(Number(details.sell_cost_us));
      } else {
        this.saleForm.controls.cost1.setValue(Number(details.sell_cost_uz));
      }
      this.saleForm.controls.quantity1.setValue(1);
      this.saleForm.controls.dona1.setValue(Number(Number(this.saleForm.value.quantity1) / Number(details.layout_quantity)).toFixed(2));
      this.addAddress(1);
    }
  }
  createAddress(): FormGroup {
    const product = this.saleForm.value;
    return this.formBuilder.group({
      product_id: [product.id1, Validators.required],
      name: [product.name1, Validators.required],
      barcode: [product.barcode1, Validators.required],
      currency: [product.currency1, Validators.required],
      quantity: [product.quantity1, Validators.required],
      dona: [Number(product.dona1), Validators.required],
      unit: [product.unit1, Validators.required],
      cost: [product.cost1, Validators.required],
      sum: [0, Validators.required],
      image: [product.image1]
    });
  }
  get addressControls() {
    return this.saleForm.get('products')['controls'];
  }
  addAddress(id: number): void {
    if (Number(this.saleForm.value.dona1) > 0 && Number(this.saleForm.value.quantity1) > 0) {
      if (this.saleForm.valid) {
        if (this.isProductExist() !== false) {
          const quantityItem: number = this.saleForm.get('products')['controls'][this.isProductExist()].controls.quantity.value;
          const quantityValue: number = this.saleForm.value.quantity1;
          if (id === 1) {
            this.saleForm.get('products')['controls'][this.isProductExist()].controls.quantity
              .setValue(Number(quantityItem) + Number(quantityValue));
            // dona
            const dona1: number = (quantityValue + quantityItem) / this.dona;
            this.saleForm.get('products')['controls'][this.isProductExist()]
              .controls.dona.setValue(Number(dona1));
          } else {
            this.saleForm.get('products')['controls'][this.isProductExist()].controls.quantity
              .setValue(Number(quantityValue));
            // dona
            const dona2: number = this.saleForm.value.dona1;
            this.saleForm.get('products')['controls'][this.isProductExist()]
              .controls.dona.setValue(Number(dona2));
          }
          this.saleForm.get('products')['controls'][this.isProductExist()].controls.cost
            .setValue(this.saleForm.value.cost1);
          this.saleForm.get('products')['controls'][this.isProductExist()].controls.currency
            .setValue(this.saleForm.value.currency1);

          this.getSum(this.isProductExist());
          this.barcodeRef.nativeElement.focus();
        } else {
          this.products = this.saleForm.get('products') as FormArray;
          this.products.push(this.createAddress());
          this.getSum(this.isProductExist());
          this.barcodeRef.nativeElement.focus();
        }
        this.pack = 0;
        this.saleForm.get('dona1').setValue(0);
      } else {
        this.toast.error('Hamma ma\'lumotlarni kiriting!', 'Xatolik');
      }
    } else {
      this.toast.info('Nol qiymat bilan saqlay olmaysiz!');
    }
  }
  addAddress12(id: number): void {
    if (this.saleForm.valid) {
      if (this.isProductExist() !== false) {
        const quantityItem: number = this.saleForm.get('products')['controls'][this.isProductExist()].controls.quantity.value;
        const quantityValue: number = this.saleForm.value.quantity1;
        if (id === 1) {
          this.saleForm.get('products')['controls'][this.isProductExist()].controls.quantity
            .setValue(Number(quantityItem) + Number(quantityValue));
          // dona
          const dona1: number = (quantityValue + quantityItem) / this.dona;
          this.saleForm.get('products')['controls'][this.isProductExist()]
            .controls.dona.setValue(Number(dona1));
        } else {
          this.saleForm.get('products')['controls'][this.isProductExist()].controls.quantity
            .setValue(Number(quantityValue));
          const dona2: number = quantityValue / this.dona;
          this.saleForm.get('products')['controls'][this.isProductExist()]
            .controls.dona.setValue(Number(dona2));
        }
        this.saleForm.get('products')['controls'][this.isProductExist()].controls.cost
          .setValue(this.saleForm.value.cost1);
        this.saleForm.get('products')['controls'][this.isProductExist()].controls.currency
          .setValue(this.saleForm.value.currency1);

        // this.getSum(this.isProductExist());
        this.barcodeRef.nativeElement.focus();
      } else {
        this.products = this.saleForm.get('products') as FormArray;
        this.products.push(this.createAddress());
        this.getSum(this.isProductExist());
        // this.barcodeRef.nativeElement.focus();
      }
    } else {
      this.toast.error('Hamma ma\'lumotlarni kiriting!', 'Xatolik');
    }
  }
  isProductExist() {
    const productArray = this.saleForm.get('products')['controls'];
    let i = null;
    for (i = 0; productArray.length > i; i += 1) {
      if (productArray[i].controls.product_id.value === this.saleForm.value.id1) {
        return i;
      }
    }
    return false;
  }
  getSum(id: number) {
    let rowQuantity = 0;
    let rowCost = 0;
    // Calculating sum
    rowQuantity = this.saleForm.get('products')['controls'][id].controls.quantity.value;
    rowCost = this.saleForm.get('products')['controls'][id].controls.cost.value;
    this.saleForm.get('products')['controls'][id].controls.sum.setValue(rowCost * rowQuantity);
    this.totalQuantity = this.saleForm.value.products.reduce((a, {quantity}) => Number(a) + Number(quantity), 0);
    // Calculating total sum, total dollar , total hr
    this.totalSum = this.saleForm.value.products.filter(object => {
      return object['currency'] === 1;
    });
    this.saleForm.controls.totalSum.setValue(this.totalSum.reduce((a, {sum}) => Number(a) + Number(sum), 0));
    //
    this.totalDollar = this.saleForm.value.products.filter(object => {
      return object['currency'] === 2;
    });
    this.saleForm.controls.totalDollar.setValue(this.totalDollar.reduce((a, {sum}) => Number(a) + Number(sum), 0));
    //
    this.totalHr = this.saleForm.value.products.filter(object => {
      return object['currency'] === 3;
    });
    this.saleForm.controls.totalHr.setValue(this.totalHr.reduce((a, {sum}) => Number(a) + Number(sum), 0));
    // Clear the form after product is added to basket
    this.saleForm.controls.id1.reset();
    this.saleForm.controls.cost1.setValue(0);
    this.saleForm.controls.quantity1.setValue(0);
    this.saleControl.reset();
  }
  // Delete clicked line in array form
  removeAddress(i: number) {
    this.products.removeAt(i);
    // Calculating total sum, total dollar , total hr
    this.totalQuantity = this.saleForm.value.products.reduce((a, {quantity}) => Number(a) + Number(quantity), 0);
    this.totalSum = this.saleForm.value.products.filter(object => {
      return object['currency'] === 1;
    });
    this.saleForm.controls.totalSum.setValue(this.totalSum.reduce((a, {sum}) => Number(a) + Number(sum), 0));
    //
    this.totalDollar = this.saleForm.value.products.filter(object => {
      return object['currency'] === 2;
    });
    this.saleForm.controls.totalDollar.setValue(this.totalDollar.reduce((a, {sum}) => Number(a) + Number(sum), 0));
    //
    this.totalHr = this.saleForm.value.products.filter(object => {
      return object['currency'] === 3;
    });
    this.saleForm.controls.totalHr.setValue(this.totalHr.reduce((a, {sum}) => Number(a) + Number(sum), 0));
  }
  // Filtering product grid when #filtername is pressed
  onFilterProduct(name: string) {
    if (name === 'main.all') {
      this.isLoading = true;
      this.posService.getPosProducts()
        .subscribe((result: Product[]) => {
          this.product = result;
          this.isLoading = false;
        });
    } else {
      this.isLoading = true;
      this.posService.getPosProducts()
        .subscribe((result: Product[]) => {
          this.product = result;
          this.isLoading = false;
          return this.product = this.product.filter(object => {
            return object['type'] === name;
          });
        });
      this.isLoading = false;
    }
  }
  onCustomerClick(sale: any) {
    // this.isLoading = true;
    // this.posService.getPosProducts()
    //   .subscribe((result: Product[]) => {
    //     this.product = result;
        // this.isLoading = false;
        //
        this.productList.forEach(x => {
          const id = sale.products.length;
          for (let i = 0; i < id; i++) {
            if (x.id === sale.products[i].product_id && sale.products[i].currency !== 2) {
              this.originalCostSum = Number(this.originalCostSum) + Number(x.sell_cost_uz) * Number(sale.products[i].quantity);
            }
          }
        });

        const sale1 = {
          sale,
          originalCostSum: this.originalCostSum
        };
        const dialogRef = this.dialog.open(CustomerAddComponent, {
          panelClass: ['animate__animated', 'animate__slideInRight'],
          width: '50%',
          height: '100%',
          position: {right: '0'},
          data: sale1
        });

        dialogRef.afterClosed().subscribe(result1 => {
          // clearing originalSum
          this.originalSumId.length = 0;
          this.originalCostSum = 0;
          if (result1.a === 1) {
            this.router.navigate(['../pos/receiptCheck'], {state: {alarm: result1.id}});
            this.clearForm();
          } else {

          }
        });
        //
      // });
  }
  clearForm() {
    this.saleForm.get('products')['controls'].length = 0;
    this.saleForm.controls.totalSum.setValue(0);
    this.saleForm.controls.totalDollar.setValue(0);
    this.saleForm.controls.totalHr.setValue(0);
    this.totalQuantity = 0;
  }
  submitForm() {
    this.saleForm.controls.id1.setValue(0);
    this.saleForm.controls.cost1.setValue(0);
    this.saleForm.controls.quantity1.setValue(0);
    if (this.saleForm.valid && this.saleForm.get('products')['controls'].length > 0) {
      this.onCustomerClick(this.saleForm.value);
    } else {
      this.toast.info('Sotish uchun maxsulot tanlang');
    }
  }
  onClickBasket(i: number) {
    BasketArr1.forEach(arr => {
      this.saleForm.get(arr.name).setValue(this.saleForm.get('products')['controls'][i].get(arr.value).value);
    });
    this.dona = Number(this.saleForm.get('products')['controls'][i].controls.quantity.value) /
      Number(this.saleForm.get('products')['controls'][i].controls.dona.value);
  }
  onBarcodeSubmit() {
    if (this.barcodeForm.value.barcode.length < 1) {
      this.vc.nativeElement.focus();
    } else {
      this.product.forEach(x => {
        if (x.barcode === this.barcodeForm.value.barcode) {
          if (x.quantity > 0) {
            this.setProductPick(x);
            this.barcodeForm.controls.barcode.setValue('');
          } else {
            if (confirm('Maxsulot omborda qolmagan!\nSavdoni amalga oshirsangiz minusga ishlaydi\nSavdoni amalga oshirasizmi ?')) {
              this.setProductPick(x);
              this.barcodeForm.controls.barcode.setValue('');
            }
          }

        }
      });
      this.barcodeForm.controls.barcode.setValue('');
    }
  }
  openSale() {
    this.submitForm();
  }
  selectCurrency() {
    this.saleForm.controls.currency1.setValue(1);
  }
  onBarcodeFocus() {
    this.barcodeForm.controls.barcode.setValue('');
  }
  insSubmit() {
    this.saleForm.controls.id1.setValue(0);
    this.saleForm.controls.cost1.setValue(0);
    this.saleForm.controls.quantity1.setValue(0);
    this.saleForm.controls.dona1.setValue(0);
    this.saleForm.controls.unit1.setValue(0);
    if (this.saleForm.valid && this.saleForm.get('products')['controls'].length > 0) {
      const dialogRef = this.dialog.open(InsWindowComponent, {
        panelClass: ['animate__animated', 'animate__slideInRight', 'my-full-screen-dialog'],
        width: '100%',
        height: '98%',
        data: this.saleForm.value
      });
      dialogRef.afterClosed().subscribe(a => {
        // clearing originalSum
        this.originalSumId.length = 0;
        this.originalCostSum = 0;
        // this.clearForm();
      });
    } else {
      this.toast.info('Sotish uchun maxsulot tanlang');
    }
  }
  getQuantityValue(firstController: string, secondController: any, dir: boolean, min: number, max: number): void {
    const value: number = this.saleForm.get(firstController).value;
    if (value > max) {
      this.saleForm.get(firstController).setValue(max);
    } else if (value < min) {
      this.saleForm.get(firstController).setValue(min);
    } else if (value === null) {
      this.saleForm.get(firstController).setValue(min);
    } else {
      this.saleForm.get(firstController).setValue(value);
    }
    if (dir) {
      this.saleForm.get(secondController).setValue((value / Number(this.dona)).toFixed(2));
    } else {
      this.saleForm.get(secondController).setValue((value * Number(this.dona)).toFixed(2));
    }
  }

  getBranchInfo(): void {
    this.cashierService.getBranchInfo().pipe(take(1)).subscribe(r => {
      this.branchInfo = r[0].is_sale_open;
    });
  }
}
