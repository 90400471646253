import {NgModule} from '@angular/core';
import {SertificateAddComponent} from './sertificate-add/sertificate-add.component';
import {SarbonRoutingModule} from './sarbon-routing.module';

@NgModule({
  declarations: [
    SertificateAddComponent
  ],
  imports: [
    SarbonRoutingModule
  ],
  exports: []
})
export class SarbonModule {

}
