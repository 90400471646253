import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {dateParser} from '../crm/dateParser';

const BACKEND_URL = environment.api_URL;

@Injectable({providedIn: 'root'})
export class ProdService {
  private prodSub = new Subject<any>();
  cast$ = this.prodSub.asObservable();
  constructor(private router: Router, private http: HttpClient) {
  }

  getProductLayout(id: number): any {
    const pl: any = {
      id
    };
    return this.http.post<{message: string}>(BACKEND_URL + 'crm/production/get', pl);
  }

  getLine() {
    return this.http.get(BACKEND_URL + 'crm/production/line/get');
  }

  getProdHistory(d1: Date, d2: Date, obj: any, isFirst: boolean, ls1: string, ls2: string, path: string, line: number) {
    let app;
    if (isFirst) {
      app = obj;
    } else {
      const date1 = dateParser(d1);
      const date2 = dateParser(d2);
      localStorage.setItem(ls1, date1);
      localStorage.setItem(ls2, date2);
      app = {
        line,
        d1: localStorage.getItem(ls1),
        d2: localStorage.getItem(ls2),
      };
    }
    return this.http.post(BACKEND_URL + path, app);
  }

  postProductLayout(line: number, crOn: Date, id: number, quantity: number) {
    const ppl: any = {
      line,
      crOn: dateParser(crOn),
      id,
      quantity
    };
    return  this.http.post(BACKEND_URL + 'crm/production/post', ppl);
  }

  postLayout(id: string, idLayout: string, quantity: number, line: number) {
    const lauout: any = {
      id,
      idLayout,
      quantity,
      line
    };
    return  this.http.post(BACKEND_URL + 'crm/production/constructor/post', lauout);
  }
  getHotLinks(): any {
    return this.http.get<any>(BACKEND_URL + 'crm/production/hotlink');
  }

  getProductLayoutProducts(line: number): any {
    const app = {
      line
    };
    return this.http.post<any>(BACKEND_URL + 'crm/production/product/layout', app);
  }

  getProductLayoutProductsByType(type: number): any {
    const app = {
      type
    };
    return this.http.post<any>(BACKEND_URL + 'crm/production/product/layout/type', app);
  }

  postManualProduction(line: number, id: number, barcode: number, quantity: number, raws: any, date: Date) {
    const app = {
      line,
      id,
      barcode,
      quantity,
      raws,
      date: dateParser(date)
    };
    // this.prodSub.next('1');
    return this.http.post<{message: string}>(BACKEND_URL + 'crm/production/manual/post', app);
  }

  deleteLayoutItem(id: number) {
    const app = {
      id
    };
    return this.http.post<{message: string}>(BACKEND_URL + 'crm/production/layout/delete', app);
  }
  // get production report
  getProductionReport(obj: any): any {
    const from = dateParser(obj.from);
    const to = dateParser(obj.to);
    const app = {
      from,
      to
    };
    return this.http.post<any>(BACKEND_URL + 'crm/production/report/get', app);
  }

  getProductionManualReport(obj: any): any {
    const from = dateParser(obj.from);
    const to = dateParser(obj.to);
    const app = {
      line: obj.line,
      from,
      to
    };
    return this.http.post<any>(BACKEND_URL + 'crm/production/manual/report/get', app);
  }

  deleteProduction(id: number): any {
    const app = {
      id
    };
    return this.http.post<any>(BACKEND_URL + 'crm/production/delete', app);
  }

  deleteManualProduction(id: number): any {
    const data = {
      id
    };
    return this.http.post<any>(BACKEND_URL + 'crm/production/manual/delete', data);
  }

  getInventoryByType(id: number): any {
    const data = {
      type: id
    };
    return this.http.post(BACKEND_URL + 'crm/inventory/product/type/get', data);
  }
}
