import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../../../system/client-create/user.service';

@Component({
  templateUrl: './inventories.component.html',
  styleUrls: ['./inventories.component.css']
})
export class InventoriesComponent implements OnInit {

  branches = [];
  constructor(
    private router: Router,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    this.userService.getBranchesByOwner()
      .subscribe(result => {
        this.branches = result;
        console.log(result);
      });
  }

  onInventory(id: any) {
      this.router.navigate(['/inventory/product'], {state: {id}});
  }
}
