import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {Observable} from 'rxjs';
import {InventoryService} from '../../../crm/inventory/inventory.service';
import {ProdService} from '../../prod.service';
import {Router} from '@angular/router';
import {map, startWith} from 'rxjs/operators';

interface Product {
  id: number;
  barcode: string;
  product_name: string;
}

interface Layout {
  l_id: number;
  barcode: string;
  name: string;
  quantity: number;
  line: number;
}

interface Line {
  id: number;
  name: string;
}

@Component({
  templateUrl: './constructor.component.html',
  styleUrls: ['./constructor.component.css']
})
export class ConstructorComponent implements OnInit {
  layoutForm: FormGroup;
  rowData: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  public modules: Module[] = AllModules;
  myControl = new FormControl();
  filteredOptions: Observable<Product[]>;
  options: Product[] = [];
  layouts: Layout[] = [];
  lines: Line[] = [];

  constructor(private inventoryService: InventoryService, private prodService: ProdService, private router: Router) {
  }

  columnDefs1 = [
    {headerName: 'Id', field: 'id', sortable: true},
    {headerName: 'Barcode', field: 'barcode', sortable: true, filter: 'agTextColumnFilter'},
    {headerName: 'Product', field: 'product_name', sortable: true, filter: 'agTextColumnFilter'}
  ];

  ngOnInit(): void {
    this.layoutForm = new FormGroup({
      line: new FormControl(null, Validators.required),
      barcodeLayout: new FormControl(null, Validators.required),
      nameLayout: new FormControl(null, Validators.required),
      idLayout: new FormControl(null, Validators.required),
      id: new FormControl(null, Validators.required),
      quantity: new FormControl(null, Validators.required)
    });
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    );
    this.inventoryService.getInventory()
      .subscribe((result: Product[]) => {
        this.options = result;
      });
    this.prodService.getLine()
      .subscribe((result: Line[]) => {
        this.lines = result;
      });
    this.rowData = this.inventoryService.getInventory();
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    param.api.sizeColumnsToFit();
  }

  filter(val: any): Product[] {
    return this.options.filter((item: any) => {
      // If the user selects an option, the value becomes a Human object,
      // therefore we need to reset the val for the filter because an
      // object cannot be used in this toLowerCase filter
      if (typeof val === 'object') {
        val = '';
      } else {
        const TempString = item.product_name + item.barcode;
        return TempString.toLowerCase().includes(val.toLowerCase());
      }
    });
  }

  onRowDoubleClick() {
    const selectedRow = this.gridApi.getSelectedRows()[0];
    this.layoutForm.controls.nameLayout.setValue(selectedRow.product_name);
    this.layoutForm.controls.id.setValue(selectedRow.id);
    this.prodService.getProductLayout(selectedRow.id)
      .subscribe((result: Layout[]) => {
        this.layouts = result;
        if (this.layouts.length > 0) {
          this.layoutForm.controls.line.patchValue(this.layouts[0].line);
        }
      });
  }

  displayFn(item: any): any {
    // tslint:disable-next-line:triple-equals
    if (item == undefined) {
      return;
    }
    return item.product_name;
  }

  // Setting barcode and id
  setBarcode(id: any) {
    this.layoutForm.controls.barcodeLayout.setValue(id.barcode);
    this.layoutForm.controls.idLayout.setValue(id.id);
  }

  onSubmit() {
    this.prodService.postLayout(
      this.layoutForm.value.id,
      this.layoutForm.value.idLayout,
      this.layoutForm.value.quantity,
      this.layoutForm.value.line,
    ).subscribe(result => {
      this.prodService.getProductLayout(this.layoutForm.value.id)
        .subscribe((result1: Layout[]) => {
          this.layouts = result1;
          this.layoutForm.controls.idLayout.reset();
          this.layoutForm.controls.barcodeLayout.reset();
          this.myControl.setValue({name: ''});
          this.layoutForm.controls.quantity.reset();
        });
    });
  }


  onRemove(id: number) {
    this.prodService.deleteLayoutItem(
      id
    ).subscribe(result => {
      if (result.message === '1') {
        this.prodService.getProductLayout(this.layoutForm.value.id)
          .subscribe((result1: Layout[]) => {
            this.layouts = result1;
          });
      }
    });
  }
}
