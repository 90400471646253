import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {InstallmentListComponent} from './installment-apps/installment-list/installment-list.component';
import {AuthGuard} from '../auth/auth.guard';
import {InsCustomersComponent} from './installment-apps/customers/ins-customers.component';
import {InsCustomerAddComponent} from './installment-apps/customers/ins-cutomer-add/ins-customer-add.component';
import {InsWindowComponent} from './installment-apps/installment-sale/installment-window/ins-window.component';
import {InsSaleCheckComponent} from './installment-apps/installment-sale/check/check-sale/ins-sale-check.component';
import {InsSaleListComponent} from './installment-apps/sale-list/ins-sale-list/ins-sale-list.component';
import {InsOperListComponent} from './installment-apps/ins-operations/ins-operation-list/ins-oper-list.component';
import {InsOperAddComponent} from './installment-apps/ins-operations/ins-oper-add/ins-oper-add.component';

const routes: Routes = [
  {path: 'installment', component: InstallmentListComponent, canActivate: [AuthGuard],
    children: [
      {path: 'customers/list', component: InsCustomersComponent, canActivate: [AuthGuard]},
      {path: 'customer/add', component: InsCustomerAddComponent, canActivate: [AuthGuard]},
      {path: 'window', component: InsWindowComponent, canActivate: [AuthGuard]},
      {path: 'check', component: InsSaleCheckComponent, canActivate: [AuthGuard]},
      {path: 'sale-list', component: InsSaleListComponent, canActivate: [AuthGuard]},
      {path: 'oper-list', component: InsOperListComponent, canActivate: [AuthGuard]},
      {path: 'oper/add', component: InsOperAddComponent, canActivate: [AuthGuard]}
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [],
})
export class InstallmentRoutingModule {}
