import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {map, startWith, take} from 'rxjs/operators';
import {InventoryService} from '../../inventory/inventory.service';
import {CashierService} from '../cashier.service';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {MatDialog} from '@angular/material/dialog';
import {CashierSettingsComponent} from '../cashier-settings/cashier-settings.component';
import {GridApi} from 'ag-grid-community';
import {
  CurrencyCellRendererDollar,
  CurrencyCellRendererQuantity,
  CurrencyCellRendererSum
} from '../../../test/table-currency';
import {ToastrService} from 'ngx-toastr';
import {ClientReportComponent} from './client-report/client-report.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {dateParser} from '../../dateParser';
import {CurrencyExchangeComponent} from '../../../hospital/components/currency-exchange/currency-exchange.component';
import {AuthService} from "../../../auth/auth.service";

interface Customer {
  id: number;
  name: string;
}

interface Balance {
  owner: number;
  branch: number;
  company: number;
  type: number;
  cost: number;
}

interface DayBalance {
  id: number;
  owner_id: number;
  branch_id: number;
  company_id: number;
  type_id: number;
  account_balance: number;
}


@Component({
  templateUrl: './cashier-day.component.html',
  styleUrls: ['./cashier-day.component.css']
})
export class CashierDayComponent implements OnInit, OnDestroy {
  dayForm: FormGroup;
  smsForm: FormGroup;
  dayControl = new FormControl();
  filterCustomers: Observable<Customer[]>;
  customers: Customer[] = [];
  currency$: Observable<Customer[]>;
  exchange: Customer[];
  summExchange = 0;
  sum = 0;
  dollar = 0;
  hr = 0;
  cashBeck = 0;
  daySum = 0;
  dayHr = 0;
  dayDollar = 0;
  daySum1 = 0;
  dayHr1 = 0;
  dayDollar1 = 0;
  rowData: any;
  rowData1: any;
  rowData2: any;
  rowData3: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  isLoading = false;
  info: any;
  public modules: Module[] = AllModules;
  private inOutSubs: Subscription;
  viewAble: boolean;
  branchInfo;

  filterForm12: FormGroup;
  filterFormBalance: FormGroup;
  exchangeFilterForm: FormGroup;
  date1 = '';
  date2 = '';
  rowDataBalance;
  operationType: Customer[] = [
    {id: 1, name: 'Kirim'},
    {id: 2, name: 'Chiqim'}
  ];

  constructor(
    private inventoryService: InventoryService,
    private fb: FormBuilder,
    private cashierService: CashierService,
    public dialog: MatDialog,
    private toast: ToastrService,
    private modalService: NgbModal,
    private authService: AuthService
  ) {
    this.defaultColDef = {resizable: true};
  }

  columnDefs1 = [ // Transaction table
    {headerName: 'Sana', field: 'date', sortable: true, filter: 'agTextColumnFilter', width: 170},
    {headerName: 'Kompaniya', field: 'company', sortable: true, filter: 'agTextColumnFilter'},
    {headerName: 'Turi', field: 'oper_type', sortable: true, filter: 'agTextColumnFilter', width: 120},
    {headerName: 'Valyuta', field: 'currency', sortable: true, filter: 'agTextColumnFilter', width: 100},
    {headerName: 'Cost', field: 'amount', sortable: true, filter: 'agNumberColumnFilter',
      width: 150, valueFormatter: CurrencyCellRendererQuantity},
    {headerName: 'Som', field: 'balance_sum', sortable: true, filter: 'agNumberColumnFilter',
      width: 150, valueFormatter: this.currencyFormatter},
    {headerName: 'Dollar', field: 'balance_dollar', sortable: true, filter: 'agNumberColumnFilter',
      width: 150, valueFormatter: CurrencyCellRendererDollar},
    {headerName: 'Hr', field: 'balance_hr', sortable: true, filter: 'agNumberColumnFilter',
      width: 150, valueFormatter: this.currencyFormatter},
    {headerName: 'Maxsulot', field: 'products', sortable: true, filter: 'agTextColumnFilter', width: 200},
    {headerName: 'User', field: 'user1', sortable: true, filter: 'agTextColumnFilter', width: 100},
    {headerName: 'Izoh', field: 'comment', sortable: true, filter: 'agTextColumnFilter', width: 200}
  ];
  columnDefs2 = [ // Balance table
    {headerName: 'Company', field: 'name', sortable: true, filter: 'agTextColumnFilter', resizable: true,
      width: 400, checkboxSelection: true, multiSelection: true},
    {headerName: 'Sum', field: 'sum', sortable: true, width: 150, resizable: true, valueFormatter: CurrencyCellRendererSum
      , cellStyle: this.cellStyling},
    {headerName: 'Hr', field: 'hr', sortable: true, width: 150, resizable: true, valueFormatter: CurrencyCellRendererSum
      , cellStyle: this.cellStylingHr},
    {
      headerName: 'Dollar', field: 'dollar', sortable: true, width: 150,
      resizable: true, cellRenderer: CurrencyCellRendererDollar, cellStyle: this.cellStylinghrDollar
    },
    {
      headerName: 'HR dollar', field: 'dollar_hr', sortable: true, width: 150,
      resizable: true, cellRenderer: CurrencyCellRendererDollar, cellStyle: this.cellStylinghrDollarHr
    },
    {
      headerName: 'Keshbek so\'mda', field: 'cash_back', sortable: true, width: 150,
      resizable: true, cellRenderer: CurrencyCellRendererSum, cellStyle: this.cellStylinghrDollarHr
    }
  ];
  columnDefs3 = [ // Exchange history table
    {headerName: 'Date', field: 'date', sortable: true, filter: 'agTextColumnFilter', width: 100, resizable: true},
    {headerName: 'Company', field: 'name', sortable: true, filter: 'agTextColumnFilter', resizable: true, width: 200},
    {headerName: 'Type', field: 'type', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Cost', field: 'cost', sortable: true, width: 150, cellRenderer: CurrencyCellRendererQuantity},
    {headerName: 'Rate', field: 'rate', sortable: true, width: 150, valueFormatter: CurrencyCellRendererQuantity},
    {headerName: 'Natija', field: 'result', sortable: true, width: 150, cellRenderer: CurrencyCellRendererQuantity},
  ];
  currencyFormatter(params) {
    // return '' + this.formatNumber(params.value);
    return Math.floor(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
  // End of table header names
  ngOnInit(): void {
    this.viewAble = localStorage.getItem('card') === 'true';
    const date1 = new Date();
    const to = dateParser(date1);
    const mm = String(date1.getMonth() + 1).padStart(2, '0');
    const yyyy = date1.getFullYear();
    const from = yyyy + '-' + mm + '-' + '01';
    this.isLoading = true;

    this.initFilterForm();
    this.initFilterBalanceForm();
    this.initExchangeForm(from, to);
    this.dayForm = this.fb.group({
      customer: ['', Validators.required],
      operationType: ['', Validators.required],
      currency: ['', Validators.required],
      cost: ['', [Validators.required, Validators.maxLength(15)]],
      comment: ['', [Validators.required, Validators.maxLength(100)]],
    });
    this.smsForm = this.fb.group({
      text: ['', [Validators.required, Validators.maxLength(150)]]
    });
    this.filterCustomers = this.dayControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    );
    this.currency$ = this.inventoryService.getCurrency();
    this.cashierService.getDayBalance()
      .subscribe((result: DayBalance[]) => {
        this.isLoading = false;
        this.daySum = result[0].account_balance;
        this.dayHr = result[2].account_balance;
        this.dayDollar = result[1].account_balance;
        this.daySum1 = result[3].account_balance;
        this.dayHr1 = result[5].account_balance;
        this.dayDollar1 = result[4].account_balance;
      });
    this.inventoryService.getCompany()
      .subscribe((result: Customer[]) => {
        this.customers = result;
      });
    // Getting data to all tables in this module
    this.rowData2 = this.cashierService.getBalance();
    this.rowData3 = this.cashierService.getExchangeHistory(this.exchangeFilterForm.value.d1, this.exchangeFilterForm.value.d2);
    this.inOutSubs = this.cashierService.cast$
      .subscribe(result => {
        if (result.message === '1') {
          this.ngOnInit();
        }
      });

    this.getBranchInfo();
  }
  // Filtering product grid when #filtername is pressed
  filter(val: any): Customer[] {
    return this.customers.filter((item: any) => {
      // If the user selects an option, the value becomes a Human object,
      // therefore we need to reset the val for the filter because an
      // object cannot be used in this toLowerCase filter
      if (typeof val === 'object') {
        val = '';
      } else {
        const TempString = item.name;
        return TempString.toLowerCase().includes(val.toLowerCase());
      }
    });
  }
  initFilterForm(): void {
    this.filterForm12 = this.fb.group({
      customer: ['', Validators.required],
      s_date: [Validators.required],
      e_date: [Validators.required]
    });
  }
  initFilterBalanceForm(): void {
    this.filterFormBalance = this.fb.group({
      s_date_balance: [Validators.required],
      e_date_balance: [Validators.required]
    });
  }
  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    // param.api.sizeColumnsToFit();
  }
  initExchangeForm(from: any, to: any): void {
    this.exchangeFilterForm = this.fb.group({
      d1: [from],
      d2: [to]
    });
  }
  displayFn(item: any): any {
    // tslint:disable-next-line:triple-equals
    if (item == undefined) {
      return;
    }
    return item.name;
  }
  setProduct(value: any) {
    this.dayForm.controls.customer.setValue(value.id);
    this.inventoryService.getBalance(value.id)
      .subscribe((result: Balance[]) => {
        this.sum = result[0].cost;
        this.hr = result[2].cost;
        this.dollar = result[1].cost;
        if (result.length > 4) {
          this.cashBeck = result[4].cost;
        }
      });
  }
  onSubmit() {
    if (this.dayForm.valid) {
      this.cashierService.postCashierOperation(
        this.dayForm.value.customer,
        this.dayForm.value.operationType,
        this.dayForm.value.currency,
        this.dayForm.value.cost,
        this.dayForm.value.comment
      );
    } else {
      alert('Hamma malumotlarni kiriting!');
    }
  }
  onCloseDay() {
    if (confirm('Rostdan ham kun yopmoqchimisiz?')) {
      this.cashierService.postDayClose(
        this.daySum,
        this.dayDollar,
        this.dayHr,
        this.daySum1,
        this.dayDollar1,
        this.dayHr1,
      );
      // this.ngOnInit();
    }
  }
  ngOnDestroy(): void {
    this.inOutSubs.unsubscribe();
  }
  serCurrency() {
    const dialogRef = this.dialog.open(CashierSettingsComponent, {
      panelClass: ['animate__animated', 'animate__slideInRight'],
      width: '30%',
      height: '100%',
      position: {right: '0'}
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.ngOnInit();
    });
  }
  cellStyling(params: any) {
    if (params.data.sum < 0) {
      return {backgroundColor: '#f08a90', color: 'white'};
    } else if (params.data.sum > 0) {
      return {backgroundColor: '#28a745', color: 'white'};
    }
  }
  cellStylingHr(params: any) {
    if (params.data.hr < 0) {
      return {backgroundColor: '#f08a90', color: 'white'};
    } else if (params.data.hr > 0) {
      return {backgroundColor: '#28a745', color: 'white'};
    }
  }
  cellStylinghrDollar(params: any) {
    if (params.data.dollar < 0) {
      return {backgroundColor: '#f08a90', color: 'white'};
    } else if (params.data.dollar > 0) {
      return {backgroundColor: '#28a745', color: 'white'};
    }
  }
  cellStylinghrDollarHr(params: any) {
    if (params.data.dollar_hr < 0) {
      return {backgroundColor: '#f08a90', color: 'white'};
    } else if (params.data.dollar_hr > 0) {
      return {backgroundColor: '#28a745', color: 'white'};
    }
  }
  onBtnExportExchange(api: GridApi) {
    api.exportDataAsExcel();
  }
  onSubmitFilter(): void {
    if (this.filterForm12.valid) {
      this.isLoading = true;
      this.cashierService.getTransactions(this.filterForm12.value.s_date, this.filterForm12.value.e_date, this.filterForm12.value.customer)
        .subscribe(x => {
          this.rowData1  = x;
          this.isLoading = false;
        });
    } else {
        this.toast.error('Oraliq muddatni tanlang');
    }
  }
  onSubmitFilterBalance() {
    const selectedRow = this.gridApi.getSelectedRows()[0].id;
    if (this.filterFormBalance.valid && selectedRow > 0) {
      this.isLoading = true;
      this.cashierService.getTransactionReport(this.filterFormBalance.value.s_date_balance,
        this.filterFormBalance.value.e_date_balance, selectedRow)
        .subscribe(x => {
          const dialogRef = this.dialog.open(ClientReportComponent, {
            panelClass: ['animate__animated', 'animate__slideInRight', 'my-full-screen-dialog'],
            width: '90%',
            height: '98%',
            data: x
          });
          dialogRef.afterClosed().subscribe(a => {

          });
          this.isLoading = false;
        });
    } else {
      this.toast.error('Oraliq muddatni tanlang');
    }
  }
  onSendSMS(content) {
    const selectedRow = this.gridApi.getSelectedRows();
    this.rowDataBalance = selectedRow;
    if (selectedRow.length > 0) {
      this.smsForm.controls.text.setValue('Sum: ' + selectedRow[0].sum +
        ', Hisob raqam: ' + selectedRow[0].hr + ', Dollar: ' + selectedRow[0].dollar);
      this.modalService.open(content, {size: 'lg'}).result.then((result) => {
      }, (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else {
      alert('Jadvaldan tanlang!');
    }
  }
  onSend() {
    const selectedRow = this.gridApi.getSelectedRows();
    if (selectedRow.length > 0) {
      this.isLoading = true;
      this.cashierService.sendSMS(selectedRow, this.smsForm.value.text).pipe(take(1))
        .subscribe(result => {
          this.isLoading = false;
          this.modalService.dismissAll();
        });
    }
  }
  onSubmitExchangeFilter(): void {
    if (this.exchangeFilterForm.valid) {
      this.rowData3 = this.cashierService.getExchangeHistory(this.exchangeFilterForm.value.d1, this.exchangeFilterForm.value.d2);
    } else {
      this.toast.info('Oraliq muddatni tanlang!');
    }
  }
  onInfo(content) {
    const selectedRow = this.gridApi.getSelectedRows();
    this.rowDataBalance = selectedRow;
    if (selectedRow.length > 0) {
      this.info = selectedRow;
      this.modalService.open(content, {size: 'lg'}).result.then((result) => {
      }, (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else {
      alert('Jadvaldan tanlang!');
    }
  }
  onExchange() {
    const selectedRow = this.gridApi.getSelectedRows();
    this.rowDataBalance = selectedRow;
    if (selectedRow.length > 0) {
      const dialogRef = this.dialog.open(CurrencyExchangeComponent, {
        panelClass: ['animate__animated', 'animate__slideInRight'],
        width: '30%',
        height: '100%',
        position: {right: '0'},
        data: selectedRow
      });

      dialogRef.afterClosed().subscribe(result1 => {
        // clearing originalSum
      });
    } else {
      alert('Jadvaldan tanlang!');
    }
  }
  hideCurrency() {
    this.viewAble = !this.viewAble;
    localStorage.setItem('card', this.viewAble + '');
  }
  getBranchInfo(): void {
    this.cashierService.getBranchInfo().pipe(take(1)).subscribe(r => {
      this.branchInfo = r[0].is_sale_open;
    });
  }
  onOpenDay(content): void {
    this.isLoading = true;
    setTimeout( a => {
      this.isLoading = false;
    }, 30000);
    this.modalService.open(content, {size: 'lg'}).result.then((result) => {
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.cashierService.postRequest({id: 9, ids: [1, 2]}, true).pipe(take(1))
      .subscribe((r: any) => {
        if (r.payload.success) {
          this.toast.success('Kun ochildi');
          this.ngOnInit();
          this.authService.getDollarSelf();
          // this.getBranchInfo();
        }
      });
  }
  openNewTab(): void {
    window.open('http://new.meinhaus.uz/auth/login', '_blank');
  }
}

