import {NgModule} from '@angular/core';
import {NotificationListComponent} from './list/notification-list.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {CommonModule} from '@angular/common';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
  declarations: [
    NotificationListComponent
  ],
  exports: [],
    imports: [
        TranslateModule,
        MatIconModule,
        MatDialogModule,
        MatExpansionModule,
        CommonModule,
        MatProgressSpinnerModule
    ]
})

export class NotificationModule {
}
