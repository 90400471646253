export function CurrencyCellRendererSum(params: any) {

  const usdFormate = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'UZS',
    minimumFractionDigits: 0
  });
  return usdFormate.format(params.value);
}

export function CurrencyCellRendererDollar(params: any) {

  const usdFormate = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  });
  return usdFormate.format(params.value);
}

export function CurrencyCellRendererQuantity(params: any) {
  const usdFormate = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2
  });
  return usdFormate.format(params.value);
}

export function currencyFormatter(params) {
  // return '' + this.formatNumber(params.value);
  return Math.floor(params.value)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
