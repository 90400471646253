import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  templateUrl: './ins-sale-check.component.html',
  styleUrls: ['./ins-sale-check.component.css']
})
export class InsSaleCheckComponent implements OnInit {

  data: any;
  graph = [];
  products = [];

  constructor(
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.data = window.history.state.obj1;
    this.graph = window.history.state.obj1.graph;
    this.products = window.history.state.obj1.products;
  }

  onPrint() {
    window.print();
  }
}
