import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {PosService} from '../../pos.service';
import {Router} from '@angular/router';
import {CurrencyCellRendererQuantity} from '../../../../test/table-currency';
import {Subscription} from 'rxjs';

@Component({
  templateUrl: './10day-stat.component.html',
  selector: 'app-10day-stat'
})
export class Day10StatComponent implements OnInit, OnDestroy {

  subscription: Subscription;

  constructor(
    private posService: PosService,
    private router: Router
    ) {
  }
  isLoading = false;
  rowData: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  isVisible: any;
  public modules: Module[] = AllModules;

  columnDefs = [
    {headerName: 'Filial', field: 'branch_name', sortable: true, width: 200},
    {headerName: 'Nomi', field: 'name', sortable: true, width: 250, filter: 'agTextColumnFilter', checkboxSelection: true},
    {headerName: 'Barcode', field: 'barcode', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Miqdori', field: 'quantity', sortable: true, filter: 'agTextColumnFilter', width: 150,
      cellRenderer: this.currencyCellRendererSum},
    {headerName: 'Dona', field: 'dona', sortable: true, filter: 'agTextColumnFilter', width: 150,
      cellRenderer: CurrencyCellRendererQuantity},
    {headerName: 'Summa', field: 'total_sum', sortable: true, filter: 'agNumberColumnFilter', width: 150,
      cellRenderer: this.currencyCellRendererSum}
  ];

  ngOnInit(): void {
    if (this.router.url === '/production') {
      // this.rowData = this.prodService.getDayManualStat();
    } else if (this.router.url === '/sale_history') {
      this.subscription = this.posService.getData()
        .subscribe(result => {
          this.rowData = this.posService.getPosSale10DayStat(result);
        });
    }
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    param.api.sizeColumnsToFit();
  }

  private currencyCellRendererSum(params: any) {
    const usdFormate = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0
    });
    return usdFormate.format(params.value);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
