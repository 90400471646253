import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {dateParser} from '../dateParser';


const BACKEND_URL = environment.api_URL;

@Injectable({providedIn: 'root'})
export class CashierService {
  private inOutValueListener = new Subject<any>();
  private operationListener = new Subject<any>();
  cast$ = this.operationListener.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
  }
  getDayBalance() {
    return this.http.get<any>(BACKEND_URL + 'crm/pos/day/get');
  }

  postCashierOperation(customer: number, operationType: number, currency: number, cost: number, comment: string) {
    const post = {
      customer,
      operationType,
      currency,
      cost,
      comment
    };
    return this.http.post<any>(BACKEND_URL + 'crm/pos/day/operation/post', post)
      .subscribe(result => {
        if ( result.message === '1') {
          this.operationListener.next(result);
        }
      });
  }

  postCashierOperation2(customer: number, operationType: number, currency: number, cost: number, comment: string) {
    const post = {
      customer,
      operationType,
      currency,
      cost,
      comment
    };
    return this.http.post<any>(BACKEND_URL + 'crm/pos/day/operation/post', post);
  }

  updatePaidCostStatus(id: number): any {
    return this.http.post<any>(BACKEND_URL + 'crm/delivery/paid/cost/status/update', {id});
  }

  postExchangeCompanyBalance(company: number, type: number, cost: number, rate: number) {
    const exchange = {
      company,
      type,
      cost,
      rate
    };
    return this.http.post<{message: string}>(BACKEND_URL + 'crm/cashier/exchange/balance/post', exchange);
  }

  getInOut(d1: Date, d2: Date, obj: any, isFirst: boolean) {
    let app;
    if (isFirst) {
      app = obj;
    } else {
      const date1 = dateParser(d1);
      const date2 = dateParser(d2);

      const diff = Math.abs(d1.getTime() - d2.getTime());
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

      if (diffDays < 8) {
        localStorage.setItem('c_1', date1);
        localStorage.setItem('c_2', date2);

        app = {
          d1: localStorage.getItem('c_1'),
          d2: localStorage.getItem('c_2'),
        };
      } else {
        app = {
          d1: date1,
          d2: date2,
        };
      }
    }
    return this.http.post<any>(BACKEND_URL + 'crm/cashier/inout/get', app);
  }

  getBalance() {
    return this.http.get<any>(BACKEND_URL + 'crm/cashier/balance/get');
  }

  getExchangeHistory(d1: Date, d2: Date) {
    const data = {
      d1,
      d2
    };
    return this.http.post<any>(BACKEND_URL + 'crm/cashier/exchange/history/get', data);
  }

  getTransactions(date1: Date, date2: Date, customer: number) {
    const date11 = dateParser(date1);
    const date22 = dateParser(date2);
    const app = {
      customer,
      d1: date11,
      d2: date22
    };
    return this.http.post<any>(BACKEND_URL + 'crm/cashier/transactions/get', app);
  }

  getTransactionReport(date1: Date, date2: Date, customer: number) {
    const date11 = dateParser(date1);
    const date22 = dateParser(date2);
    const app = {
      customer,
      d1: date11,
      d2: date22
    };
    return this.http.post<any>(BACKEND_URL + 'crm/cashier/transaction/report/get', app);
  }

  postDayClose(inSum: number, inDollar: number, inHr: number, outSum: number, outDollar: number, outHr: number) {
    const post = {
      inSum,
      inDollar,
      inHr,
      outSum,
      outDollar,
      outHr
    };
    this.http.post<{message: string}>(BACKEND_URL + 'crm/cashier/day/close/post', post)
      .subscribe(result => {
        if (result.message === '1') {
          this.router.navigate(['../cashier/closeCheck'], {state: {alarm: post}});
        }
      });
  }

  postCurrencySelf(cost: number, type: number): any {
    const app = {
      cost,
      type
    };
    return this.http.post<{message: string}>(BACKEND_URL + 'crm/cashier/dollar/self/post', app);
  }
  postInOutReturn(id: number) {
    const app = {
      id
    };
    return this.http.post<{message: string}>(BACKEND_URL + 'crm/cashier/inout/return', app)
      .subscribe(result => {
        if (result.message === '1') {
          this.inOutValueListener.next(result);
        }
      });
  }
  getInOutSubs() {
    return this.inOutValueListener.asObservable();
  }

  getCashierBalanceHistory(id: number , date1: Date, date2: Date) {
    if (typeof id !== 'number') {
      id = 0;
    }
    const app = {
      id,
      date1,
      date2
    };
    return this.http.post<any>(BACKEND_URL + 'crm/cashier/balance/history', app);
  }

  sendSMS(data: any, text: string): any {
    const data1 = {
      phone: data,
      text
    };
    return this.http.post<any>(BACKEND_URL + 'crm/cashier/balance/sms', data1);
  }

  getBranchInfo(): any {
    return this.http.get<any>(BACKEND_URL + 'crm/pos/branch/info');
  }

  postRequest(obj: any, confirm1: boolean): any {
    if (confirm1) {
      if (confirm('Rostdan amalga oshirmoqchimisiz?')) {
        const data = {
          obj
        };
        return this.http.post<any>(BACKEND_URL + 'core/request', data);
      }
    } else {
      const data = {
        obj
      };
      return this.http.post<any>(BACKEND_URL + 'core/request', data);
    }
  }
}
