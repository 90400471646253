import {Component, OnDestroy, OnInit} from '@angular/core';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {ProdService} from '../../prod.service';
import {MatDialog} from '@angular/material/dialog';
import {PMListItemsComponent} from './p-m-list-items/p-m-list-items.component';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CurrencyCellRendererQuantity} from '../../../test/table-currency';
import {take} from 'rxjs/operators';

interface Line {
  id: number;
  name: string;
}

@Component({
  selector: 'app-production-m-list',
  templateUrl: './production-m-list.component.html',
  styleUrls: ['./production-m-list.component.css']
})
export class ProductionMListComponent implements OnInit, OnDestroy {
  rowData: any;
  rowData1: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  public modules: Module[] = AllModules;
  private manualProductionSubs = new Subscription();
  filterForm: FormGroup;
  filterForm1: FormGroup;
  date1 = '';
  date2 = '';
  date11 = '';
  date21 = '';
  lines = [];

  constructor(
    private prodService: ProdService,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {
    this.defaultColDef = { resizable: true };
  }

  columnDefs = [
    {headerName: '#', field: 'id', sortable: true, width: 100, checkboxSelection: true},
    {headerName: 'Date', field: 'cr_on', sortable: true, filter: 'agTextColumnFilter', width: 100},
    {headerName: 'Nomi', field: 'name', sortable: true, filter: 'agTextColumnFilter', width: 300},
    {headerName: 'Barcode', field: 'barcode', sortable: true, filter: 'agTextColumnFilter'},
    {headerName: 'Quantity', field: 'quantity', sortable: true, filter: 'agNumberColumnFilter',
      cellRenderer: CurrencyCellRendererQuantity},
    {headerName: 'Ishchi', field: 'user_id', sortable: true, filter: 'agTextColumnFilter'}
  ];

  columnDefs1 = [
    {headerName: '#', field: 'id', sortable: true, width: 100},
    {headerName: 'Date', field: 'cr_on', sortable: true, filter: 'agTextColumnFilter', width: 100},
    {headerName: 'Nomi', field: 'name', sortable: true, filter: 'agTextColumnFilter', width: 300},
    {headerName: 'Barcode', field: 'barcode', sortable: true, filter: 'agTextColumnFilter'},
    {headerName: 'Quantity', field: 'quantity', sortable: true, filter: 'agNumberColumnFilter',
      cellRenderer: CurrencyCellRendererQuantity},
    {headerName: 'Bolim', field: 'line', sortable: true, filter: 'agNumberColumnFilter'},
    {headerName: 'Foydalanuvchi', field: 'cr_by', sortable: true, filter: 'agNumberColumnFilter'},
    {headerName: 'Asos raqami', field: 'r_h', sortable: true, filter: 'agNumberColumnFilter'},
  ];

  ngOnInit(): void {
    this.date1 = localStorage.getItem('mpd1');
    this.date2 = localStorage.getItem('mpd2');
    this.date11 = localStorage.getItem('mpd1_1');
    this.date21 = localStorage.getItem('mpd2_1');
    this.initFilterForm();
    this.initFilterForm1();
    const app = {
      d1: localStorage.getItem('mpd1'),
      d2: localStorage.getItem('mpd2'),
    };
    const app1 = {
      d1: localStorage.getItem('mpd1_1'),
      d2: localStorage.getItem('mpd2_1'),
    };
    const  a = new Date();

    this.rowData = this.prodService.getProdHistory(a, a, app,
      true, 'mpd1', 'mpd2', 'crm/production/manual/list', this.filterForm.value.line);
    this.rowData1 = this.prodService.getProdHistory(a, a, app1,
      true, 'mpd1_1', 'mpd2_1', 'crm/production/manual/ingredients', this.filterForm1.value.line);
    this.manualProductionSubs = this.prodService.cast$
      .subscribe(result => {
        this.ngOnInit();
      });

    this.prodService.getLine()
      .subscribe((result: Line[]) => {
        this.lines = result;
      });
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    param.api.sizeColumnsToFit();
  }

  onRowDoubleClick() {
    const selectedRow = this.gridApi.getSelectedRows()[0];
    const dialogRef = this.dialog.open(PMListItemsComponent, {
      width: '30%',
      height: '100%',
      position: {right: '0'},
      data: selectedRow
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.ngOnInit();
    });
    // this.router.navigate(['../../inventory/invoice/add']);

  }

  onBtnExport() {
    this.gridApi.exportDataAsCsv();
  }

  ngOnDestroy(): void {
    this.manualProductionSubs.unsubscribe();
  }

  initFilterForm(): void {
    this.filterForm = this.fb.group({
      s_date: [Validators.required],
      e_date: [Validators.required],
      line: ['', Validators.required]
    });
  }
  initFilterForm1(): void {
    this.filterForm1 = this.fb.group({
      s_date: [Validators.required],
      e_date: [Validators.required],
      line: ['', Validators.required]
    });
  }
  onFilterSubmit(): void {
    if (this.filterForm.valid) {
      const app = {
        line: this.filterForm.value.line,
        d1: localStorage.getItem('mpd1'),
        d2: localStorage.getItem('mpd2'),
      };
      this.rowData = this.prodService.getProdHistory(this.filterForm.value.s_date,
        this.filterForm.value.e_date, app, false, 'mpd1', 'mpd2', 'crm/production/manual/list', this.filterForm.value.line);
      this.date1 = localStorage.getItem('mpd1');
      this.date2 = localStorage.getItem('mpd2');
    }
  }

  onFilterSubmit1(): void {
    if (this.filterForm1.valid) {
      const app = {
        line: this.filterForm1.value.line,
        d1: localStorage.getItem('mpd1_1'),
        d2: localStorage.getItem('mpd2_1'),
      };
      this.rowData1 = this.prodService.getProdHistory(this.filterForm1.value.s_date,
        this.filterForm1.value.e_date, app, false, 'mpd1_1', 'mpd2_1', 'crm/production/manual/ingredients', this.filterForm1.value.line);
      this.date11 = localStorage.getItem('mpd1_1');
      this.date21 = localStorage.getItem('mpd2_1');
    }
  }

  onReturnManualProduction(): void {
    const selectedRow = this.gridApi.getSelectedRows()[0];
    console.log(selectedRow);
    if (selectedRow.id > 0) {
      if (confirm('Rostdan ham qaytarmoqchimisiz ?')) {
        this.prodService.deleteManualProduction(selectedRow.id)
          .pipe(take(1))
          .subscribe(result => {
            console.log(result);
          });
      } else {

      }
    }
  }
}
