import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../auth/auth.guard';
import {InventoryListComponent} from './inventory/inventory-list/inventory-list.component';
import {InvoiceListComponent} from './inventory/invoice/invoice-list/invoice-list.component';
import {InvoiceAddComponent} from './inventory/invoice/invoice-add/invoice-add.component';
import {CompanyAddComponent} from './inventory/invoice/company-add/company-add.component';
import {CurrencyAddComponent} from './inventory/invoice/currency-add/currency-add.component';
import {TypeAddComponent} from './inventory/invoice/type-add/type-add.component';
import {SaleComponent} from './pos/sale/sale.component';
import {CashierDayComponent} from './cashier/cashier-day/cashier-day.component';
import {SaleHistoryComponent} from './pos/sale-history/sale-history.component';
import {WarehouseAddComponent} from './inventory/invoice/warehouse-add/warehouse-add.component';
import {AdminOperComponent} from './Accounting/admin-oper/admin-oper.component';
import {AdminDashboardComponent} from './Accounting/dashboard/admin-dashboard.component';
import {AccountingAddoperationComponent} from './Accounting/admin-oper/accounting-addoperation/accounting-addoperation.component';
import {SaleCheckComponent} from './pos/sale/sale-check/sale-check.component';
import {CloseDayCheckComponent} from './cashier/cashier-day/close-day-check/close-day-check.component';
import {InventoryHistoryComponent} from './inventory/inventory-list/inventory-history/inventory-history.component';
import {CashierBalanceHComponent} from './cashier/cashier-balance-h/cashier-balance-h.component';
import {ExcellImportComponent} from './inventory/inventory-list/product-excell-import/excell-import.component';
import {CompanyListComponent} from './inventory/invoice/company-add/company-list/company-list.component';
import {InPropertyComponent} from './inventory/invoice/inventory-property/in-property.component';
import {InListComponent} from './inventory/invoice/invoice-list/in-list/in-list.component';
import {InventoriesComponent} from './inventory/inventory-list/inventories/inventories.component';

const routes: Routes = [
  { path: 'inventory', component: InvoiceListComponent, canActivate: [AuthGuard] },
  { path: 'inventory/products', component: InventoriesComponent, canActivate: [AuthGuard] },
  { path: 'inventory/invoice', component: InListComponent, canActivate: [AuthGuard] },
  { path: 'inventory/invoice/add', component: InvoiceAddComponent, canActivate: [AuthGuard] },
  { path: 'inventory/product', component: InventoryListComponent, canActivate: [AuthGuard] },

  { path: 'inventory/company/add', component: CompanyAddComponent, canActivate: [AuthGuard] },
  { path: 'inventory/company/list', component: CompanyListComponent, canActivate: [AuthGuard] },

  { path: 'inventory/currency/add', component: CurrencyAddComponent, canActivate: [AuthGuard] },
  { path: 'inventory/type/add', component: TypeAddComponent, canActivate: [AuthGuard] },
  { path: 'inventory/warehouse/add', component: WarehouseAddComponent, canActivate: [AuthGuard] },
  { path: 'inventory/history/:id', component: InventoryHistoryComponent, canActivate: [AuthGuard] },
  { path: 'inventory/import/excel', component: ExcellImportComponent, canActivate: [AuthGuard] },
  { path: 'inventory/properties', component: InPropertyComponent, canActivate: [AuthGuard] },

  // Cashire routes
  { path: 'cashier', component: CashierDayComponent, canActivate: [AuthGuard] },
  { path: 'cashier/closeCheck', component: CloseDayCheckComponent, canActivate: [AuthGuard] },
  { path: 'cashier/balance/history', component: CashierBalanceHComponent, canActivate: [AuthGuard] },

  // Pos routes
  { path: 'pos', component: SaleComponent, canActivate: [AuthGuard] },
  { path: 'sale_history', component: SaleHistoryComponent, canActivate: [AuthGuard] },
  { path: 'pos/receiptCheck', component: SaleCheckComponent, canActivate: [AuthGuard] },

  // Accounting
  { path: 'accounting/dashboard', component: AdminDashboardComponent, canActivate: [AuthGuard]},
  { path: 'accounting/operation', component: AdminOperComponent, canActivate: [AuthGuard]},
  { path: 'accounting/operation/add', component: AccountingAddoperationComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class CrmRoutingModule {}
