import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import { CurrencyCellRendererSum} from '../../../../test/table-currency';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {InstallmentService} from '../../installment.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels} from '@techiediaries/ngx-qrcode';
import {take} from 'rxjs/operators';


@Component({
  templateUrl: './ins-oper-list.component.html',
  styleUrls: ['./ins-oper-list.component.css']
})
export class InsOperListComponent implements OnInit {

  rowData: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  public modules: Module[] = AllModules;
  checkData: any;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = 'Isbd.uz';
  graph;
  sum = 0;
  index = 0;

  constructor(
    private router: Router,
    private toast: ToastrService,
    private insService: InstallmentService,
    private modalService: NgbModal
  ) {
    this.defaultColDef = {resizable: true};
  }

  columnDefs = [
    {headerName: 'Sana', field: 'cr_on', sortable: true, filter: 'agNumberColumnFilter', width: 100},
    {headerName: 'Filial', field: 'branch_name', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Ins id', field: 'ins_sale_id', sortable: true, filter: 'agNumberColumnFilter', width: 50},
    {headerName: 'Toliq ism', field: 'customer_name', sortable: true, filter: 'agTextColumnFilter', width: 200, checkboxSelection: true},
    {headerName: 'Tolov turi', field: 'payment_type', sortable: true, filter: 'agNumberColumnFilter', width: 75},
    {headerName: 'Summa', field: 'amount', sortable: true, filter: 'agNumberColumnFilter', width: 150,
      cellRenderer: CurrencyCellRendererSum},
    {headerName: 'Hodim', field: 'user_name', sortable: true, filter: 'agNumberColumnFilter', width: 150},
    {headerName: 'Izox', field: 's_comment', sortable: true, filter: 'agTextColumnFilter'}
  ];

  ngOnInit(): void {
    if (window.history.state.id !== undefined) {
      this.insService.getInsOperationList(window.history.state.id).pipe(take(1))
        .subscribe(result => {
          this.rowData = result;
          this.rowData.forEach(r => {
            if (r.s_comment !== 'Oldindan tolov') {
              this.sum += Number(r.amount);
            }
          });
        });
      this.insService.getPaymentGraph(window.history.state.id)
        .subscribe(x => {
          this.graph = x;
          let summa = 0;
          x.forEach((r1, index) => {
            summa = Number(r1.t_sum) + summa;
            // console.log(summa)
            if (this.sum > summa) {
              // console.log(summa)
              this.index = index;
            }
          });
        });
    } else {
      this.insService.getInsOperationList(0).pipe(take(1))
        .subscribe(result => {
          this.rowData = result;
        });
    }
  }
  onGridReady({api}) {
    this.gridApi = api;
    this.gridApi.sizeColumnsToFit();
  }
  open(content) {
    if (this.gridApi.getSelectedRows().length > 0) {
      this.checkData = this.gridApi.getSelectedRows()[0];
      this.value = this.checkData.cr_on + ',' +
        this.checkData.customer_name + ', ' + this.checkData.currency_name + ', ' + this.checkData.amount;
      this.modalService.open(content, {size: 'lg'}).result.then((result) => {
      }, (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else {
      this.toast.info('Jadvaldan qatorni tanlang!');
    }
  }
  print(name: string) {
    // window.print();
    const printContent = document.getElementById(name);
    let htmlPrint = '' +
      '<style>' +
      '.company-name {' +
      'font-weight: bold' +
      '}' +
      '.header-center {' +
      'display: flex;' +
      'justify-content: center;' +
      'align-items: center;' +
      '}' +
      '.header-column-flex {' +
      'display: flex;' +
      'flex-direction: column;' +
      'justify-content: center;' +
      'align-items: center' +
      '}' +
      '.header-text {' +
      'display: flex;' +
      'justify-content: space-between;' +
      'align-items: center;' +
      ' line-height: 2px;' +
      '}' +
      'table {' +
      'width: 100%;' +
      '}' +
      'p {' +
      'font-size: 14px;' +
      '}' +
      '.center {' +
      'text-align: center' +
      '}' +
      'hr {' +
      'border-bottom: 1px solid black;' +
      '}' +
      ':root:after { \n' +
      '.img-qrcode > img{' +
      'display: flex;' +
      'justify-content: center;' +
      'align-items: center;' +
      'width: 150px;' +
      'height: 150px;' +
      'font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;' +
      'font-size: 17px;' +
      'text-align: center;' +
      '}' +
      '#legalcopy > p {' +
      'text-align: center;' +
      '}' +
      '.info > h2, p {' +
      'text-align: center;' +
      '}' +
      'ngx-qrcode {' +
      'margin: auto;' +
      '}' +
      'code {' +
      'font-size: 16px' +
      '}' +
      'table th {' +
      'border-bottom: 0.5px solid #000;' +
      'padding: 2px;' +
      '}' +
      'table td {' +
      'border-bottom: 0.5px solid #000;' +
      'border-right: 0.5px solid #000;' +
      'padding: 2px' +
      '}' +
      'h1 {' +
      'text-align: center' +
      '}' +
      '.logo-img {' +
      'background: url("https://api.isbd.uz/images/shaptoli11-1657561001316-551302404.jpg");' +
      'display: flex;' +
      'justify-content: center;' +
      'align-items: center;' +
      'width: 50px;' +
      'height: 50px;' +
      '}' +
      '</style>';
    htmlPrint += printContent.outerHTML;
    const WindowPrt = window.open();
    WindowPrt.document.write(htmlPrint);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
  }
  onEdit(): void {
    if (this.gridApi.getSelectedRows().length > 0) {
      this.checkData = this.gridApi.getSelectedRows()[0];
      this.router.navigate(['/installment/oper/add']);
    }
  }
}
