import {Component, Inject, OnInit, Optional} from '@angular/core';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PosService} from '../../pos.service';
import {CurrencyCellRendererQuantity} from '../../../../test/table-currency';
import {Router} from '@angular/router';

interface Sale {
  product_id: number;
  barcode: string;
  name: string;
  currency: number;
  cost: number;
  image: string;
  product_unit: string;
  quantity: number;
  sum: number;
}

@Component({
  templateUrl: './sale-items-history.component.html',
  styleUrls: ['./sale-items-history.component.css']
})
export class SaleItemsHistoryComponent implements OnInit {
  gridApi;
  gridColumnApi;
  defaultColDef;
  public modules: Module[] = AllModules;
  public rowData: any;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private posService: PosService,
    private router: Router
  ) {
    this.rowData = data.products;
  }

  columnDefs1 = [
    {headerName: 'Barcode', field: 'barcode', sortable: true, filter: true, resizable: true, width: 200},
    {headerName: 'Nomi', field: 'name', sortable: true, filter: 'agTextColumnFilter', width: 150, resizable: true},
    {headerName: 'Valyuta', field: 'currency1', sortable: true, filter: 'agTextColumnFilter', width: 100},
    {
      headerName: 'Miqdori', field: 'quantity', sortable: true, filter: 'agNumberColumnFilter',
      width: 150, cellRenderer: CurrencyCellRendererQuantity
    },
    {
      headerName: 'Narx', field: 'cost', sortable: true, filter: 'agNumberColumnFilter',
      width: 100, resizable: true, cellRenderer: CurrencyCellRendererQuantity
    },
    {
      headerName: 'Jami', field: 'sum', sortable: true, filter: 'agNumberColumnFilter',
      width: 100, resizable: true, cellRenderer: CurrencyCellRendererQuantity
    },
  ];

  ngOnInit(): void {
  }

}
