import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ApplistModel} from './applist.model';
import {Subject} from 'rxjs';
import {AuthService} from '../auth/auth.service';

const BACKEND_URL = environment.api_URL;

@Injectable({providedIn: 'root'})
export class ApplistService {
  private appList: ApplistModel[] = [];
  private appsUpdated = new Subject<ApplistModel[]>();

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getApps() {
    this.http.post<{message: string, appList: ApplistModel[]}>(BACKEND_URL + 'applist/get',
      {userId: '' + this.authService.getUserId() + ''})
      .subscribe((appListData) => {
          this.appList = appListData.appList;
          this.appsUpdated.next([...this.appList]);
      });
  }

  getAppListUpdateListener() {
    return this.appsUpdated.asObservable();
  }
}
