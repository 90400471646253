import {Component, OnInit} from '@angular/core';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {InventoryService} from '../../../inventory.service';

@Component({
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.css'],
  selector: 'app-company-list'
})
export class CompanyListComponent implements OnInit {
  rowDataCompany: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  defaultColDefCompany;
  isLoading = false;
  public modules: Module[] = AllModules;
  constructor(
    private http: HttpClient,
    private router: Router,
    private invoiceService: InventoryService,
  ) {
    this.defaultColDefCompany = { resizable: true };
  }

  columnDefsCompany = [
    {headerName: 'Filial', field: 'branch_name', sortable: true, filter: 'agTextColumnFilter', width: 150, checkboxSelection: true},
    {headerName: 'Turi', field: 'type', sortable: true, filter: 'agTextColumnFilter', width: 150 },
    {headerName: 'Nomi', field: 'name', sortable: true, filter: 'agTextColumnFilter', width: 300  },
    {headerName: 'Email', field: 'email', sortable: true, filter: 'agTextColumnFilter', width: 200  },
    {headerName: 'Telefon', field: 'phone_number', sortable: true, filter: 'agTextColumnFilter', width: 200  },
    {headerName: 'Manzil', field: 'location', sortable: true, filter: 'agTextColumnFilter', width: 200  },
    {headerName: 'Balans', field: 'is_open', sortable: true, filter: 'agTextColumnFilter', width: 100  },
    {headerName: 'Izox', field: 'comment', sortable: true, width: 300  },
  ];

  ngOnInit(): void {
    this.rowDataCompany = this.invoiceService.getCompany();
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    this.gridApi.sizeColumnsToFit();
  }
  // Adding company to the list of companies
  addCompany() {
    this.router.navigate(['../../inventory/company/add']);
  }

  onEditCompany() {
    const selectedRow = this.gridApi.getSelectedRows()[0];
    if (selectedRow.id !== null) {
      this.router.navigate(['../../inventory/company/add'], { state: { alarm: selectedRow } });
    } else {
      alert('Kompaniyani tanlang!');
    }
  }
}
