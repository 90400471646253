import {Component, OnInit} from '@angular/core';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {InstallmentService} from '../installment.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {CurrencyCellRendererSum} from '../../../test/table-currency';


@Component({
  templateUrl: './ins-customers.component.html',
  styleUrls: ['./ins-customers.component.css']
})
export class InsCustomersComponent implements OnInit {

  rowData: any = [];
  gridApi;
  gridColumnApi;
  defaultColDef;
  public modules: Module[] = AllModules;

  constructor(
    private insService: InstallmentService,
    private router: Router,
    private toast: ToastrService
  ) {
    this.defaultColDef = {resizable: true};
  }

  columnDefs = [
    {headerName: 'Filial', field: 'branch_name', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Ism', field: 'full_name', sortable: true, filter: 'agTextColumnFilter', checkboxSelection: true, width: 150},
    {headerName: 'Email', field: 'email', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Tugulgan kun', field: 'birthdate', sortable: true, filter: 'agTextColumnFilter', width: 100},
    {headerName: 'Ish joyi', field: 'working_place', sortable: true, filter: 'agNumberColumnFilter', width: 150},
    {headerName: 'Lavozim', field: 'mansab', sortable: true, filter: 'agNumberColumnFilter', width: 150},
    {headerName: 'Maosh', field: 'salary', sortable: true, filter: 'agNumberColumnFilter', width: 150,
      cellRenderer: CurrencyCellRendererSum},
    {headerName: 'Pasport seriasi', field: 'passport_seria', sortable: true, filter: 'agNumberColumnFilter', width: 110},
    {headerName: 'K/T berilgan', field: 'who_given', sortable: true, filter: 'agNumberColumnFilter', width: 150},
    {headerName: 'Sana', field: 'pass_date', sortable: true, filter: 'agNumberColumnFilter', width: 150},
    {headerName: 'Manzil', field: 'address', sortable: true, filter: 'agNumberColumnFilter', width: 150},
    {headerName: 'Izox', field: 's_comment', sortable: true, filter: 'agNumberColumnFilter'}
  ];

  ngOnInit(): void {
    this.rowData = this.insService.getInsCustomer();
  }

  onGridReady({api}) {
    this.gridApi = api;
    this.gridApi.sizeColumnsToFit();
  }

  onEdit() {
    const app = this.gridApi.getSelectedRows()[0];
    if (app.id > 0) {
      this.router.navigate(['installment/customer/add'], {state: app});
    } else {
      this.toast.info('Taxrirlash uchun haridor tanlang!');
    }
  }
}
