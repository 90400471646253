import {Component, OnInit} from '@angular/core';
import {UserService} from '../user.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../auth/auth.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  user: any = [];
  userForm: FormGroup;
  gender: any[] = [
    {id: 1, name: 'Erkak'},
    {id: 2, name: 'Ayol'}
  ];

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private toast: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.userForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      fatherName: new FormControl(null, Validators.required),
      userName: new FormControl(null, Validators.required),
      password: new FormControl(null),
      password2: new FormControl(null),
      gender: new FormControl(null, Validators.required),
      phone: new FormControl(null, Validators.required),
      location: new FormControl(null, Validators.required)
    });


    this.userService.getUserInfo(this.authService.getUserId())
      .subscribe((result: any) => {
        this.userForm.controls.id.setValue(result.id);
        this.userForm.controls.firstName.setValue(result.first_name);
        this.userForm.controls.lastName.setValue(result.last_name);
        this.userForm.controls.fatherName.setValue(result.father_name);
        this.userForm.controls.userName.setValue(result.user_name);
        this.userForm.controls.location.setValue(result.location);
        this.userForm.controls.gender.patchValue(result.gender);
        this.userForm.controls.phone.setValue(result.phone);
      });
  }

  onSubmit() {
    if (this.userForm.valid) {
      if (this.userForm.value.password === this.userForm.value.password2) {
        this.userService.editProfile(
          this.userForm.value.id,
          this.userForm.value.firstName,
          this.userForm.value.lastName,
          this.userForm.value.fatherName,
          this.userForm.value.userName,
          this.userForm.value.password,
          this.userForm.value.location,
          this.userForm.value.gender,
          this.userForm.value.phone
        )
          .subscribe(x => {
            this.userForm.controls.password.setValue('');
            this.userForm.controls.password2.setValue('');
          });
      } else {
        alert('Tasdiqlangan parol bir hil emas!');
      }
    } else {
      this.toast.info('Hamma ma\'lumotlarni kiriting!');
    }
  }

  goBack() {
    this.router.navigate(['./applist']);
  }
}
