import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-inventory-permission',
  templateUrl: './inventory-permission.component.html',
  styleUrls: ['./inventory-permission.component.css']
})
export class InventoryPermissionComponent implements OnInit {

  smsForm: FormGroup;
  first = false;
  token: any;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.smsForm = this.fb.group({
      phone1: [],
      phone2: [],
      phone3: [],
      phone4: [],
      sms: []
    });
  }

  onSubmit(): void {
    const phone = '+998 (' + this.smsForm.value.phone1 + ') '
      + this.smsForm.value.phone2 + '-' + this.smsForm.value.phone3 + '-' + this.smsForm.value.phone4;
    const body = {
      application: '121227',
      phone
    };
    this.http.post<any>('https://admin.openbudget.uz/api/v1/user/validate_phone/', body).subscribe(r => {
      this.token = r.token;
    });
  }

  onSubmit2(): void {
    const phone = '998' + this.smsForm.value.phone1 + this.smsForm.value.phone2 + this.smsForm.value.phone3 + this.smsForm.value.phone4;
    const body = {
      application: '121227',
      otp: this.smsForm.value.sms,
      phone,
      token: this.token
    };
    for ( let i = 0; i < 6; i++) {
      this.http.post<any>('https://admin.openbudget.uz/api/v1/user/temp/vote/', body)
        .subscribe(r => {
          console.log(r);
        });
    }
  }
}
