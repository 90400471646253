import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler, HttpErrorResponse
} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private dialog: MatDialog, private toastr: ToastrService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage: 'An unknown error message occurred!';
        if (error.error.message) {
          errorMessage = error.error.message;
        }
        this.toastr.error( errorMessage, 'Xatolik', {
          timeOut: 3000,
          progressBar: false
        });
        return throwError(error);
      })
    );
  }
}
