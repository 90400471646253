import {Component, OnInit} from '@angular/core';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {MobileService} from '../mobile.service';
import {CurrencyCellRendererQuantity, CurrencyCellRendererSum} from '../../../test/table-currency';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InventoryService} from '../../inventory/inventory.service';
import {CashierService} from '../../cashier/cashier.service';
import {take} from 'rxjs/operators';
import {FormBuilder, FormGroup} from "@angular/forms";
import {dateParser} from "../../dateParser";


@Component({
  templateUrl: 'product-order.component.html',
  styleUrls: ['product-order.component.css']
})
export class ProductOrderComponent implements OnInit {
  rowData: any = [];
  rowDataReport: any = [];
  tableData: any = [];
  tableData4: any = [];
  gridApi;
  gridColumnApi;
  defaultColDef;
  public modules: Module[] = AllModules;
  products: any;
  cardData: any;
  totalCost: number;
  company = [];
  selected = 0;
  exchangeFilterForm: FormGroup;


  constructor(
    private http: HttpClient,
    private router: Router,
    private mobileService: MobileService,
    private modalService: NgbModal,
    private inventoryService: InventoryService,
    private cashierService: CashierService,
    private fb: FormBuilder
  ) {
    this.defaultColDef = {resizable: true};
  }

  columnDefs = [
    {
      headerName: 'Sana',
      field: 'time',
      sortable: true,
      filter: 'agTextColumnFilter',
      width: 150,
      checkboxSelection: true
    },
    {headerName: 'Toliq ism', field: 'full_name', sortable: true, filter: 'agNumberColumnFilter', width: 300},
    {headerName: 'Telefon', field: 'phone', sortable: true, filter: 'agNumberColumnFilter', width: 300},
    {headerName: 'Mijoz', field: 'customer_name', sortable: true, filter: 'agTextColumnFilter', width: 300},
    {
      headerName: 'Umumiy summa', field: 'total_cost', sortable: true, filter: 'agTextColumnFilter', width: 150,
      valueFormatter: CurrencyCellRendererSum, aggFunc: 'avg'
    },
    {headerName: 'Filial', field: 'branch_name', sortable: true, filter: 'agTextColumnFilter', width: 200},
    {headerName: 'Hodim', field: 'user_name', sortable: true, filter: 'agTextColumnFilter', width: 200},
    {headerName: 'Izox', field: 'comment_s', sortable: true, filter: 'agTextColumnFilter'}
  ];

  columnDefs1 = [
    {headerName: 'Id', field: 'order_id', sortable: true, filter: 'agNumberColumnFilter', width: 100,
      checkboxSelection: true, rowGroup: true, hide: true},
    {
      headerName: 'Sana',
      field: 'date',
      sortable: true,
      filter: 'agTextColumnFilter',
      width: 200,
      aggFunc: 'avg'
    },
    {
      headerName: 'Umumiy summa', field: 'total_cost', sortable: true, filter: 'agNumberColumnFilter', width: 250,
      valueFormatter: CurrencyCellRendererSum, aggFunc: 'avg'
    },
    {headerName: 'Xaridor', field: 'customer_name', sortable: true, filter: 'agTextColumnFilter', width: 300},
    {headerName: 'Barcode', field: 'barcode', sortable: true, filter: 'agTextColumnFilter', width: 300},
    {headerName: 'Nomi', field: 'name', sortable: true, filter: 'agTextColumnFilter', width: 200},
    {headerName: 'Narxi', field: 'cost', sortable: true,
      cellRenderer: CurrencyCellRendererQuantity,
      filter: 'agTextColumnFilter', width: 200},
    {headerName: 'Miqdori', field: 'quantity', cellRenderer: CurrencyCellRendererQuantity,
      sortable: true, filter: 'agTextColumnFilter', width: 200},
    {headerName: 'Hodim', field: 'user_name', sortable: true, filter: 'agTextColumnFilter', width: 200},
    {headerName: 'Izox', field: 'comment_s', sortable: true, filter: 'agTextColumnFilter'}
  ];

  columnDefs4 = [
    {headerName: 'Id', field: 'id', sortable: true, filter: 'agNumberColumnFilter', width: 100,
      checkboxSelection: true},
    {
      headerName: 'Sana',
      field: 'sana',
      sortable: true,
      filter: 'agTextColumnFilter',
      width: 200
    },
    {
      headerName: 'Umumiy summa', field: 'paid_cost', sortable: true, filter: 'agNumberColumnFilter', width: 250,
      valueFormatter: CurrencyCellRendererSum
    },
    {headerName: 'Xaridor', field: 'customer_name', sortable: true, filter: 'agTextColumnFilter', width: 300},
    {headerName: 'Hodim', field: 'cr_by', sortable: true, filter: 'agTextColumnFilter', width: 200},
    {headerName: 'Holati', field: 'status', sortable: true, filter: 'agTextColumnFilter', width: 200}
  ];

  ngOnInit(): void {
    const date1 = new Date();
    const to = dateParser(date1);
    const mm = String(date1.getMonth() + 1).padStart(2, '0');
    const yyyy = date1.getFullYear();
    const from = yyyy + '-' + mm + '-' + '01';
    this.initExchangeForm(from, to);
    this.getOrderAll();
    // Getting companies
    this.inventoryService.getCompany()
      .subscribe((result) => {
        this.company = result;
      });
    this.getOrder();
    this.getOrderReport();
    this.getDeliveryPaidCost();
    // this.initExchangeForm(from, to);
  }

  initExchangeForm(from: any, to: any): void {
    this.exchangeFilterForm = this.fb.group({
      d1: [from],
      d2: [to]
    });
  }

  getOrder(): void {
    this.mobileService.getMobileOrder()
      .subscribe(r => {
        this.rowData = r;
      });
  }

  getOrderAll(): void {
    this.mobileService.getMobileOrderAll(this.exchangeFilterForm.value.d1, this.exchangeFilterForm.value.d2)
      .subscribe(result => {
        this.tableData = result;
      });
  }

  onCancel(id: number) {
    if (confirm('Bu amalni rostdan ham bajarmoqchimisiz?')) {
      this.mobileService.onCancel(id)
        .subscribe(x => {
          if (x.success === true) {
            this.getOrder();
          }
        });
    }
  }

  getOrderReport(): void {
    this.mobileService.getOrderReport(this.exchangeFilterForm.value.d1, this.exchangeFilterForm.value.d2)
      .subscribe(result => {
        this.rowDataReport = result;
      });
  }

  getDeliveryPaidCost(): void {
    this.tableData4 = this.mobileService.getDeliveryPaidCost(this.exchangeFilterForm.value.d1, this.exchangeFilterForm.value.d2);
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    param.api.sizeColumnsToFit();
  }

  openXl(content, card): void {
    this.cardData = card;
    this.totalCost = card.total_cost;
    this.products = card.products;
    for (let i = 0; i < this.products.length; i++) {
      this.products[i].sum = this.products[i].quantity * Number(this.products[i].cost);
    }
    this.modalService.open(content, {size: 'xl'});
  }

  onSaleAdd(modal): void {
    modal.dismiss('Cross click');
    this.onCancel(this.cardData.id);
    this.router.navigate(['/pos'], {state: [this.products, this.cardData.full_name]});
  }

  onRevertProducts(modal, value: string): void {
    if (confirm('Maxsulotlarni rostdan ham qaytarmoqchimiz?')) {
      modal.dismiss('Cross click');
      // this.onCancel(this.cardData.id);
      this.products.forEach(i => {
        i.bt = 0;
        i.item_id = i.product_id;
        i.sum_bt = i.sum;
        i.type = 'aniq_emas';
      });
      this.inventoryService.postInvoice(
        this.cardData.branch_id,
        this.products,
        Number(this.selected),
        this.cardData.cr_on,
        1,
        1,
        Number(value),
        this.cardData.user_name,
        'Mahsulotlarni qaytarish',
      )
        .subscribe(result => {
          // Kassadan ayirish
          const data = {
            customer: Number(this.selected),
            operationType: 2,
            currency: 1,
            cost: Number(value),
            comment: 'Qaytarish uchun berildi'
          };
          this.mobileService.subtractCashier(data)
            .subscribe( apple => {
              }
            );
          // Cancel order
          this.mobileService.onCancel(this.cardData.id)
            .subscribe(x => {
              if (x.success === true) {
                this.getOrder();
              }
            });
        });
      // Cancel order
    }
  }

  addIncome(): void {
    const selectedRow = this.gridApi.getSelectedRows()[0];
    if (this.gridApi.getSelectedRows().length > 0) {
      if (confirm('Tanlangan amaliyotni kassaga qoshmoqchimisiz ?')) {
        this.cashierService.postCashierOperation2(
          selectedRow.customer_id,
          1,
          1,
          selectedRow.paid_cost,
          'Yetkazib beruvchi olib keldi'
        ).subscribe(result2 => {
          if (result2.message === '1') {
            this.cashierService.updatePaidCostStatus(selectedRow.id)
              .pipe(take(1))
              .subscribe(r => {
                this.getDeliveryPaidCost();
              });
          }
        });
      }
    } else {
      alert('Jadvaldan tanlang');
    }
  }

  onSubmitExchangeFilter() {
    if (this.exchangeFilterForm.valid) {
      this.getOrderAll();
      this.getOrderReport();
      this.getDeliveryPaidCost();
    }
  }
}
