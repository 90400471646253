import {Component, Inject, OnInit, Optional} from '@angular/core';
import {CurrencyCellRendererDollar, CurrencyCellRendererQuantity} from '../../../../test/table-currency';
import {InventoryService} from '../../../inventory/inventory.service';
import {FormBuilder} from '@angular/forms';
import {CashierService} from '../../cashier.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';

@Component({
  selector: 'app-client-report',
  templateUrl: './client-report.component.html',
  styleUrls: ['./client-report.component.css']
})
export class ClientReportComponent implements OnInit {

  rowData3: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  isLoading = false;
  public modules: Module[] = AllModules;

  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ClientReportComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.defaultColDef = {resizable: true};
  }

  columnDefs1 = [ // Transaction table
    {headerName: 'Sana', field: 'date', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Kompaniya', field: 'client_name', sortable: true, filter: 'agTextColumnFilter', width: 200},
    {headerName: 'Turi', field: 'type', sortable: true, filter: 'agTextColumnFilter', width: 100},
    {headerName: 'Operatsiya', field: 'oper_type', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Sum', field: 'cost_sum', sortable: true, filter: 'agNumberColumnFilter',
      width: 150, valueFormatter: CurrencyCellRendererQuantity},
    {headerName: 'Dollar', field: 'cost_dollar', sortable: true, filter: 'agNumberColumnFilter',
      width: 150, valueFormatter: CurrencyCellRendererQuantity},
    {headerName: 'Hr', field: 'cost_hr', sortable: true, filter: 'agNumberColumnFilter', width: 150,
      valueFormatter: CurrencyCellRendererQuantity},
    {headerName: 'Hr dollar', field: 'cost_hr_dollar', sortable: true, filter: 'agNumberColumnFilter', width: 150,
      valueFormatter: CurrencyCellRendererQuantity},
    {headerName: 'Sum balans', field: 'balans_sum', sortable: true, filter: 'agNumberColumnFilter',
      width: 150, valueFormatter: CurrencyCellRendererQuantity},
    {headerName: 'Dollar balans', field: 'balans_dollar', sortable: true, filter: 'agNumberColumnFilter',
      width: 150, valueFormatter: CurrencyCellRendererDollar},
    {headerName: 'Hr balans', field: 'balans_hr', sortable: true, filter: 'agNumberColumnFilter',
      width: 150, valueFormatter: CurrencyCellRendererQuantity},
    {headerName: 'Hr dollar balans', field: 'balans_hr_dollar', sortable: true, filter: 'agNumberColumnFilter',
      width: 150, valueFormatter: CurrencyCellRendererQuantity},
    {headerName: 'Maxsulot', field: 'products', sortable: true, filter: 'agTextColumnFilter', width: 200},
    {headerName: 'User', field: 'user_name', sortable: true, filter: 'agTextColumnFilter', width: 100},
    {headerName: 'Izoh', field: 'comment', sortable: true, filter: 'agTextColumnFilter', width: 200}
  ];

  ngOnInit(): void {
    this.rowData3 = this.data;
  }

  cellStyling(params: any) {
    if (Number(params.data.quantity) < 100 && Number(params.data.quantity) >= 50) {
      return {'background-color': '#17A589; color: white;'};
    } else if (Number(params.data.quantity) < 50 && Number(params.data.quantity) > 10) {
      return {'background-color': '#e8c855; color: black;'};
    } else if (Number(params.data.quantity) <= 10) {
      return {'background-color': '#f08a90; color: white;'};
    }
  }

  toEval(data: string): any {
    return eval(data);
  }

  onPrint() {
    const printContent = document.getElementById('forPrint');
    let htmlPrint = '' +
      '<style>' +
      ':root:after { \n' +
      '         \n' +
      '            content: "SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD SBD"; \n' +
      '            position: fixed; \n' +
      '            color: rgb(187, 182, 182); \n' +
      '            top:0;                     \n' +
      '            z-index: -1; \n' +
      '        } ' +
      '.img-qrcode > img{' +
      ' width: 70px;' +
      'height: 70px;' +
      'margin-left: 20px;' +
      'margin-right: 20px;' +
      'font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;' +
      'font-size: 20px;' +
      'text-align: center;' +
      '}' +
      '#legalcopy > p {' +
      'text-align: center;' +
      '}' +
      '.info > h2, p {' +
      'text-align: center;' +
      '}' +
      'ngx-qrcode {' +
      'margin: auto;' +
      '}' +
      'code {' +
      'font-size: 16px' +
      '}' +
      'table th {' +
      'border-bottom: 0.5px solid #000;' +
      'padding: 2px;' +
      '}' +
      'table td {' +
      'border-bottom: 0.5px solid #000;' +
      'border-right: 0.5px solid #000;' +
      'padding: 2px' +
      '}' +
      '</style>';
    htmlPrint += printContent.outerHTML;
    const WindowPrt = window.open();
    WindowPrt.document.write(htmlPrint);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }
}
