import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SertificateAddComponent} from './sertificate-add/sertificate-add.component';
import {AuthGuard} from '../auth/auth.guard';

const routes: Routes = [
  {path: 'sarbon', component: SertificateAddComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
    ]
})
export class SarbonRoutingModule {

}
