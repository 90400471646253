import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InventoryService} from '../../inventory.service';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../../../system/client-create/user.service';

@Component({
  templateUrl: './warehouse-add.component.html',
  styleUrls: ['./warehouse-add.component.css']
})
export class WarehouseAddComponent implements OnInit {
  wForm: FormGroup;
  branches = [];
  id = 0;



  constructor(
    private fb: FormBuilder,
    private inventoryService: InventoryService,
    private toast: ToastrService,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    this.wForm = this.fb.group({
      branch: ['', Validators.required],
      name: ['', [Validators.required, Validators.maxLength(20)]],
      comment: ['', [Validators.required, Validators.maxLength(50)]]
    });
    if (window.history.state !== undefined) {
      const data = window.history.state;
      this.wForm.controls.branch.patchValue(Number(data.branch));
      this.wForm.controls.name.setValue(data.name);
      this.wForm.controls.comment.setValue(data.comment);
      this.id = data.id;
    } else {
      this.id = 0;
    }
    if (this.id === undefined) {
      this.id = 0;
    }

    this.userService.getBranchesByOwner()
      .subscribe(x => {
        this.branches = x;
      });
  }

  onSubmit() {
    if (this.wForm.valid) {
        this.inventoryService.postWarehouse(
          this.wForm.value.branch,
          this.wForm.value.name,
          this.wForm.value.comment,
          this.id
        ).subscribe(result => {
          if (result.message === '1') {
            this.wForm.reset();
          }
        });
    } else {
      this.toast.info('Hamma ma\'lumotlarni to\'ldiring');
    }
  }
}
