import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {ApplistModel} from '../app-list/applist.model';
import {ApplistService} from '../app-list/applist.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit, OnDestroy {
  userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  appList: ApplistModel[] = [];
  private appsSub: Subscription;

  constructor(private authService: AuthService, public applistService: ApplistService, private router: Router) {}

  ngOnInit(): void {
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authListenerSubs = this.authService.getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;

        this.applistService.getApps();
        this.appsSub = this.applistService.getAppListUpdateListener()
          .subscribe((appList: ApplistModel[]) => {
            this.appList = appList;
          });
      });
  }

  getURL(url: string) {
    this.router.navigate(['../' +  url +  '']);
  }

  ngOnDestroy(): void {
    this.authListenerSubs.unsubscribe();
  }

  onLogout() {
    this.authService.logout();
    this.appsSub.unsubscribe();
  }
}
