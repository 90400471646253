import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {Observable, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {UserService} from '../system/client-create/user.service';
import {HttpClient} from '@angular/common/http';
import {ProdService} from '../production/prod.service';
import {MatDialog} from '@angular/material/dialog';
import {NotificationListComponent} from '../notification/list/notification-list.component';
import {NotificationService} from '../notification/notification.service';
import {take} from "rxjs/operators";
import {CashierService} from "../crm/cashier/cashier.service";

interface Hotlink {
  id: number;
  name: string;
  url: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  opened = false;
  notificationQuantity: any = 0;
  balance = 'P';

  userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  private currencySubs: Subscription;
  private currencySubsSelf: Subscription;
  private notificationSubscription: Subscription;
  hotlink$: Observable<Hotlink[]>;
  push: any;
  fullName: string;
  userId: number;
  date: string;
  time: string;
  dollar: string;
  euro: string;
  rubl: string;
  funt: string;
  dollarSelf: string;
  branchInfo;

  constructor(
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
    private userService: UserService,
    private http: HttpClient,
    private prodService: ProdService,
    public dialog: MatDialog,
    private notification: NotificationService,
    private cashierService: CashierService
  ) {
    translate.setDefaultLang('uz');
  }

  changeLanguage(language: string) {
    this.translate.use(language);
  }

  getBranchInfo(): void {
    this.cashierService.getBranchInfo().pipe(take(1)).subscribe(r => {
      this.branchInfo = r[0].is_sale_open;
    });
  }

  ngOnInit(): void {
    this.getBranchInfo();
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.currencySubs = this.authService.getDollarSubs()
      .subscribe(result => {
        this.dollar = result[0].Rate;
        this.euro = result[1].Rate;
        this.rubl = result[2].Rate;
        this.funt = result[3].Rate;
      });

    this.currencySubsSelf = this.authService.getDollarSubsSelf()
      .subscribe(result => {
        this.dollarSelf = result;
        this.getBranchInfo();
      });
    setInterval(x => {
      this.currencySubsSelf = this.authService.getDollarSubsSelf()
        .subscribe(result => {
          this.dollarSelf = result;
        });

      if (this.userIsAuthenticated === true) {
        this.authService.checkWarning().subscribe(r => {
          this.balance = r.warning_status;
        });
      }
    } , 60000);

    this.authListenerSubs = this.authService.getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.fullName = this.authService.getFullName();
        this.userId = this.authService.getUserId();

        if (this.userIsAuthenticated === true) {
          // BehaviourSubject for getting notifications
           this.notificationSubscription = this.notification.cast.subscribe(x11 => {
            this.notification.getNotification()
              .subscribe((x1: any) => {
                this.notificationQuantity = x1;
              });
          });
        }
      });
    setInterval(() => {
      const currentDate = new Date();
      this.date = currentDate.toDateString();
      this.time = currentDate.toTimeString().substring(0, 8);
    }, 1000);
  }

  ngOnDestroy(): void {
    this.authListenerSubs.unsubscribe();
    this.currencySubs.unsubscribe();
    this.currencySubsSelf.unsubscribe();
    this.notificationSubscription.unsubscribe();
  }

  goProfile() {
    this.router.navigate(['./system/profile']);
  }

  onLogout() {
    this.authService.logout();
  }

  getHotlink(url: string) {
    this.router.navigate(['./' + url]);
  }

  onClick() {
    this.hotlink$ = this.prodService.getHotLinks();
  }

  onNotificationList() {
    const dialogRef = this.dialog.open(NotificationListComponent, {
      panelClass: ['animate__animated', 'animate__slideInRight'],
      width: '30%',
      height: '100%',
      position: {right: '0'}
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.ngOnInit();
    });
  }

  onSBD() {
    this.router.navigate(['../']);
  }
}
