import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "src/app/auth/auth.service";
import { UserService } from "src/app/system/client-create/user.service";
import { InstallmentService } from "../../installment.service";

@Component({
  selector: "app-contract-form",
  templateUrl: "./contract-form.component.html",
  styleUrls: ["./contract-form.component.css"],
})
export class ContractFormComponent implements OnInit {
  @Input() patent;
  @Input() ownerName;

  @Input() clientName;
  @Input() products;
  @Input() totalCost;
  @Input() withAdditional;
  @Input() graph;
  @Input() initialPayment;
  @Input() currentDate;
  @Input() percent;
  @Input() duration;
  @Input() division;
  @Input() productCount;
  @Input() today;
  @Input() saleId;

  userName: string;
  isLoading = true;

  constructor(
    private insService: InstallmentService,
    private userService: UserService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.userService
      .getUserInfo(this.authService.getUserId())
      .subscribe((result: any) => {
        this.userName =
          result.first_name + " " + result.last_name + " " + result.father_name;
        this.isLoading = false;
      });
  }

  onPrint() {
    const printContent = document.getElementById("forPrint");
    let htmlPrint = "" + "<style>" + "margin: 300px" + "</style>";
    htmlPrint += printContent.outerHTML;
    const WindowPrt = window.open();
    WindowPrt.document.write(htmlPrint);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }
}
