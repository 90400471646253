import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {HttpClient} from '@angular/common/http';
import {InventoryService} from '../../inventory.service';
import {MatDialog} from '@angular/material/dialog';
import {InvoicePListComponent} from './invoice-product-list/invoice-p-list.component';

@Component({
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent implements OnInit {

  rowData: any;
  rowDataCurrency: any;
  rowDataType: any;
  rowDataWarehouse: any;
  rowDataIP: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  defaultColDefCurrency;
  defaultColDefType;
  isLoading = false;
  public modules: Module[] = AllModules;

  constructor(
    private http: HttpClient,
    private router: Router,
    private invoiceService: InventoryService,
    private dialog: MatDialog
  ) {
    this.defaultColDef = { resizable: true };
    this.defaultColDefCurrency = { resizable: true };
    this.defaultColDefType = { resizable: true };
  }

  columnDefs = [
    {headerName: 'Sana', field: 'date', sortable: true, filter: 'agTextColumnFilter', width: 100 },
    {headerName: 'Kompaniya', field: 'company', sortable: true, filter: 'agTextColumnFilter', width: 300 },
    {headerName: 'Invoice nomi', field: 'invoice_name', sortable: true, filter: 'agTextColumnFilter', width: 150 },
    {headerName: 'Operatsiya turi', field: 'oper_type', sortable: true, filter: 'agTextColumnFilter', width: 100 },
    {headerName: 'Valyuta', field: 'currency', sortable: true, filter: 'agTextColumnFilter', width: 100 },
    {headerName: 'Sum', field: 'cost', sortable: true, filter: 'agNumberColumnFilter',
      cellRenderer: this.CurrencyCellRendererDollar, width: 150 },
    {headerName: 'Ishchi', field: 'cr_by', sortable: true, filter: true, width: 150 },
    {headerName: 'Izox', field: 'comment', sortable: true }
  ];

  columnDefsCurrency = [
    {headerName: 'Nomi', field: 'name', sortable: true, filter: 'agTextColumnFilter' },
    {headerName: 'Izox', field: 'comment', sortable: true}
  ];

  columnDefsType = [
    {headerName: '#', field: 'id', sortable: true, filter: 'agTextColumnFilter' },
    {headerName: 'Nomi', field: 'name', sortable: true, filter: 'agTextColumnFilter' },
    {headerName: 'Birlik', field: 'unit', sortable: true, filter: 'agTextColumnFilter' },
    {headerName: 'Sana', field: 'cr_on', sortable: true, filter: 'agTextColumnFilter', width: 100 },
  ];
  columnDefsWarehouse = [
    {headerName: '#', field: 'id', sortable: true, filter: 'agTextColumnFilter'},
    {headerName: 'Nomi', field: 'name', sortable: true, filter: 'agTextColumnFilter', checkboxSelection: true },
    {headerName: 'Izox', field: 'comment' },
    {headerName: 'Xolati', field: 'status', sortable: true},
  ];

  columnDefsIP = [
    {headerName: 'Barcode', field: 'barcode', sortable: true, checkboxSelection: true, width: 100},
    {headerName: 'Turi', field: 'type', sortable: true, width: 100},
    {headerName: 'Miqdori', field: 'quantity', sortable: true, width: 100, cellRenderer: this.CurrencyCellRendererDollar},
    {headerName: 'Narxi', field: 'cost', sortable: true, width: 100, cellRenderer: this.CurrencyCellRendererDollar},
    {headerName: 'Summa', field: 'sum', sortable: true, width: 100, cellRenderer: this.CurrencyCellRendererDollar},
    {headerName: '%', field: 'bt', sortable: true, width: 100, cellRenderer: this.CurrencyCellRendererDollar},
    {headerName: '% summa', field: 'sum_bt', sortable: true, width: 100, cellRenderer: this.CurrencyCellRendererDollar},
  ];

  private CurrencyCellRendererDollar(params: any) {
    const usdFormate = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0
    });
    return usdFormate.format(params.value);
  }


  ngOnInit(): void {
    this.isLoading = true;
    this.rowData = this.invoiceService.getInvoice();
    this.rowDataCurrency = this.invoiceService.getCurrency();
    this.rowDataType = this.invoiceService.getType();
    this.rowDataWarehouse = this.invoiceService.getWarehouse();
    if (this.rowData !== undefined) {
      this.isLoading = false;
    }
  }

  // Currency renderer functions
  currencyFormatter(params) {
    return Math.floor(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  openDialog() {
    this.router.navigate(['../../inventory/invoice/add']);
  }

  onRowDoubleClick(rowData: any) {
    const dialogRef = this.dialog.open(InvoicePListComponent, {
      width: '70%',
      height: '100%',
      position: {right: '0'},
      data: rowData
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  addType() {
    this.router.navigate(['../../inventory/type/add']);
  }

  addWarehouse() {
    this.router.navigate(['../../inventory/warehouse/add']);
  }

  goBack() {
    this.router.navigate(['inventory']);
  }

}
