import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { AppRoutingModule } from "./app-routing.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { AuthInterceptor } from "./auth/auth-interceptor";
import { ErrorInterceptor } from "./error-interceptor";
import { MatDialogModule } from "@angular/material/dialog";
import { ErrorComponent } from "./error/error.component";
import { ApplistComponent } from "./app-list/applist.component";
import { AngularMaterialModule } from "./angular-material.module";
import { PostModule } from "./posts/post.module";
import { MatIconModule } from "@angular/material/icon";
import { MatGridListModule } from "@angular/material/grid-list";
import { HrListComponent } from "./hr/hr-list/hr-list.component";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { AgGridModule } from "ag-grid-angular";
import { TestComponent } from "./test/test.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HrCreateComponent } from "./hr/hr-create/hr-create.component";
import { AuthModule } from "./auth/auth.module";
import { NotifyInterceptor } from "./NotifyInterceptor";
import { MatMenuModule } from "@angular/material/menu";
import { CrmModule } from "./crm/crm.module";
import { MatButtonModule } from "@angular/material/button";
import { MatSidenavModule } from "@angular/material/sidenav";
import { SideNavComponent } from "./side-nav/side-nav.component";
import { MatListModule } from "@angular/material/list";
import { InventoryAddComponent } from "./crm/inventory/inventory-add/inventory-add.component";
import { SuccessComponent } from "./success/success.component";
import { ProdModule } from "./production/prod.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSelectModule } from "@angular/material/select";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SystemModule } from "./system/system.module";
import { CustomerAddComponent } from "./crm/pos/customer-add/customer-add.component";
import { NotificationModule } from "./notification/notification.module";
import { ToastrModule } from "ngx-toastr";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { PermissionComponent } from "./system/permissions/permission.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatBadgeModule } from "@angular/material/badge";
import { SarbonModule } from "./test/sarbon.module";
import { MobileModule } from "./crm/Mobile/mobile.module";
import { InstallmentModule } from "./installment/installment.module";
import { InsWindowComponent } from "./installment/installment-apps/installment-sale/installment-window/ins-window.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { HrOperationsComponent } from "./hr/operations/hr-operations.component";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { NgxBarcode6Module } from "ngx-barcode6";
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ErrorComponent,
    ApplistComponent,
    HrListComponent,
    TestComponent,
    HrCreateComponent,
    HrOperationsComponent,
    SideNavComponent,
    SuccessComponent,
    PermissionComponent,
  ],
  imports: [
    MobileModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatDialogModule,
    NgxQRCodeModule,
    AngularMaterialModule,
    PostModule,
    AuthModule,
    CrmModule,
    InstallmentModule,
    SarbonModule,
    ProdModule,
    SystemModule,
    NotificationModule,
    MatIconModule,
    MatGridListModule,
    MatTableModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatSortModule,
    AgGridModule.withComponents([]),
    FormsModule,
    MatMenuModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    NgxBarcode6Module,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    NgxMaskModule.forRoot(),
    MatSlideToggleModule,
    MatBadgeModule,
    NgbModule,
    MatDatepickerModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NotifyInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  exports: [],
  entryComponents: [
    ErrorComponent,
    InventoryAddComponent,
    SuccessComponent,
    CustomerAddComponent,
    InsWindowComponent,
  ],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
