import {NgModule} from '@angular/core';
import {UserCreateComponent} from './client-create/user-create/user-create.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {SystemRoutingModule} from './system-routing.module';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ProfileComponent} from './client-create/user-profile/profile.component';
import {StoreModule} from '@ngrx/store';
import {shoopingListReducer} from './store/shopping-list.reducer';
import { TableComponent } from './table/table.component';
import { InventoryPermissionComponent } from './inventory-permission/inventory-permission.component';

@NgModule({
  declarations: [
    UserCreateComponent,
    ProfileComponent,
    TableComponent,
    InventoryPermissionComponent
  ],
  imports: [
    SystemRoutingModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    StoreModule.forRoot({shoopingList: shoopingListReducer})
  ],
  exports: []
})
export class SystemModule {}
