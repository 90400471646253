import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {InventoryService} from '../../inventory.service';
import {UserService} from '../../../../system/client-create/user.service';
import {ToastrService} from "ngx-toastr";

@Component({
  templateUrl: './company-add.component.html'
})
export class CompanyAddComponent implements OnInit {
  app: any;
  companyForm: FormGroup;
  checked = false;
  id = 0;
  gender: any[] = [
    {id: 1, name: 'Supplier'},
    {id: 2, name: 'Customer'}
  ];
  branches = [];

  constructor(
    private inventoryService: InventoryService,
    private userService: UserService,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {
    this.getBranchList();
    this.companyForm = new FormGroup({
      branch: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required, Validators.maxLength(40)]),
      email: new FormControl('isbd.uz@gmail.com', [Validators.required, Validators.maxLength(40), Validators.email]),
      comment: new FormControl('Izoh qoldiring!', [Validators.required, Validators.maxLength(100)]),
      isOpen: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      phone: new FormControl(null, [Validators.required, Validators.maxLength(20)]),
      location: new FormControl('40.63513827765001, 71.08016689633445', [Validators.required, Validators.maxLength(99)])
    });
    if (window.history.state.alarm !== undefined) {
      this.companyForm.controls.branch.setValue(window.history.state.alarm.branch);
      this.companyForm.controls.name.setValue(window.history.state.alarm.name);
      this.companyForm.controls.email.setValue(window.history.state.alarm.email);
      this.companyForm.controls.phone.setValue(window.history.state.alarm.phone_number);
      this.companyForm.controls.location.setValue(window.history.state.alarm.location);
      this.companyForm.controls.comment.setValue(window.history.state.alarm.comment);
      let comType = 'suplier';
      if (comType === window.history.state.alarm.type) {
        comType = '1';
      } else {
        comType = '2';
      }
      this.companyForm.controls.type.patchValue(Number(comType));
      if (window.history.state.alarm.is_open === 'open') {
        this.companyForm.controls.isOpen.setValue(true);
        this.checked = true;
      } else {
        this.companyForm.controls.isOpen.setValue(false);
        this.checked = false;
      }
    }
    if (window.history.state.alarm !== undefined) {
      this.id = window.history.state.alarm.id;
    }
  }

  getBranchList(): void {
    this.userService.getBranchesByOwner()
      .subscribe(x => {
        this.branches = x;
      });
  }
  onSubmit() {
    if (this.companyForm.valid) {
      this.inventoryService.postCompany(
        this.companyForm.value.branch,
        this.companyForm.value.name,
        this.companyForm.value.email,
        this.companyForm.value.comment,
        this.companyForm.value.isOpen,
        this.id,
        this.companyForm.value.type,
        this.companyForm.value.phone,
        this.companyForm.value.location
      ).subscribe(result => {
        if (result.message === '1') {
          this.companyForm.reset();
        }
      });
    } else {
      this.toast.info('Hamma ma\'lumotlarni to\'ldiring');
    }
  }
}
