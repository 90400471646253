import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith, take} from 'rxjs/operators';
import {InventoryService} from '../../crm/inventory/inventory.service';
import {ProdService} from '../prod.service';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {Router} from '@angular/router';

interface Product {
  id: number;
  barcode: string;
  name: string;
  quantity: number;
}

interface Line {
  id: number;
  name: string;
}

interface Layout {
  barcode: string;
  name: string;
  quantity: number;
}

@Component({
  templateUrl: './production.component.html',
  styleUrls: ['./production.component.css']
})
export class ProductionComponent implements OnInit {

  rowData: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  public modules: Module[] = AllModules;
  myControl = new FormControl();
  filteredOptions: Observable<Product[]>;
  prodForm: FormGroup;
  lines: Line[] = [];
  options: Product[] = [];
  layouts: Layout[] = [];
  isActive = true;
  prodQuantity = 0;
  filterForm: FormGroup;
  date1 = '';
  date2 = '';
  today: Date;
  daysBefore: Date;
  type = [];
  isLoading = false;

  constructor(
    private inventoryService: InventoryService,
    private prodService: ProdService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.defaultColDef = {resizable: true};
  }

  @ViewChild('name', {static: true}) name: ElementRef;

  columnDefs = [
    {headerName: 'Date', field: 'cr_on', sortable: true, filter: 'agTextColumnFilter', width: 200},
    {
      headerName: 'Barcode',
      field: 'barcode',
      sortable: true,
      width: 200,
      filter: 'agTextColumnFilter',
      checkboxSelection: true
    },
    {headerName: 'Liniya', field: 'line', sortable: true, filter: 'agTextColumnFilter'},
    {headerName: 'Product', field: 'product_name_code', sortable: true, rowGroup: true, hide: true, width: 500},
    {
      headerName: 'Product quantity',
      field: 'p_quantity',
      sortable: true,
      filter: 'agNumberColumnFilter',
      aggFunc: 'avg'
    },
    {headerName: 'Product name', field: 'product_name', sortable: true, filter: 'agTextColumnFilter'},
    {headerName: 'Hom ashiyo nomi', field: 'rm_name', sortable: true, filter: 'agTextColumnFilter'},
    {headerName: 'Hom ashiyo barkodi', field: 'rm_barcode', sortable: true, filter: 'agTextColumnFilter'},
    {headerName: 'Quantity', field: 'quantity', sortable: true, filter: 'agNumberColumnFilter', aggFunc: 'sum'},
  ];

  ngOnInit(): void {
    this.getProdHistory();
    this.getType();
  }

  getType(): any {
    this.inventoryService.getType()
      .subscribe(
        result => {
          this.type = result;
        }
      );
  }

  getProdHistory(): void {
    this.date1 = localStorage.getItem('pd1');
    this.date2 = localStorage.getItem('pd2');
    this.initFilterForm();
    const app = {
      d1: localStorage.getItem('pd1'),
      d2: localStorage.getItem('pd2'),
    };
    const a = new Date();
    this.daysBefore = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    this.today = a;
    this.prodForm = new FormGroup({
      line: new FormControl(null, Validators.required),
      crOn: new FormControl(a, Validators.required),
      id: new FormControl(null, Validators.required),
      barcode: new FormControl(null, Validators.required),
      quantity: new FormControl(1, Validators.required),
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    );
    this.prodService.getLine()
      .subscribe((result: Line[]) => {
        this.lines = result;
      });
    // getting  production history
    this.rowData = this.prodService.getProdHistory(a, a, app, true, 'pd1', 'pd2', 'crm/production/history/get', 1);
  }

  filter(val: any): Product[] {
    return this.options.filter((item: any) => {
      // If the user selects an option, the value becomes a Human object,
      // therefore we need to reset the val for the filter because an
      // object cannot be used in this toLowerCase filter
      if (typeof val === 'object') {
        val = '';
      } else {
        const TempString = item.name + item.barcode;
        return TempString.toLowerCase().includes(val.toLowerCase());
      }
    });
  }

  displayFn(item: any): any {
    // tslint:disable-next-line:triple-equals
    if (item == undefined) {
      return;
    }
    return item.name;
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    param.api.sizeColumnsToFit();
  }

  onBtnExport() {
    this.gridApi.exportDataAsExcel();
  }

  // Setting barcode and id
  setBarcode(id: any) {
    this.prodForm.controls.barcode.setValue(id.barcode);
    this.prodForm.controls.id.setValue(id.id);
    this.prodService.getProductLayout(id.id)
      .subscribe((result: Layout[]) => {
        this.layouts = result;
      });
    this.inventoryService.getProduct(id.id)
      .subscribe(x => {
        this.prodQuantity = x[0].quantity;
      });
  }

  // Submit form
  onSubmit() {
    this.isActive = false;
    this.prodService.postProductLayout(
      this.prodForm.value.line,
      this.prodForm.value.crOn,
      this.prodForm.value.id,
      this.prodForm.value.quantity,
    ).subscribe(result => {
      this.isActive = true;
      this.prodForm.controls.id.reset();
      this.prodForm.controls.barcode.reset();
      this.prodForm.controls.quantity.reset();
      this.layouts = [];
      this.myControl.setValue({name: ''});
      this.ngOnInit();
    });
  }

  onClick() {
    this.router.navigate(['./production/constructor']);
  }

  onSelected(value: any) {
    this.prodService.getProductLayoutProducts(value)
      .subscribe((result: Product[]) => {
        this.options = result;
      });
  }

  initFilterForm(): void {
    this.filterForm = this.fb.group({
      s_date: [Validators.required],
      e_date: [Validators.required]
    });
  }

  onFilterSubmit(): void {
    if (this.filterForm.valid) {
      const app = {
        d1: localStorage.getItem('pd1'),
        d2: localStorage.getItem('pd2'),
      };
      this.rowData = this.prodService.getProdHistory(this.filterForm.value.s_date,
        this.filterForm.value.e_date, app, false, 'pd1', 'pd2', 'crm/production/history/get', 1);
      this.date1 = localStorage.getItem('pd1');
      this.date2 = localStorage.getItem('pd2');
    }
  }


  productionReturn(): void {
    const selectedRow = this.gridApi.getSelectedRows()[0];
    if (selectedRow.id > 0) {
      if (confirm('Rostdan ham qaytarmoqchimisiz?')) {
        this.prodService.deleteProduction(selectedRow.id).pipe(take(1))
          .subscribe(r => {
            if (r.message === 1) {
              this.getProdHistory();
            }
          });
      }
    } else {
      alert('Jadvaldan tanlang!');
    }
  }

  onSelectedType(id: number): void {
    this.getInventoryByType(id);
  }

  getInventoryByType(id: number): void {
    this.isLoading = true;
    this.prodService.getProductLayoutProductsByType(id).pipe(take(1))
      .subscribe((result: Product[]) => {
        this.options = result;
        this.isLoading = false;
      });
  }
}
