import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import { tap } from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class NotifyInterceptor implements HttpInterceptor {
  constructor(private dialog: MatDialog, private toastr: ToastrService) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status === 201) {
          this.toastr.success('Muvaffaqiyatli saqlandi', 'Bildirishnoma', {
            timeOut: 3000,
            progressBar: false
          });
        } else if (event instanceof HttpResponse && event.status === 204) {
          // alert('Successfully deleted!');
          // console.log('Nimadir chiqishi kerak shu yerda!');
        }
      })
    );
  }
}
