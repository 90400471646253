import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {CurrencyCellRendererQuantity, CurrencyCellRendererSum} from '../../test/table-currency';
import {HrService} from '../hr.service';
import {Store} from '@ngrx/store';
import {Ingredient} from '../../system/store/shopping-list.reducer';
import {Observable} from 'rxjs';

@Component({
  templateUrl: 'hr-list.component.html',
  styleUrls: ['hr-list.component.css']
})
export class HrListComponent implements OnInit {
  ingredients: Observable<{ ingredients: Ingredient[] }>;

  constructor(
    private hrService: HrService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    // private store: Store<{shoopingList: {ingredients: Ingredient[]}}>
  ) {
  }
  isLoading = false;
  rowData: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  isVisible: any;
  public modules: Module[] = AllModules;

  columnDefs = [
    {headerName: 'Ism', field: 'full_name', sortable: true, filter: 'agTextColumnFilter', width: 150, checkboxSelection: true},
    {headerName: 'Telefon', field: 'phone_number', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Email', field: 'email', sortable: true, width: 200, filter: 'agTextColumnFilter'},
    {headerName: 'Tugulgan sana', field: 'birth_date', sortable: true, width: 100, filter: 'agTextColumnFilter'},
    {headerName: 'Maosh', field: 'salary', sortable: true, width: 150, cellRenderer: CurrencyCellRendererQuantity},
    {headerName: 'Hozirgi oy uchun olingan', field: 'current_month_sum', sortable: true, width: 150,
      cellRenderer: CurrencyCellRendererQuantity, cellStyle: this.currentMonthSumStyling },
    {headerName: 'Ayirma', field: 'subtraction', sortable: true, width: 150,
      cellRenderer: CurrencyCellRendererQuantity, cellStyle: this.cellStyling},
    {headerName: 'Qoldiq', field: 'saldo', sortable: true, width: 150, cellRenderer: CurrencyCellRendererQuantity,
      cellStyle: this.saldoStyling},
  ];

  ngOnInit(): void {
    // this.ingredients = this.store.select('shoopingList');
    this.rowData = this.hrService.getHrUsers();
  }

  onDelete() {
    const selectedRow = this.gridApi.getSelectedRows()[0].id;
    if (selectedRow > 0) {
      if (confirm('Rostdan ochirishni xoxlaysizmi?')) {
        this.hrService.deleteUser(selectedRow)
          .subscribe(x => {
            if (x.message === 1) {
              this.rowData = this.hrService.getHrUsers();
            }
          });
      }
    }
  }

  onReady(param: any): void {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    param.api.sizeColumnsToFit();
  }

  cellStyling(params: any) {
    if (params.data.subtraction >= 0) {
      return {backgroundColor: '#17A589', color: 'white'};
    } else {
      return {backgroundColor: 'red', color: 'white'};
    }
  }

  saldoStyling(params: any) {
    if (params.data.saldo >= 0) {
      return {backgroundColor: '#17A589', color: 'white'};
    } else {
      return {backgroundColor: 'red', color: 'white'};
    }
  }

  currentMonthSumStyling(params: any) {
    if (params.data.current_month_sum >= 0) {
      return {backgroundColor: '#17A589', color: 'white'};
    } else {
      return {backgroundColor: 'red', color: 'white'};
    }
  }

  onChange(): void {
      const selectedRow = this.gridApi.getSelectedRows()[0].id;
      if (selectedRow > 0 && confirm('Ayirmani qoldiq bilan birlashtirasizmi?')) {
        this.hrService.salaryCalculate(selectedRow)
          .subscribe(result => {
            if (result.message === 1) {
              this.rowData = this.hrService.getHrUsers();
            }
          });
      } else {
        alert('Jadvaldan tanlang!');
      }
  }

  onUpdate() {
    if (this.gridApi.getSelectedRows()[0].id > 0) {
      const selectedRow = this.gridApi.getSelectedRows()[0];
      this.router.navigate(['/hr/add'], { state: {alarm: selectedRow}});
    } else {
      alert('Hodim tanlang!');
    }
  }
}
