

export function dateParser(date1: Date): string {
  const date = new Date(date1);
  const dd1 = String(date.getDate()).padStart(2, '0');
  const mm1 = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy1 = date.getFullYear();
  return yyyy1 + '-' + mm1 + '-' + dd1;
}

export function dateParserStringWithDots(date1: string): string {
  const dd1 = date1.substring(0, 2);
  const mm1 = date1.substring(3, 5); // January is 0!
  const yyyy1 = date1.substring(6, 10);
  return yyyy1 + '-' + mm1 + '-' + dd1;
}

interface BasketArr {
  name: string;
  value: string;
}

export const BasketArr1: BasketArr[]  = [
  {
    name: 'quantity1',
    value: 'quantity'
  },
  {
    name: 'cost1',
    value: 'cost'
  },
  {
    name: 'currency1',
    value: 'currency'
  },
  {
    name: 'barcode1',
    value: 'barcode'
  },
  {
    name: 'id1',
    value: 'product_id'
  },
  {
    name: 'name1',
    value: 'name'
  },
  {
    name: 'dona1',
    value: 'dona'
  },
  {
    name: 'unit1',
    value: 'unit'
  }];
