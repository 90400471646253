import {Component, OnInit} from '@angular/core';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {CashierService} from '../cashier.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  templateUrl: './cashier-balance-h.component.html',
  styleUrls: ['./cashier-balance-h.component.css']
})
export class CashierBalanceHComponent implements OnInit {

  balanceHistoryForm: FormGroup;
  rowData: any;
  gridApi;
  defaultColDef;
  public modules: Module[] = AllModules;
  company: any = [];

  constructor(
    private cashierService: CashierService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.defaultColDef = {resizable: true};
  }

  columnDefs2 = [ // Balance table
    {headerName: 'Sana', field: 'cr_on', sortable: true, filter: 'agTextColumnFilter', resizable: true, width: 100},
    {headerName: 'Company', field: 'name', sortable: true, filter: 'agTextColumnFilter', resizable: true,
      width: 400, checkboxSelection: true},
    {headerName: 'Sum', field: 'sum', sortable: true, width: 150, resizable: true, cellRenderer: this.CurrencyCellRendererSum
      , cellStyle: this.cellStyling},
    {headerName: 'Hr', field: 'hr', sortable: true, width: 150, resizable: true, cellRenderer: this.CurrencyCellRendererSum
      , cellStyle: this.cellStylingHr},
    {
      headerName: 'Dollar', field: 'dollar', sortable: true, width: 150,
      resizable: true, cellRenderer: this.CurrencyCellRendererDollar, cellStyle: this.cellStylinghrDollar
    }
  ];

  ngOnInit(): void {

    this.cashierService.getBalance()
      .subscribe(x => {
        console.log(x);
        this.company = x;
      });

    this.balanceHistoryForm = this.fb.group({
      id: [''],
      date1: ['', Validators.required],
      date2: ['', Validators.required]
    });
  }

  currencyFormatter(params) {
    // return '' + this.formatNumber(params.value);
    return Math.floor(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  private CurrencyCellRendererSum(params: any) {

    const usdFormate = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'UZS',
      minimumFractionDigits: 0
    });
    return usdFormate.format(params.value);
  }

  private CurrencyCellRendererDollar(params: any) {

    const usdFormate = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0
    });
    return usdFormate.format(params.value);
  }

  cellStyling(params: any) {
    if (params.data.sum < 0) {
      return {'background-color': '#f08a90; color: white;'};
    } else if (params.data.sum > 0) {
      return {'background-color': '#28a745; color: white;'};
    }
  }
  cellStylingHr(params: any) {
    if (params.data.hr < 0) {
      return {'background-color': '#f08a90; color: white;'};
    } else if (params.data.hr > 0) {
      return {'background-color': '#28a745; color: white;'};
    }
  }

  cellStylinghrDollar(params: any) {
    if (params.data.dollar < 0) {
      return {'background-color': '#f08a90; color: white;'};
    } else if (params.data.dollar > 0) {
      return {'background-color': '#28a745; color: white;'};
    }
  }

  onSubmit() {
    if (this.balanceHistoryForm.valid) {
      this.rowData = this.cashierService.getCashierBalanceHistory(
        this.balanceHistoryForm.value.id,
        this.balanceHistoryForm.value.date1,
        this.balanceHistoryForm.value.date2,
      );
    } else {
      this.toastr.info('Sanani tanlang!');
    }
  }
}
