import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {InstallmentService} from '../../installment.service';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {CurrencyCellRendererSum} from '../../../../test/table-currency';
import {HttpClient} from '@angular/common/http';
import {take} from "rxjs/operators";

@Component({
  templateUrl: "./ins-sale-list.html",
  styleUrls: ["./ins-sale-list.css"],
})
export class InsSaleListComponent implements OnInit {
  rowData: any = [];
  closeResult: string;
  products = [];
  gridApi;
  gridColumnApi;
  defaultColDef;
  public modules: Module[] = AllModules;
  graph = [];
  payments = [];

  constructor(
    private router: Router,
    private insService: InstallmentService,
    private modalService: NgbModal,
    private toast: ToastrService,
    private http: HttpClient
  ) {
    this.defaultColDef = { resizable: true };
  }

  columnDefs = [
    {
      headerName: "Sana",
      field: "cr_on",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 100,
    },
    {
      headerName: "Filial",
      field: "branch_name",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Toliq ism",
      field: "customer_name",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 200,
      checkboxSelection: true,
    },
    {
      headerName: "Valyuta",
      field: "currency",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 50,
    },
    {
      headerName: "Asl summa",
      field: "org_sum",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 150,
      valueFormatter: this.currencyFormatter,
    },
    {
      headerName: "Qoshimcha bilan",
      field: "with_add",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 150,
      valueFormatter: this.currencyFormatter,
    },
    {
      headerName: "Boshlangich tolov",
      field: "initial_pay",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 150,
      valueFormatter: this.currencyFormatter,
    },
    {
      headerName: "Qolgani",
      field: "debt_amount",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 150,
      valueFormatter: this.currencyFormatter,
    },
    {
      headerName: "Oy",
      field: "month",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 80,
    },
    {
      headerName: "Kafil",
      field: "guarantor",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Foydalanuvchi",
      field: "user_name",
      sortable: true,
      filter: "agNumberColumnFilter",
    },
  ];

  currencyFormatter(params) {
    // return '' + this.formatNumber(params.value);
    return Math.floor(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  ngOnInit(): void {
    this.rowData = this.insService.getInsSaleList();
  }

  onGridReady({ api }) {
    this.gridApi = api;
    this.gridApi.sizeColumnsToFit();
  }

  onRowDoubleClick() {}

  open(content, id: number) {
    if (this.gridApi.getSelectedRows().length > 0) {
      this.products = this.gridApi.getSelectedRows()[0].products;
      if (id === 1) {
        this.insService
          .getPaymentGraph(this.gridApi.getSelectedRows()[0].id)
          .subscribe((x) => {
            this.graph = x;
            this.modalService.open(content, { size: "xl" }).result.then(
              (result) => {
                this.closeResult = `Closed with: ${result}`;
              },
              (reason) => {
                // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
              }
            );
          });
      } else if (id === 0) {
        this.modalService.open(content, { size: "xl" }).result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
      } else {
        this.insService.getDayPayments().subscribe((x) => {
          this.payments = x;
        });

        this.modalService.open(content, { size: "xl" }).result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
      }
    } else {
      this.toast.info("Jadvaldan qatorni tanlang!");
    }
  }

  print() {
    window.print();
  }

  onOper(): void {
    if (this.gridApi.getSelectedRows().length > 0) {
      const id = this.gridApi.getSelectedRows()[0];
      this.router.navigate(["/installment/oper-list"], { state: id });
    } else {
      this.toast.info("Jadvaldan qatorni tanlang!");
    }
  }

  sendSMS(): void {
    if (this.gridApi.getSelectedRows().length > 0) {
      const id = this.gridApi.getSelectedRows()[0];
      if (confirm("Rostan SMS jonatmoqchimisiz?")) {
      }
    } else {
      this.toast.info("Jadvaldan qatorni tanlang!");
    }
  }

  onDeleteSale(): void {
    if (this.gridApi.getSelectedRows().length > 0) {
      const id = this.gridApi.getSelectedRows()[0].id;
      if (confirm("Rostan ham o'chirmoqchimisiz?")) {
        this.insService
          .deleteSale(id)
          .pipe(take(1))
          .subscribe((result) => {
            if (result.message === true) {
              this.rowData = this.insService.getInsSaleList();
            }
          });
      }
    } else {
      this.toast.info("Jadvaldan qatorni tanlang!");
    }
  }

  openNewTab() {
    window.open("http://new.meinhaus.uz/auth/login", "_blank");
  }
}
