import {Component, Inject, OnInit, Optional} from '@angular/core';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  templateUrl: './p-m-list-items.component.html',
  styleUrls: ['./p-m-list-items.component.css']
})
export class PMListItemsComponent implements OnInit {
  rowData: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  public modules: Module[] = AllModules;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  columnDefs = [
    {headerName: 'Barcode', field: 'barcode', sortable: true, filter: 'agNumberColumnFilter'},
    {headerName: 'Nomi', field: 'name', sortable: true, filter: 'agNumberColumnFilter'},
    {headerName: 'Miqdori', field: 'quantity', sortable: true, filter: 'agNumberColumnFilter'}
  ];
  ngOnInit(): void {
    this.rowData = this.data.raws;
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    param.api.sizeColumnsToFit();
  }

}
