import {Component, OnInit} from '@angular/core';
import {CashierService} from '../../cashier.service';
import {Router} from '@angular/router';

interface CloseDay {
  inSum: number;
  inDollar: number;
  inHr: number;
  outSum: number;
  outDollar: number;
  outHr: number;
}

@Component({
  templateUrl: './close-day-check.component.html',
  styleUrls: ['./close-day-check.component.css']
})
export class CloseDayCheckComponent implements OnInit {
  app: CloseDay;
  isLoading = false;

  constructor(
    private cashierService: CashierService,
    private router: Router
  ) {
  }
  ngOnInit(): void {
     this.isLoading = true;
     this.app =  window.history.state.alarm;
     this.isLoading = false;
  }

  printComponent() {
    const printContent = document.getElementById('invoice-POS');
    const WindowPrt = window.open();
    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
  }

  backSale() {
    this.router.navigate(['../cashier']);
  }
}
