import {Component, OnInit} from '@angular/core';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {InventoryService} from '../../../inventory.service';

@Component({
  templateUrl: 'invoice-products.component.html',
  styleUrls: ['invoice-products.component.css'],
  selector: 'app-invoice-products'
})
export class InvoiceProductsComponent implements OnInit {
  rowData: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  public modules: Module[] = AllModules;


  constructor(
    private inventoryService: InventoryService
  ) {
    this.defaultColDef = { resizeable: true };
  }
  columnDefs = [
    {headerName: 'Sana', field: 'cr_on', sortable: true, filter: 'agTextColumnFilter', width: 120},
    {headerName: 'Turi', field: 'oper_type', sortable: true, filter: 'agTextColumnFilter', width: 120},
    {headerName: 'Filial', field: 'branch_name', sortable: true, filter: 'agTextColumnFilter', width: 150 },
    {headerName: 'Kompaniya nomi', field: 'company_name', sortable: true, filter: 'agTextColumnFilter', width: 200},
    {headerName: 'Invoice nomi', field: 'invoice_name', sortable: true, filter: 'agTextColumnFilter', width: 200},
    {headerName: 'Maxsulot nomi', field: 'product_name', sortable: true, filter: 'agTextColumnFilter', checkboxSelection: true, width: 250},
    {headerName: 'Barcode', field: 'barcode', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Turi', field: 'type', sortable: true, width: 200, filter: 'agTextColumnFilter'},
    {headerName: 'Miqdori', field: 'quantity', sortable: true, width: 150, filter: 'agNumberColumnFilter',
      cellRenderer: this.CurrencyCellRendererDollar},
    {headerName: 'Narxi', field: 'cost', sortable: true, width: 150, filter: 'agNumberColumnFilter',
      cellRenderer: this.CurrencyCellRendererDollar},
    {headerName: 'Summa', field: 'sum', sortable: true, width: 150, filter: 'agNumberColumnFilter',
      cellRenderer: this.CurrencyCellRendererDollar},
    {headerName: '%', field: 'bt', sortable: true, width: 150, filter: 'agNumberColumnFilter',
      cellRenderer: this.CurrencyCellRendererDollar},
    {headerName: '% summa', field: 'sum_bt', sortable: true, filter: 'agNumberColumnFilter',
      width: 150, cellRenderer: this.CurrencyCellRendererDollar},
  ];

  ngOnInit(): void {
    this.rowData = this.inventoryService.getInvoiceProductsFilter(
      localStorage.getItem('from_i_p'),
      localStorage.getItem('to_i_p')
    );
  }

  private CurrencyCellRendererDollar(params: any) {
    const usdFormate = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0
    });
    return usdFormate.format(params.value);
  }

}
